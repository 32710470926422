import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { DisposalSitesService } from '../disposal-sites.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
    selector: 'app-add-edit-ewc-code',
    templateUrl: './add-edit-ewc-code.component.html',
    styleUrls: ['./add-edit-ewc-code.component.scss']
})
export class AddEditEWCCodeComponent implements OnInit {
    slide = true;
    parentForm: FormGroup;
    subjectList = [];
    subCategoryList = [];
    isSubjectVisible = false;
    closepopup = false;
    companyList = [{ label: 'Please Select', value: '' }];
    parentWasteTypeList = [{ label: 'Please Select', value: '' }];
    CompanyForm: FormGroup;
    isView = false;
    @Input('category') category = true;
    @Input('selectSubCategory') selectSubCategory = false;
    @Input('title') title: any;
    @Input('isUpdate') isUpdate = false;
    @Input('parentWasteType') parentWasteType: any;
    @Input('disposalData') disposalData: any;
    @Input('id') id: any;
    @Input('data') viewData: any = {};
    @Output('closeEvents') closeEvents = new EventEmitter<any>();
    constructor(private service: DisposalSitesService, private translateService: TranslateService, private cdr: ChangeDetectorRef, public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig,
        private messageService: MessageService, private confirmDialogService: ConfirmationService, private router: Router) {
        this.parentForm = new FormGroup({
            parent_waste_category: new FormControl('', [Validators.required])
        });
    }

    loadCategoryData() {
        console.log('disposal data', this.disposalData)
        if (this.disposalData) {
            this.service.parentWasteTypes({}).subscribe((data) => {
                data.forEach(element => {
                    let data;
                    if (this.disposalData.disposal_type_details) {
                        if (this.disposalData.disposal_type_details.parent_waste_category.length > 0) {
                            var exists = this.disposalData.disposal_type_details.parent_waste_category.find(({ ewc_code }) =>
                                element.ewc_code === ewc_code);
                            if (!exists) {
                                data = { label: element.ewc_code + ' - ' + element.code_description, value: element.ewc_code };
                                this.parentWasteTypeList.push(data);
                            }
                        } else {
                            data = { label: element.ewc_code + ' - ' + element.code_description, value: element.ewc_code };
                            this.parentWasteTypeList.push(data);
                        }
                    } else {
                        data = { label: element.ewc_code + ' - ' + element.code_description, value: element.ewc_code };
                        this.parentWasteTypeList.push(data);
                    }

                });

            });
        }
    }
    fetchCategory() {
        this.service.getDisposalDetails(this.id).subscribe((data: any) => {
            this.disposalData = data.data;
        });
    }
    updateDisposal(event: any, value) {
        console.log('value', value);
        this.service.updateDisposalLocation(value).subscribe((data: any) => {
            if (data.status === 200) {
                if (this.closepopup) {
                    this.cancel();
                }
                else {
                    this.loadCategory();
                    this.fetchCategory();
                }
            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    // summary: 'Update Disposal site',
                    detail: data.error.message.errmsg
                });
            }
            else {
                this.messageService.add({
                    severity: 'error',
                    //  summary: 'Update Disposal site',
                    // detail: 'Failed to quotes the Tip site details'
                    detail: 'Unable to update Tip site.Please try again.'
                });
            }

        });
    }
    updateSubCategory(event) {
        const subcatArr = [];
        const mainCategoryArr = [];
        console.log('subcategorylist', this.subCategoryList, this.disposalData);
        this.subCategoryList.forEach(element => {
            const subsubcatArr = [];
            if (element.checked == true) {
                element.waste_sub_sub_details.forEach(ele => {
                    if (ele.checked == true) {
                        subsubcatArr.push(ele.ewc_code);
                    }
                });
                let data;
                data = { ewc_code: element.ewc_code, sub_sub_waste_category: subsubcatArr }
                subcatArr.push(data);
            }
        });
        if (this.disposalData.disposal_type_details.parent_waste_category.length > 0) {
            this.disposalData.disposal_type_details.parent_waste_category.forEach(el => {
                let data;
                if (el.ewc_code == this.parentWasteType) {
                    data = { ewc_code: this.parentWasteType, sub_waste_category: subcatArr }
                    mainCategoryArr.push(data);
                } else {
                    const subArr = [];
                    let val;
                    //  console.log('data',el,el.sub_waste_category);
                    if (el.sub_waste_category.length > 0) {
                        el.sub_waste_category.forEach(e2 => {
                            const subSubArr = [];
                            if (e2.sub_sub_waste_category.length > 0) {
                                e2.sub_sub_waste_category.forEach(e3 => {
                                    subSubArr.push(e3.ewc_code);
                                });

                            }
                            val = { ewc_code: e2.ewc_code, sub_sub_waste_category: subSubArr };
                            subArr.push(val);
                        });
                    }
                    data = { ewc_code: el.ewc_code, sub_waste_category: subArr }
                    mainCategoryArr.push(data);
                }
            });
        }
        console.log('main data', mainCategoryArr);
        this.disposalData.disposal_type_details.parent_waste_category = mainCategoryArr;
        this.closepopup = true;
        this.updateDisposal(event, this.disposalData);

    }
    createParentWasteType(event) {
        console.log('disposal data', event);
        const disposal = [];
        const val = [];
        this.selectSubCategory = true;
        if (this.disposalData.disposal_type_details.parent_waste_category) {
            this.disposalData.disposal_type_details.parent_waste_category.push(this.parentForm.value.parent_waste_category);

        } else {
            val.push(this.parentForm.value.parent_waste_category);
            this.disposalData.disposal_type_details.parent_waste_category = val;
        }
        if (this.disposalData.disposal_type_details.parent_waste_category.length > 0) {
            this.disposalData.disposal_type_details.parent_waste_category.forEach(element => {
                let data;
                if (element.ewc_code) {
                    const subArr = [];
                    let val;
                    if (element.sub_waste_category.length > 0) {
                        element.sub_waste_category.forEach(e2 => {
                            const subSubArr = [];
                            if (e2.sub_sub_waste_category.length > 0) {
                                e2.sub_sub_waste_category.forEach(e3 => {
                                    subSubArr.push(e3.ewc_code);
                                });

                            }
                            val = { ewc_code: e2.ewc_code, sub_sub_waste_category: subSubArr };
                            subArr.push(val);
                        });
                    }
                    data = { ewc_code: element.ewc_code, sub_waste_category: subArr }

                } else {
                    if (!this.isUpdate) {
                        this.parentWasteType = element;
                        this.parentWasteTypeList.forEach(e1 => {
                            if (e1.value == element) {
                                //  (<HTMLInputElement>document.getElementById('ui-dialog-1-label')).style.display='block';
                                this.title = e1.label;
                            }
                        });
                    }
                    data = { ewc_code: element, sub_waste_category: [] };
                }
                disposal.push(data);
            });
            this.disposalData.disposal_type_details.parent_waste_category = disposal;
        }

        this.updateDisposal(event, this.disposalData);
    }





    ngOnInit() {
        this.loadCategoryData();
        this.loadCategory();
    }
    loadCategory() {
        console.log('load category');
        this.service.subCategoryList({ ewc_code: this.parentWasteType }).subscribe((response: any) => {
            if (response && response.length > 0) {
                response.forEach(element => {
                    element.code_description = element.ewc_code + ' - ' + element.code_description;
                    element.checked = false;
                    element.waste_sub_sub_details.forEach(ele => {
                        ele.code_description = ele.ewc_code + ' - ' + ele.code_description;
                        ele.checked = false;
                    });
                });

                this.subCategoryList = response;
                const subCat = [];
                const subSubCat = [];
                if (this.isUpdate) {
                    this.disposalData.disposal_type_details.parent_waste_category.forEach(element => {
                        if (element.ewc_code == this.parentWasteType) {
                            if (element.sub_waste_category.length > 0) {
                                element.sub_waste_category.forEach(el => {
                                    subCat.push(el);
                                    if (el.sub_sub_waste_category.length > 0) {
                                        el.sub_sub_waste_category.forEach(e => {
                                            subSubCat.push(e);
                                        });
                                    }
                                });
                            }

                        }

                    });
                    this.subCategoryList.forEach(ele => {
                        if (subCat.length > 0) {
                            subCat.forEach(e => {
                                if (ele.ewc_code == e.ewc_code) {
                                    ele.checked = true;
                                    ele.waste_sub_sub_details.forEach(e1 => {
                                        subSubCat.forEach(e2 => {
                                            if (e1.ewc_code == e2.ewc_code) {
                                                e1.checked = true;
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    });

                }
            }
        });
    }
    onChange(event, item) {
        this.subCategoryList.forEach(element => {
            if (element.checked == false) {
                element.waste_sub_sub_details.forEach(ele => {
                    ele.checked = false;
                });
            } else {
                if (element.ewc_code == item.ewc_code) {
                    element.waste_sub_sub_details.forEach(ele => {
                        ele.checked = true;
                    });
                }

            }
        })
    }

    cancel() {
        this.closeEvents.emit(true);
    }
    closeEvent(event) {

        this.slide = false;
        this.cdr.detectChanges();
        setTimeout(e => {
            this.closeEvents.emit(true);
        }, 1000)
    }

}
