<div class="">

  <div id="styleSelector" class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
          <div class='row add-edit-header' *ngIf="!isView">
            <div class="ui-g-10">
              <h4 *ngIf='!isUpdate'>Create Admin</h4>
              <h4 *ngIf='isUpdate'>Edit Admin</h4>
            </div>
            <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Admin" tooltipPosition="top">
              <i class="fa fa-window-close fa-2x "></i>
            </div>
          </div>
          <div class="">
            <form [formGroup]="userForm" *ngIf="!isView">



              <div class='row form-group'>
                <div class='ui-g-12 mt-3'>
                  <label for="firstname">First Name</label>
                  <input class='ui-g-12' id="firstname" class="form-control" type="text" size="30"
                    formControlName='firstName' pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="lastname">Last Name</label>
                  <input class='ui-g-12' id="lastname" type="text" class="form-control" size="30"
                    formControlName='lastName' pInputText>
                </div>
              </div>

              <!-- <div class='col-12' style='color:red;font-size: 12px' *ngIf="userForm.get(''">
                  Employee ID
                </div> -->
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="email">Admin Email</label>
                  <input class='ui-g-12' id="email" type="email" class="form-control" size="30" formControlName='email'
                    pInputText>
                </div>
              </div>
              <!-- <div class='row form-group' *ngIf='!isUpdate'> -->
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="password">Password</label>
                  <input class='ui-g-12' id="password" type="password" class="form-control" (change)="onChange($event)" autocomplete="new-password"
                    size="30" formControlName='password' pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="cpassword">Confirm Password</label>
                  <input class='ui-g-12' id="cpassword" type="password" (change)="onChange($event)" class="form-control" size="30"
                    formControlName='cpassword' pInputText>
                </div>
              </div>
              <span *ngIf="errorMessage" class="error-message">Password and Confirm Password does not match</span>
              <div class='row form-group' *ngIf="isAcumen == 'true'">
                <!-- <div class='ui-g-12 ui-float-label mt-3'>
                  <input class='ui-g-12' id="companyassociatedto" type="text" size="30" formControlName='comapny_associated_to' pInputText>
                  <label for="companyassociatedto">Company Associated to</label>
                </div> -->
                <div class='ui-g-12  mt-3'>
                  <label for="linked_type">Company Associated to</label>
                  <p-dropdown class='ui-g-12 form-control' [options]="companyList" [style]="{'width':'100%'}"
                    formControlName='company'></p-dropdown>

                </div>
              </div>
              <div class='row form-group hide-block'>
                <div class='ui-g-12 checkbox mt-3'>
                  <div class="custom-control custom-switch">
                    <input formControlName="active" type="checkbox" id="customswitch1" class="custom-control-input">
                    <label for="customswitch1" class="custom-control-label">Active</label></div>
                </div>

              </div>





              <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                <div class='ui-g-12'>
                  <p-button label="Create" [disabled]='!userForm.valid' (onClick)="createAdmin($event)"></p-button>

                </div>
              </div>
              <div class='row button-cls' *ngIf='isUpdate'>
                <div class='ui-g-12'>
                  <p-button label="Update" [disabled]='!userForm.valid' (onClick)="updateAdmin($event)"></p-button>

                </div>
              </div>

            </form>
            <div *ngIf="isView">
              <div class='row details-header'>
                <div class="ui-g-10">
                  <h4>View Admin Details</h4>
                </div>
                <div class="ui-g-1" (click)="editEvent($event)">
                  <i class="fa fa-edit fa-2x " pTooltip="Click to edit a Admin" tooltipPosition="top"></i>
                </div>
                <div class="ui-g-1" (click)="closeEvent($event)">
                  <i class="fa fa-window-close fa-2x " pTooltip="Click to close a Admin" tooltipPosition="top"></i>
                </div>
              </div>
              <div class="details-content">
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">First Name</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.firstName}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Last Name</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.lastName}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Email</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.email}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Company Associated to </span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.company.companyName}}</span>
                  </div>
                </div>


                <!-- <div class='row'>
                            <div class="ui-g-6">
                              <span for="userName">Active </span>
                            </div>
                            <div class="ui-g-6">
                              <span for="userName">{{viewData.active}}</span>
                            </div>
                          </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>