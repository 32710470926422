<div   class="" >
  
        <div id="styleSelector"  class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
    <div class="container h-100">
            <div class="row align-items-center">
              <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
                  <div class='row add-edit-header'  *ngIf="!isView">  
                      <div class="ui-g-10">
                          <h4 >Alert Text</h4>
                      </div> 
                      <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Alert text" tooltipPosition="top">
                          <i class="fa fa-window-close fa-2x "></i>
                       </div>    
                           </div> 
                           <div class="">                    
                <form [formGroup]="alertTextForm" >
               
                
                  <div class='row form-group'>
                    <div class='ui-g-12 mt-3'>
                        <label for="firstname">Alert Text</label>
                <textarea [rows]="5" [cols]="30" class='ui-g-12 form-control'  pInputTextarea formControlName='alert_text' autoResize="autoResize"></textarea>
                    </div>
                  </div>   
                  
                
                   
                
                   
                      
                  
                
                  
               
                  <div class='row button-cls' >
                    <div class='ui-g-12'>
                      <p-button label="Save" [disabled]='!alertTextForm.valid' (onClick)="save($event)"></p-button>
                    
                    </div>
                  </div>
                 
                </form>
                
              </div>
              </div>
            </div>
          </div>
          </div>
          </div>