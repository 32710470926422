import { Component, OnInit ,ChangeDetectorRef, Input, Output,EventEmitter} from '@angular/core';
import { DisposalSitesService } from '../disposal-sites.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormGroup,FormControl,Validators } from '@angular/forms';
@Component({
  selector: 'app-link-disposal-customer',
  templateUrl: './link-disposal-customer.component.html',
  styleUrls: ['./link-disposal-customer.component.scss']
})
export class LinkDisposalCustomerComponent implements OnInit {
  itemList = [];
  title:any;
  slide = true;
  selected:any;
  @Input('data') linkData :any;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();

  constructor(private service:DisposalSitesService,private cdr:ChangeDetectorRef,private messageService:MessageService) { }

  ngOnInit() {
    let flag = true;
      this.title = 'Companies for the '+ this.linkData.Name;
      this.service.companyList().subscribe(response =>{
        console.log('response',response.data);
        response.data.forEach(element => {
          if(this.linkData.companyId){
            if(this.linkData.companyId.length > 0){
              var exists = this.linkData.companyId.find(({companyId}) => 
                          element.companyId === companyId);
                      if(exists){
                        element.checked = true;
                        this.itemList.push({label:element.companyName,value:element.companyId,checked:true});

                      }else{
                        element.checked = false;
                        this.itemList.push({label:element.companyName,value:element.companyId,checked:false});

                      }
           
            }else{
              this.itemList.push({label:element.companyName,value:element.companyId,checked:false});

            }
          }else{
            this.itemList.push({label:element.companyName,value:element.companyId,checked:false});

          }

         });
         this.setFlag();

      });

    


  }
  onChange(event){
    console.log('value',this.selected);
    if(this.selected == true){
      this.itemList.forEach(element=>{
         element.checked = true;
      });
    }else{
      this.itemList.forEach(element=>{
        element.checked = false;
     });
    }
    this.cdr.detectChanges();

  }
  changeEvent(event){
    this.setFlag();
  }
  setFlag(){
    let flag = true;
    this.itemList.forEach(element =>{
      if(element.checked == false){
          flag = false;
      }
      this.selected = flag;
    });
  }
  closeEvent(event){
    this.slide = false;
    setTimeout(e =>{
      this.closeEvents.emit(true);
    },1000);
  }
  linkItems(event){
    let value: any = '';
    const companyArr = [];
    value = this.linkData;
    value._id = this.linkData._id;
      this.itemList.forEach(element =>{
         if(element.checked == true){
          companyArr.push(element.value);
         }
      });
     
     value.companyId = companyArr;


    
    console.log('Update value',value);
    
        this.service.updateDisposalLocation(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Linked Customer',
                    detail: 'Customer Linked successfully'
                });
                

              
            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Linked Customer',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Linked Customer',
                    detail: 'Unable to Link Customer.Please try again.'
                  //  detail: 'Failed to update the user details'
                });
            }
            this.closeEvent(true);

        });
    
    
  }
}

