export const environment = {
  production: true,

  // prod
//  apiURL: 'https://app.acumen-portal.co.uk/api/',
//   mobileServerAPIURL: 'https://api.acumen-portal.co.uk/api/',
//   stitchApiURL: 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/acumenwaste-paopp/service/',


  // Dev
 apiURL: 'http://159.65.148.36:7011/api/',
 mobileServerAPIURL: 'http://159.65.148.36:7010/api/',
stitchApiURL: 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/acumenwaste-sicpe/service/',

  // Localhost
  // mobileServerAPIURL: 'http://localhost:7010/api/',
  // apiURL: 'http://localhost:7011/api/'
};
