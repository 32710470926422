import { Component, OnInit, OnDestroy,ChangeDetectorRef ,ViewChild} from '@angular/core';
import { ReportsService } from './reports.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Table } from 'primeng/table/table';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  @ViewChild('dt', { static: false }) table:Table;
  reportList = [];
  pageReportIndex;
  cols = [
    {
        header: 'Report No ',
        field: 'live_report_number'
    },
    {
        header: 'Date',
        field: 'date_time_submit'
    },
    {
        header: 'Company',
        field: 'companyName'
    },
    {
        header: 'Department',
        field: 'departmentName'
    },
    {
        header: 'Person Name',
        field: 'personName'
    },
    {
        header: 'Accepted',
        field: 'reportAccepted'
    }
];
jobList = [];
selectedReport:any;
showAdd = false;
companyID:any;
isAcumen:any;
jobData = {};
startDate = new Date();
endDate = new Date();
showLoader = true;
searchArr = [];
  constructor(private translateService: TranslateService,  private service: ReportsService,private cdr:ChangeDetectorRef,public dialogService: DialogService,
    private router:Router,private messageService: MessageService,private confirmDialogService:ConfirmationService) { 
      this.startDate.setDate(this.startDate.getDate() - 30);
    }

  ngOnInit() {
    const jobID = localStorage.getItem('jobID');
    this.companyID = localStorage.getItem('companyID');
    this.pageReportIndex = parseInt(localStorage.getItem('pageReportIndex'));
    this.isAcumen = localStorage.getItem('isAcumen');
  //  this.setPage(2);
   if(this.pageReportIndex){
    localStorage.removeItem('pageReportIndex');
   }
    if (jobID){
      let event:any;
      let data = {_id:jobID};
      localStorage.removeItem('jobID');
      console.log('jobId',data)
      this.viewReport(event,data);
    }
    this.fetchReportList({});
    if (this.cols.length > 0){
      this.cols.forEach(element => {
        this.searchArr.push(element.field);
      });
    }

  }
  onPageEvent(event){
    console.log('event',event);
    this.pageReportIndex = (event.first/event.rows + 1);
  }
  setPage(n: number){
    this.table.reset();
    let paging = {
      first: ((n - 1) * this.table.rows),
      rows: this.table.rows
  };
  console.log('table values',paging);
  this.table.first = paging.first;
  this.table.rows = paging.rows;
}
  searchReports(event){
    this.showLoader = true;
    this.fetchReportList({startDate:this.startDate,endDate:this.endDate});
  }
  viewReport(event,data){
    if (this.pageReportIndex != 1){
      let pageNum = this.pageReportIndex.toString();
      setTimeout(e =>{
        localStorage.setItem('pageReportIndex',pageNum);

      },1000)    }
    this.router.navigate(['reports/'+ data.live_report_number]);
  }
  
  callList(event){
    console.log('call list',event,this.jobData);
    if (event == false){
     // this.showAdd = false;
     // this.cdr.detectChanges();
     if (this.pageReportIndex != 1){
       let pageNum = this.pageReportIndex.toString();
       setTimeout(e =>{
        localStorage.setItem('pageReportIndex',pageNum);

      },1000)     }
     this.router.navigate(['job/data/'+ this.jobData['_id']]);
    } else{
    this.showAdd = false;
    this.jobData = {};
    this.cdr.detectChanges();
      this.fetchReportList({});
    }
  }
  deleteReport(id){
    console.log('id',id,this.confirmDialogService);
    this.confirmDialogService.confirm({
      message: 'Are you sure that you want to delete the Report?',
      accept: () => {
          this.service.deleteReport(id).subscribe((data: any) => {
              if (data.status === 200) {
                  this.messageService.add({
                      severity: 'success',
                      summary: 'Delete Report',
                      detail: 'Report details deleted successfully'
                  });
                  this.fetchReportList({});
              }
          });
      }
  });
}
  
  reload(event){
    console.log('reload event called');
    this.showLoader = true;
    this.fetchReportList({});
  }
  
  fetchReportList(data){
    const reportArr = [];
    this.service.fetchReports().subscribe((response) => {
      console.log('reposn',response.data)
    if (response.data.length > 0){
      response.data.forEach(element => {
       if(this.isAcumen == 'true' || this.isAcumen == true) {
       // if(element.job_reference && element.employeeName && element.createdAt) {
          reportArr.push(element);
        //}
       }else{
          if(element.company.companyId == this.companyID){
          //  if(element.job_reference && element.employeeName && element.createdAt) {
              reportArr.push(element);
           // }
          }
        
      }
      });
    }
      console.log('Report list', response.data);
      this.reportList = reportArr;
      this.showLoader = false;
      if (this.reportList.length > 0) {
          this.reportList.forEach((ele) => {
             // ele.createdAt = new Date(ele.createdAt).toLocaleString();
             // ele.compltedAt = ele.compltedAt ? new Date(ele.compltedAt).toLocaleString() : '';
           //  ele.createdAt = ele.createdAt? moment(ele.createdAt).format("DD/MM/YYYY h:mm a"):'-';
           //  ele.compltedAt = ele.compltedAt? moment(ele.compltedAt).format("DD/MM/YYYY h:mm a"):'-';
           ele.departmentName = ele.department.departmentName;
           ele.companyName = ele.company.companyName;
           ele.date_time_submit = ele.date_time_submit? moment(ele.date_time_submit).format("DD/MM/YYYY h:mm a"):'-';

        //   ele.date_time_submit = new Date(ele.date_time_submit).toLocaleString("en-IN"); 
          // e.date_time_submit= moment(e.date_time_sumit).format('DD-MM-YY hh:mm');
           ele.personName = ele.userId.firstName;
           ele.reportAccepted = ele.reportAccepted ? 'Yes' : 'No';
       });
          
      }
    if(this.pageReportIndex && this.pageReportIndex > 1)  {
      console.log('page index',this.pageReportIndex);
      this.setPage(this.pageReportIndex);

    }

  });
  }

}
