import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ViewEncapsulation } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ChatUserListComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import { FriendComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import { ChatMsgComponent } from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { HttpLogInterceptor } from './http-log-interceptor.service';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { JobService } from './demo/job/job-data.service';
import { VehicleService } from './demo/vehicle/vehicle-data.service';
import { StaffService } from './demo/staff/staff-data.service';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { AdminUserService } from './demo/admin-users/admin-users.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { StaffDataModule } from './demo/staff/staff-data/staff-data.module';
import { JobDataModule } from './demo/job/job-data/job-data.module';
import { DropdownModule } from 'primeng/dropdown';
import { VehicleModule } from './demo/vehicle/vehicle.module';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { VehicleDataModule } from './demo/vehicle/vehicle-data/vehicle-data.module';
import { ViewJobViewModule } from './demo/job/view-job-details/view-job-details.module';
import { TabViewModule } from 'primeng/tabview';
import { AgmCoreModule } from '@agm/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AdminUserModule } from './demo/admin-users/admin-users.component.module';
import { CustomersModule } from './demo/customers/customers.module';
import { CustomerDivisionsModule } from './demo/customer-divisions/customer-divisions.module';
import { VehicleTypesModule } from './demo/vehicle-types/vehicle-types.module';
import { CustomersService } from './demo/customers/customers.service';
import { CustomerDivisionsService } from './demo/customer-divisions/customer-divisions.service';
import { VehicleTypesService } from './demo/vehicle-types/vehicle-types.service';
import { WasteDescriptionComponent } from './demo/waste-description/waste-description.component';
import { WasteCategoryModule } from './demo/waste-category/waste-category.module';
import { WasteCategoryService } from './demo/waste-category/waste-category.service';
import { HazardousService } from './demo/hazardous/hazardous.service';
import { SkipService } from './demo/skip/skip.service';
import { CustomerSiteModule } from './demo/customer-site/customer-site.module';
import { CustomerSitesService } from './demo/customer-site/customer-site.service';
import { EditorModule } from 'primeng/editor';
import { ReportsModule } from './demo/reports/reports.module';
import { DisposalSitesModule } from './demo/disposal-sites/disposal-sites.module';
import { ReportsService } from './demo/reports/reports.service';
import { DisposalSitesService } from './demo/disposal-sites/disposal-sites.service';
import { WasteDescriptionService } from './demo/waste-description/waste-description.service';
import { Ng4GeoautocompleteModule } from '../modules/auto-complete/ng-4-geoautocomplete.module';
// import { ImportingComponent } from './demo/disposal-site/importing/importing.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { HazardousComponent } from './demo/hazardous/hazardous.component';
import { SkipComponent } from './demo/skip/skip.component';
// import { ExportingComponent } from './staff-data/exporting/exporting.component';
// import { ImportStaffComponent } from "./demo/staff/import-staff/import-staff.component";


export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    LoginComponent,
    WasteDescriptionComponent,
    HazardousComponent,
    SkipComponent,
    // ExportingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    JobDataModule,
    NgbDropdownModule,
    VehicleDataModule,
    NgbTooltipModule,
    ViewJobViewModule,
    ButtonModule,
    EditorModule,
    InputSwitchModule,
    AdminUserModule,
    CustomersModule,
    CustomerDivisionsModule,
    VehicleTypesModule,
    TabViewModule,
    Ng4GeoautocompleteModule.forRoot(),
    CalendarModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBcapYO6jKgeTykdEWCtzdJIHM6zK2LN1Q', //'AIzaSyCV4EIRYyt8YOAQFgrjCfonCyJAuzRdrq4',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] }
    }),
    ToastModule,
    TableModule,
    VehicleModule,
    MessagesModule,
    DropdownModule,
    DynamicDialogModule,
    MessageModule,
    WasteCategoryModule,
    CheckboxModule,
    StaffDataModule,
    ConfirmDialogModule,
    HttpClientModule,
    NgbButtonsModule,
    NgbTabsetModule,
    ReportsModule,
    CustomerSiteModule,
    DisposalSitesModule
  ],

  providers: [
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    ReportsService, DisposalSitesService, WasteDescriptionService, WasteCategoryService
    , CustomersService, CustomerDivisionsService, VehicleTypesService,
    CustomerSitesService, HazardousService, SkipService,
    NavigationItem, DynamicDialogConfig, DialogService, AdminUserService, JobService, DynamicDialogRef, ConfirmationService, MessageService, VehicleService, StaffService, LoginService, { provide: HTTP_INTERCEPTORS, useClass: HttpLogInterceptor, multi: true }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
