<div class="row">
        <!-- tabs card start -->
        <div class="col-sm-12">
            <app-card  moduleName="Vehicles" module="Customer site" (addVehicle)="createSite($event)"  cardClass="table-card" blockClass="p-0">
                <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
            
              
                  <p-table #dt [value]="customerSiteList" [(selection)]="selectedSite" dataKey="_id" styleClass="ui-table-customers" [rowHover]="true"
                  [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]" 
                  [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  [filterDelay]="0" [globalFilterFields]="searchArr">
                  <ng-template pTemplate="caption">
                      <div class="ui-table-globalfilter-container">
                          <input pInputText type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
                      </div>
                  </ng-template>                  
                  <ng-template pTemplate="header">
                      <tr>
                        <th *ngFor="let col of cols"  [pSortableColumn]="col.field">
                          <b>{{col.header}}</b>
                          <p-sortIcon [field]="col.field"></p-sortIcon>           
                        </th>
              
                        <!-- <th>Edit</th> -->
                        <!-- <th>View</th>
                        <th>Delete</th> -->
                        <th></th>
                      </tr>
                      <tr>
                        <th *ngFor="let col of cols" [ngSwitch]="col.field">
                          <input pInputText type="text" class="ui-inputtext" (input)="dt.filter($event.target.value, col.field, 'contains')">
                        </th>
                        <!-- <th></th> -->
                        <!-- <th></th> -->
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-car>
                      <tr *ngIf="!showLoader">
                        <td *ngFor="let col of cols">
                          {{car[col.field]}}
                        </td>
                       
                        <!-- <td>
                          <span (click)='editSubject($event,car)'>
                            <i class='fa fa-pencil fa-2x'></i>
                          </span>
                        </td> -->
                        <td>
                          <span (click)='viewSite($event,car)'  class="mob_icon style-toggler" pTooltip="Click to view a Customer site" tooltipPosition="top">
                            <i class='fa fa-eye fa-2x'></i>
                          </span>
                          <span (click)='editSite($event,car)'  class="mob_icon style-toggler delete_icon" pTooltip="Click to edit a Customer site" tooltipPosition="top">
                            <i class='fa fa-edit fa-2x'></i>
                          </span>
                          <span (click)='deleteSite(car._id)'   class="style-toggler mob_icon delete_icon" pTooltip="Click to delete a Customer site" tooltipPosition="top">
                            <i class='fa fa-trash fa-2x'></i>
                          </span>
                          <!-- <span *ngIf="!car.delete">
                              -
                            </span> -->
                        </td>
                      
                      </tr>
                    </ng-template>
                    <ng-template class="noRecords" *ngIf="customerSiteList.length==0" pTemplate="emptymessage">
                      <tr class="noRecords" *ngIf="!showLoader">
                        <td [attr.colspan]="cols.length+1">
                          <!-- <div class="my-center-text"> -->
                            No Records to display
                          <!-- </div> -->
                        </td>
              
                      </tr>
                    </ng-template>
                  </p-table>
                <!-- </perfect-scrollbar> -->
                <div class="loader" *ngIf="showLoader" ></div>
    
              </app-card>
        </div>
        <!-- tabs card end -->
      
    
      </div>
      
      <div *ngIf="showAdd">
          <app-add-edit-customer-site  [isView]="isView" [data]="siteData" (closeEvents)="callList($event)" ></app-add-edit-customer-site>
        </div>
    