<div class="">

  <div id="styleSelector" class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
          <div class='row add-edit-header' *ngIf="!isView">
            <div class="ui-g-10">
              <h4 *ngIf='!isUpdate'>Create Site</h4>
              <h4 *ngIf='isUpdate'>Edit Site</h4>
            </div>
            <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Site" tooltipPosition="top">
              <i class="fa fa-window-close fa-2x "></i>
            </div>
          </div>
          <div class="">

            <form [formGroup]="TipSitesForm" *ngIf="!isView" (keydown.enter)="$event.preventDefault()">
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="icon"> BigChange Site ID</label>
                  <input class="ui-g-12 form-control {{isUpdate?'':''}}" id="icon"
                    type="{{isUpdate?'text':'text'}}" size="30" formControlName='Id' pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <!-- <div class='ui-g-12 ui-float-label mt-3'>
                                <input class='ui-g-12' id="companyassociatedto" type="text" size="30" formControlName='comapny_associated_to' pInputText>
                                <label for="companyassociatedto">Company Associated to</label>
                              </div> -->
                <!-- <div class='ui-g-12  mt-3'>
                              <label for="linked_type">Disposal Company</label>
                              <p-dropdown class='ui-g-12 form-control' [options]="companyList" [style]="{'width':'100%'}" formControlName='companyId'></p-dropdown>
                  
                            </div> -->
              </div>
              <div class='row form-group'>
                <div class='ui-g-12 mt-3'>
                  <label for="icon">Display Name</label>

                  <input class='ui-g-12 form-control' id="icon" type="text" size="30" formControlName='Name' pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="icon"> Site Name</label>

                  <input class='ui-g-12 form-control' id="icon" type="text" size="30" formControlName='disposalSiteName'
                    pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12 mt-3'>
                  <label for="icon"> Phone Number</label>

                  <input class='ui-g-12 form-control' id="icon" type="tel" size="30" formControlName='contactNo'
                    pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="icon">Site Address</label>
                  <textarea [rows]="5" [cols]="30" class="form-control" pInputTextarea
                    formControlName='disposalSiteAdds' autoResize="autoResize"></textarea>

                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12 mt-3'>
                  <label for="icon"> Postcode</label>

                  <input class='ui-g-12 form-control' id="icon" type="text" size="30" formControlName='Postcode'
                    pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="icon"> Location</label>
                  <!-- <input #search id="search" placeholder="search for location"  autocorrect="off" autocapitalize="off" spellcheck="off"  type="text" class="ui-g-12 map" 
                                    [formControl]="searchControl"> -->
                  <ng4geo-autocomplete id="places" (componentCallback)="autoCompleteCallback1($event)">
                  </ng4geo-autocomplete>
                  <agm-map #map [latitude]="lat" [longitude]="long" [scrollwheel]="false" [zoom]="zoom"
                    (mapClick)="changePickupMarkerLocation($event)">
                    <agm-marker [latitude]="lat" [longitude]="long" (dragEnd)="markerDragEnd($event)"
                      [markerDraggable]="true" (markerClick)="clickedMarker($event)"></agm-marker>
                    <agm-info-window>
                      <strong>window</strong>
                    </agm-info-window>
                  </agm-map>

                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="linked_type">Type</label>
                  <p-dropdown class='ui-g-12 form-control' [options]="typeList" [style]="{'width':'100%'}"
                    formControlName='type'></p-dropdown>

                </div>
              </div>
              <ng-container *ngIf="TipSitesForm.get('type').value == '5e54d71d50b3a90c698c8393'">
                <div class='row form-group hide-block'>
                  <div class='ui-g-12 mt-3'>
                    <label for="icon"> Parent waste type category</label>

                    <p-multiSelect [options]="parentWasteTypesList" class="multiselect form-control"
                      formControlName='parent_waste_category'></p-multiSelect>
                  </div>
                </div>
                <div class='row form-group hide-block'>
                  <div class='ui-g-12  mt-3'>
                    <label for="icon"> Min tonnage</label>

                    <input class='ui-g-12 form-control' id="icon" type="number" step="0.25"
                      (change)="setTwoNumberDecimal($event,'min_tonnage')" formControlName='min_tonnage' pInputText>
                  </div>
                </div>

                <div class='row form-group hide-block'>
                  <div class='ui-g-12  mt-3'>
                    <label for="icon"> Cost per tonne</label>

                    <input class='ui-g-12 form-control' id="icon" type="number" step="0.25"
                      (change)="setTwoNumberDecimal($event,'cost_per_tonne')" formControlName='cost_per_tonne'
                      pInputText>
                  </div>
                </div>
                <div class='row form-group hide-block'>
                  <div class='ui-g-12  mt-3'>
                    <label for="icon"> Green recycle %</label>

                    <input class='ui-g-12 form-control' id="icon" type="number" step="0.25"
                      (change)="setTwoNumberDecimal($event,'green_recycle')" formControlName='green_recycle' pInputText>
                  </div>
                </div>
                <div class='row form-group hide-block'>
                  <div class='ui-g-12  mt-3'>
                    <label for="icon"> Metal rebate</label>

                    <input class='ui-g-12 form-control' id="icon" type="number" size="30" formControlName='metal_rebate'
                      pInputText>
                  </div>
                </div>
                <!-- <div class='row'>
                            <div class='ui-g-12 ui-float-label mt-3'>
                              <input class='ui-g-12' id="icon" type="number" size="30" formControlName='opening_hours' pInputText>
                              <label for="icon"> Opening hours</label>
                            </div>
                          </div> -->
                <div class='row form-group'>
                  <div class='ui-g-12  mt-3'>
                    <label for="icon">Global notes</label>
                    <textarea [rows]="5" [cols]="30" class="form-control" pInputTextarea formControlName='global_notes'
                      autoResize="autoResize"></textarea>

                  </div>
                </div>
                <div class='row form-group'>
                  <div class='ui-g-12  mt-3'>
                    <label for="icon">Company notes</label>
                    <textarea [rows]="5" [cols]="30" class="form-control" pInputTextarea formControlName='company_notes'
                      autoResize="autoResize"></textarea>

                  </div>
                </div>
              </ng-container>





              <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                <div class='ui-g-12'>
                  <p-button label="Create" [disabled]='!TipSitesForm.valid' (onClick)="createDisposal($event)">
                  </p-button>

                </div>
              </div>
              <div class='row button-cls' *ngIf='isUpdate'>
                <div class='ui-g-12'>
                  <p-button label="Update" [disabled]='!TipSitesForm.valid' (onClick)="updateDisposal($event)">
                  </p-button>

                </div>
              </div>

            </form>
            <div *ngIf="isView">
              <div class='row details-header'>
                <div class="ui-g-10">
                  <h4>View Site Details</h4>
                </div>
                <div class="ui-g-1" (click)="editEvent($event)" pTooltip="Click to edit a Site" tooltipPosition="top">
                  <i class="fa fa-edit fa-2x "></i>
                </div>
                <div class="ui-g-1" (click)="closeEvent($event)" pTooltip="Click to close a Site" tooltipPosition="top">
                  <i class="fa fa-window-close fa-2x "></i>
                </div>
              </div>
              <div class="details-content">
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">BigChange Site ID</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.Id}}</span>
                  </div>
                </div>
                <!-- <div class='row'>
              <div class="ui-g-6">
                <span for="userName">Disposal Company</span>
              </div>
              <div class="ui-g-6" *ngIf="viewData.companyId.length > 0">
                <span for="userName" *ngFor="let i of viewData.companyId;let j=index">
                    {{j+1}}.
                  <span>{{i.companyName}}</span><br/>
                </span>
              </div>
            </div> -->
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Display Name</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.Name}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Site Name</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.disposalSiteName}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Phone Number</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.contactNo}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Site Address</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.disposalSiteAdds}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Postcode</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.Postcode}}</span>
                  </div>
                </div>
                <!-- <div class='row'>
                              <div class="ui-g-6">
                                <span for="userName">Location</span>
                              </div>
                              <div class="ui-g-6">
                                <span for="userName">{{viewData.location}}</span>
                              </div>
                            </div> -->
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Type</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.typeName}}</span>
                  </div>
                </div>

                <div *ngIf="viewData.disposal_type_details">
                  <div class='row hide-block'>
                    <div class="ui-g-6">
                      <span for="userName">Parent Waste type Category</span>
                    </div>
                    <div class="ui-g-6" *ngIf="viewData.disposal_type_details.parent_waste_category.length > 0">
                      <span for="userName"
                        *ngFor="let i of viewData.disposal_type_details.parent_waste_category;let j=index">
                        {{j+1}}.
                        <span> {{i.code_description}}</span><br />
                      </span>
                    </div>
                  </div>
                  <div class='row hide-block'>
                    <div class="ui-g-6">
                      <span for="userName">Min tonnage</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.disposal_type_details.min_tonnage}}</span>
                    </div>
                  </div>
                  <div class='row hide-block'>
                    <div class="ui-g-6">
                      <span for="userName">Cost per tonne</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.disposal_type_details.cost_per_tonne}}</span>
                    </div>
                  </div>
                  <div class='row hide-block'>
                    <div class="ui-g-6">
                      <span for="userName">Green recycle %</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.disposal_type_details.green_recycle}}</span>
                    </div>
                  </div>
                  <div class='row hide-block'>
                    <div class="ui-g-6">
                      <span for="userName">Metal rebate</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.disposal_type_details.metal_rebate}}</span>
                    </div>
                  </div>
                  <div class='row'>
                    <div class="ui-g-6">
                      <span for="userName">Global notes</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.disposal_type_details.global_notes}}</span>
                    </div>
                  </div>
                  <div class='row'>
                    <div class="ui-g-6">
                      <span for="userName">Company notes</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.disposal_type_details.company_notes}}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>