import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VehicleService {
    constructor(private http: HttpClient) { }

    vehicleList() {
        return this.http.get(environment.apiURL + 'vehicle/list-vehicles').pipe(
            tap((response: any) => {
                return response;
            })
        );

    }
    vehicleTypeList(){
        return this.http.get(environment.apiURL + 'shared/list-vehicle-types').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    companyList() {
        return this.http.get(environment.apiURL + 'company/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    getVehicleDetails(id) {
        return this.http.get(environment.apiURL + 'vehicle/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createVehicle(data) {
        return this.http.post(environment.apiURL + 'vehicle/create', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateVehicle(data) {
        return this.http.post(environment.apiURL + 'vehicle/update', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    fetchType() {
        return this.http.get(environment.apiURL + 'types/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createType(data) {
        return this.http.post(environment.apiURL + 'types/create', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateType(data) {
        return this.http.post(environment.apiURL + 'types/update', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    deleteVehicle(id) {
        let data;
            data = {_id:id};
        return this.http.post(environment.apiURL + 'vehicle/delete/', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
}
