import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerDivisionsRoutingModule } from './customer-divisions.routing.module';
import { CustomerDivisionsComponent } from './customer-divisions.component';
import {SharedModule} from '../../theme/shared/shared.module';
import {NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {AddEditCustomerDivisionsComponent} from './add-edit-customer-divisions/add-edit-customer-divisions.component';
@NgModule({
  declarations: [CustomerDivisionsComponent,AddEditCustomerDivisionsComponent],
  imports: [
    CommonModule,
    CustomerDivisionsRoutingModule,
    SharedModule,
    TooltipModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    MessagesModule,
    NgbCarouselModule,
    DropdownModule,
    CalendarModule
  ],
  exports:[AddEditCustomerDivisionsComponent]
})
export class CustomerDivisionsModule { }
