// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

//    prod
  //  apiURL: 'https://app.acumen-portal.co.uk/api/',
  //  mobileServerAPIURL: 'https://api.acumen-portal.co.uk/api/',
  // stitchApiURL: 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/acumenwaste-paopp/service/',


  // Dev
  apiURL: 'http://159.65.148.36:7011/api/',
 mobileServerAPIURL: 'http://159.65.148.36:7010/api/',
 stitchApiURL: 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/acumenwaste-sicpe/service/',

  // Localhost
  // mobileServerAPIURL: 'http://localhost:7010/api/',
  // apiURL: 'http://localhost:7011/api/',
  // stitchApiURL: 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/acumenwaste-sicpe/service/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
