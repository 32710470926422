<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card moduleName="disposal" module="Site" (export)="exportData($event)"
      (addDisposal)="createDisposal($event)" (import)="import($event)" cardClass="table-card" blockClass="p-0">
      <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->


      <p-table #dt [value]="disposalSiteList" (onPage)="onPageEvent($event)" [(selection)]="selectedDisposal"
        dataKey="_id" styleClass="ui-table-customers disposal-site" [rowHover]="true" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
        [globalFilterFields]="searchArr">
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{col.header}}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>

            <!-- <th></th> -->
            <th></th>

            <!-- <th>View</th>
                        <th>Delete</th> -->
            <th></th>
          </tr>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
              <input pInputText type="text" class="ui-inputtext"
                (input)="dt.filter($event.target.value, col.field, 'contains')">
            </th>
            <!-- <th></th> -->
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader" [style.background-color]="car['rowColor']">
            <td *ngFor="let col of cols">
              {{car[col.field]}}
            </td>
            <td class="Delete-btn editDeleteBtns">

              <span (click)="listPrice($event,car)"
                *ngIf="car['type'] == '5e54d71d50b3a90c698c8393'||car['type'] == 'Disposal'"
                class="mob_icon style-toggler ">
                <a href="javascript:void(0);" class="{{car.rowColor?'anchor-cls':''}}">
                  Price
                </a>
              </span>
              <span (click)="listCustomer($event,car)"
                class="mob_icon style-toggler delete_icon">
                <a href="javascript:void(0);" class="{{car.rowColor?'anchor-cls':''}}">
                  Customer
                </a>
              </span>
              <span (click)="listEWCCode($event,car)"
                *ngIf="car['type'] == '5e54d71d50b3a90c698c8393'||car['type'] == 'Disposal'"
                class="mob_icon style-toggler delete_icon">
                <a href="javascript:void(0);" class="{{car.rowColor?'anchor-cls':''}}">EWC codes</a>
              </span>

            </td>


            <!-- <td>
                          <span (click)='editSubject($event,car)'>
                            <i class='fa fa-pencil fa-2x'></i>
                          </span>
                        </td> -->
            <td>
              <span (click)='viewDisposal($event,car)' pTooltip="Click to view a Site" tooltipPosition="top"
                class="mob_icon style-toggler">
                <i class='fa fa-eye fa-2x'></i>
              </span>
              <span (click)='editDisposal($event,car)' pTooltip="Click to edit a Site" tooltipPosition="top"
                class="mob_icon style-toggler delete_icon">
                <i class='fa fa-edit fa-2x'></i>
              </span>
              <span (click)='deleteDisposal(car._id)' pTooltip="Click to delete a Site" tooltipPosition="top"
                class="style-toggler mob_icon delete_icon">
                <i class='fa fa-trash fa-2x'></i>
              </span>
              <!-- <span *ngIf="!car.delete">
                              -
                            </span> -->
            </td>

          </tr>
        </ng-template>
        <ng-template class="noRecords" *ngIf="wasteCategoryList.length==0" pTemplate="emptymessage">
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length+2">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>

          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>

    </app-card>
  </div>
  <!-- tabs card end -->


</div>

<div *ngIf="showAdd">
  <app-add-edit-disposal-sites [isView]="isView" [data]="disposalData" (closeEvents)="callList($event)">
  </app-add-edit-disposal-sites>
</div>
<div *ngIf="showimport">
  <app-importing [isView]="isView" (closeEvents)="callList($event)"></app-importing>
</div>
<div *ngIf="showLink">
  <app-link-disposal-customer [data]="linkData" (closeEvents)="closeAll($event)"></app-link-disposal-customer>
</div>