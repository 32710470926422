import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WasteCategoryRoutingModule } from './waste-category.routing.module';
import { WasteCategoryComponent } from './waste-category.component';
import {SharedModule} from '../../theme/shared/shared.module';
import {NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import { AlertTextComponent } from './alert-text/alert-text.component';
import {TooltipModule} from 'primeng/tooltip';
import { ListCategoryComponent } from './list-category/list-category.component';
import {LinkWastecategoryCustomerComponent} from './link-wastecategory-customer/link-wastecategory-customer.component';
import { AddEditCategoryComponent } from './add-edit-category/add-edit-category.component';
import {AddEditWasteCategoryComponent} from './add-edit-waste-category/add-edit-waste-category.component';
@NgModule({
  declarations: [WasteCategoryComponent,LinkWastecategoryCustomerComponent,AddEditWasteCategoryComponent,AlertTextComponent,ListCategoryComponent,AddEditCategoryComponent],
  imports: [
    CommonModule,
    WasteCategoryRoutingModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    TooltipModule,
    CheckboxModule,
    MessagesModule,
    NgbCarouselModule,
    MultiSelectModule,
    DropdownModule,
    CalendarModule
  ],
  exports:[AddEditWasteCategoryComponent,LinkWastecategoryCustomerComponent,AlertTextComponent,ListCategoryComponent,AddEditCategoryComponent]
})
export class WasteCategoryModule { }
