import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
export class DisposalSitesService {
    constructor(private http: HttpClient) { }
    uploadFiles(data) {
        return this.http.post(environment.apiURL + 'student/upload', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    companyList() {
        return this.http.get(environment.apiURL + 'company/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    importFile(data) {
        console.log('import request: ', data);

        return this.http.post(environment.apiURL + 'disposallocation/import-disposals', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    typeList() {
        return this.http.get(environment.apiURL + 'shared/list-disposal-types').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    parentWasteTypes(data) {
        console.log('data', data);
        return this.http.post(environment.apiURL + 'waste-category/list-parent-waste-category', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    importDisposal(data) {
        console.log('data', data);
        return this.http.post(environment.apiURL + 'disposal/import', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    subCategoryList(data) {
        return this.http.post(environment.apiURL + 'waste-category/list-sub-waste-category', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    /* fetchDisposalLocation() {
        return this.http.get(environment.apiURL + 'disposallocation/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    } */
    fetchDisposalLocation(data?: any) {
        if (!data || data == undefined || data == null) {
            data = {}
        }
        return this.http.post(environment.apiURL + 'disposallocation/list', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    ExportDisposalLocations() {
        return this.http.get(`${environment.apiURL}disposallocation/export-disposalLocations`).pipe(
            tap((response: any) => {
                return response;
            })
        );
        // return this.http.get(`${environment.apiURL}disposallocation/export-disposalLocations`)
    }
    fetchParentWasteTypes() {
        let val;
        return this.http.post(environment.apiURL + 'disposallocation/list', val).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createDisposalLocation(data) {
        return this.http.post(environment.apiURL + 'disposallocation/create', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    departmentList(value) {
        return this.http.get(environment.apiURL + 'department/list?companyId=' + value).pipe(
            tap((response: any) => {
                return response;
            })
        );

    }

    getDisposalDetails(id) {
        return this.http.get(environment.apiURL + 'disposallocation/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateDisposalLocation(data) {
        return this.http.post(environment.apiURL + 'disposallocation/update', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    deleteStudent(id) {
        return this.http.delete(environment.apiURL + 'student/delete/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }



    // updateUser(data): Observable<any> {
    //     return this.http.post(environment.apiURL + 'user/update', data).pipe(
    //         tap((res: any) => {
    //             return res;
    //         })
    //     );
    // }
    wasteTypeList() {
        return this.http.get(environment.apiURL + 'wastetype/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    create(registerForm) {
        return this.http.post(environment.apiURL + 'user/create', registerForm).pipe(tap((res) => res));
    }
    // fetchUser() {
    //     return this.http.get(environment.apiURL + 'user/list').pipe(tap((res) => res));
    // }
    deleteSite(id) {
        return this.http.delete(environment.apiURL + 'disposallocation/delete/' + id).pipe(tap((res) => res));
    }
    deleteParentWasteType(id) {
        return this.http.delete(environment.apiURL + 'disposallocation/delete/' + id).pipe(tap((res) => res));
    }
}
