import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    constructor(private http: HttpClient) {}

    fetchReports() {
        return this.http.get(environment.apiURL + 'reports/list').pipe(map((res: any) => {
            return res;
        }
        )
        );
    }
    fetchReportsById(id) {
        return this.http.get(environment.apiURL + 'reports/' + id).pipe(map((res: any) =>{
        return res.data;
        } ));
    }
    deleteReport(id) {
        return this.http.delete(environment.apiURL + 'reports/delete/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    saveReports(reportDetails: any) {
        if (reportDetails.reportAccepted == true || reportDetails.reportAccepted == "true") {
            reportDetails.acceptedDate = new Date();
        } else {
            reportDetails.acceptedDate = null;
        }
        delete reportDetails._id;
        return this.http.post(environment.apiURL + 'reports/update', reportDetails).pipe(map((res: any) => res.data));
    }
}
