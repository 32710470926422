<div class="row">
    <!-- tabs card start -->
    <div class="col-sm-12">
        <app-card moduleName="Vehicles" module="Vehicle" (addVehicle)="createVehicle($event)" cardClass="table-card" blockClass="p-0">
            <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
              <p-table #dt [value]="vehicleList" [(selection)]="selectedVehicles" dataKey="_id" styleClass="ui-table-customers" [rowHover]="true"
              [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]" 
              [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [filterDelay]="0" [globalFilterFields]="searchArr">
              <ng-template pTemplate="caption">
                  <div class="ui-table-globalfilter-container">
                      <input pInputText type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
                  </div>
              </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                      <b>{{col.header}}</b>
                      <p-sortIcon [field]="col.field"></p-sortIcon>
          
                    </th>
                    <!-- <th>Edit</th> -->
                    <!-- <th>View</th>
                    <th>Edit</th> -->
                    <!-- <th>Delete</th> -->
                    <th></th>
                    <th></th>
                  </tr>
                  <tr>
                    <th *ngFor="let col of cols" [ngSwitch]="col.field">
                      <input pInputText type="text" class="ui-inputtext" (input)="dt.filter($event.target.value, col.field, 'contains')">
                    </th>
                    <th></th>
                    <th></th>
                    
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-car>
                  <tr *ngIf="!showLoader">
                    <td *ngFor="let col of cols">
                      {{car[col.field]}}
                    </td>
                    <!-- <td>
                      <span (click)='viewStudent($event,car)'>
                        <i class='fa fa-eye fa-2x'></i>
                      </span>
                    </td>
                    <td>
                      <span (click)='editStudent($event,car)'>
                        <i class='fa fa-pencil fa-2x'></i>
                      </span>
                    </td> -->
                    <td>
                      <span>   
                        <p-checkbox  name="isActive" ngDefaultControl [(ngModel)]="car.isActive" class="map-checkbox text-allign-btns" (onChange)="change($event,car)" label='Active'  binary="false"></p-checkbox>
                       </span>
                    </td>
                    <td>
                        <span (click)='viewVehicle($event,car)' class="mob_icon" pTooltip="Click to view a Vehilce" tooltipPosition="top">
                            <i class='fa fa-eye fa-2x'></i>
                          </span>
                      <span (click)='editVehicle($event,car)' class="mob_icon delete_icon" pTooltip="Click to edit a Vehilce" tooltipPosition="top">
                        <i class='fa fa-edit fa-2x'></i>
                      </span>
                   
                      <span (click)='deleteVehicle(car._id)' class="mob_icon delete_icon" pTooltip="Click to delete a Vehilce" tooltipPosition="top">
                        <i class='fa fa-trash fa-2x'></i>
                      </span>
                    </td>
                  
                  </tr>
                </ng-template>
                <ng-template class="noRecords" *ngIf="vehicleList.length==0" pTemplate="emptymessage">
                  <tr class="noRecords" *ngIf="!showLoader">
                    <td [attr.colspan]="cols.length+2">
                      <div class="my-center-text">
                        No Records to display
                      </div>
                    </td>
          
                  </tr>
                </ng-template>
              </p-table>
            <!-- </perfect-scrollbar> -->
          </app-card>

    </div>
    <div class="loader" *ngIf="showLoader" ></div>

    <!-- tabs card end -->
  

  </div>
  <div *ngIf="showAdd">
  <app-add-edit-vehicle [isView]="isView" [data]="vehicleData" (closeEvents)="callList($event)" ></app-add-edit-vehicle>
</div>
  