import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { CustomersService } from '../customers.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-disposal-category',
  templateUrl: './link-disposal-category.component.html',
  styleUrls: ['./link-disposal-category.component.scss']
})
export class LinkDisposalCategoryComponent implements OnInit {
  itemList = [];
  title: any;
  slide = true;
  selected: any;
  @Input('isDisposal') isDisposal = false;
  @Input('isCategory') isCategory = false;
  @Input('data') linkData: any;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();

  constructor(private service: CustomersService, private cdr: ChangeDetectorRef, private messageService: MessageService) { }

  ngOnInit() {
    console.log('isCategory', this.isCategory);
    let flag = true;
    if (this.isCategory == true) {
      this.title = 'Waste categories for the ' + this.linkData.companyName;
      this.service.wasteTypeList().subscribe(response => {
        console.log('response', response.data);

        response.data.forEach(element => {

          if (this.linkData.wasteTypes) {
            console.log('loop1', this.linkData, this.slide);

            if (this.linkData.wasteTypes.length > 0) {
              var exists = this.linkData.wasteTypes.find(({ _id }) =>
                element._id === _id);
              if (exists) {
                element.checked = true;
                this.itemList.push({ label: element.wasteType, value: element._id, checked: true });

              } else {
                element.checked = false;
                this.itemList.push({ label: element.wasteType, value: element._id, checked: false });

              }

            } else {
              console.log('loop3', this.slide);

              this.itemList.push({ label: element.wasteType, value: element._id, checked: false });
            }
          } else {
            console.log('loop2');

            this.itemList.push({ label: element.wasteType, value: element._id, checked: false });
            console.log('this.itemList', this.itemList, this.slide);

          }

        });
        this.setFlag();
      });

    }
    if (this.isDisposal == true) {
      this.title = 'Disposal sites for the ' + this.linkData.companyName;
      this.service.fetchDisposalLocation({ linkDisposalLocations: true }).subscribe(response => {
        console.log('response', response.data);
        response.data.forEach(element => {
          if (this.linkData.disposalLocations) {
            if (this.linkData.disposalLocations.length > 0) {
              var exists = this.linkData.disposalLocations.find(({ _id }) =>
                element._id === _id);
              if (exists) {
                element.checked = true;
                this.itemList.push({ label: element.Name, value: element._id, checked: true });

              } else {
                element.checked = false;
                this.itemList.push({ label: element.Name, value: element._id, checked: false });

              }

            } else {
              this.itemList.push({ label: element.Name, value: element._id, checked: false });

            }
          } else {
            this.itemList.push({ label: element.Name, value: element._id, checked: false });

          }

        });
        this.setFlag();

      });

    }


  }
  changeEvent(event) {
    console.log('change event');

    this.setFlag();
  }
  setFlag() {
    console.log('flag');

    let flag = true;
    this.itemList.forEach(element => {
      if (element.checked == false) {
        flag = false;
      }
      this.selected = flag;
    });
  }
  onChange(event) {
    console.log('value', this.selected);
    if (this.selected == true) {
      this.itemList.forEach(element => {
        element.checked = true;
      });
    } else {
      this.itemList.forEach(element => {
        element.checked = false;
      });
    }
    this.cdr.detectChanges();
  }
  closeEvent(event) {
    this.slide = false;
    setTimeout(e => {
      this.closeEvents.emit(true);
    }, 1000);
  }
  linkItems(event) {
    let value: any = '';
    let title;
    const disposalLocations = [];
    const wasteTypes = [];
    title = this.isDisposal == true ? 'Dsiposal site' : 'Waste category';
    value = this.linkData;
    value._id = this.linkData._id;
    if (this.isDisposal == true) {
      this.itemList.forEach(element => {
        if (element.checked == true) {
          disposalLocations.push(element.value);
        }
      });
      // if(this.linkData.disposalLocations.length > 0){
      //   this.linkData.disposalLocations.forEach(element => {
      //     disposalLocations.push(element._id);

      //   });
      // }
      value.disposalLocations = disposalLocations;
    }
    if (this.isCategory == true) {
      this.itemList.forEach(element => {
        if (element.checked == true) {
          wasteTypes.push(element.value);
        }
      });
      // if(this.linkData.wasteTypes.length > 0){
      //   this.linkData.wasteTypes.forEach(element => {
      //     wasteTypes.push(element._id);

      //   });
      // }
      value.wasteTypes = wasteTypes;

    }
    console.log('Update value', this.linkData);

    this.service.updateCompany(value).subscribe((data) => {
      if (data.status === 200) {
        this.messageService.add({
          severity: 'success',
          summary: 'Linked' + title,
          detail: title + 'Linked successfully'
        });



      } else if (data.status === 500) {
        this.messageService.add({
          severity: 'error',
          summary: 'Linked' + title,
          detail: data.error.message.errmsg
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Linked' + title,
          detail: 'Unable to update' + title + '.Please try again.'
          //  detail: 'Failed to update the user details'
        });
      }
      this.closeEvent(true);

    });


  }

}
