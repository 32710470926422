import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  msgs = [];

  constructor(private router: Router,private translateService:TranslateService) { }

  ngOnInit() {
    this.translateService.setDefaultLang('en');

    const url = window.location.href.split(window.location.hostname)[1].split('/').slice(1).join('/');
    console.log('url', url);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (window.location.pathname === '/') {
      let token = localStorage.getItem('token');
      if(token){
      this.router.navigate['dashboard/analytics'];
      }
    }
    console.log('app called');
  }
}
