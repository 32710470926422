import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { MapsAPILoader, LatLngLiteral } from '@agm/core';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { NgxSpinnerService } from "ngx-spinner";
import { StaffService } from "../staff-data.service";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-export-staff',
  templateUrl: './export-staff.component.html',
  styleUrls: ['./export-staff.component.scss']
})
export class ExportStaffComponent implements OnInit {
  isUpdate = false;
  slide = true;
  isEdit = false;
  companys = [];
  companySelected: any;
  deptSelected: any;
  selected: boolean = true;
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(
    private dialogService: DialogService,
    private service: StaffService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private loader: NgxSpinnerService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    console.log('on init staff export');
    this.loadCompany()
  }

  async loadCompany() {
    const company: string = localStorage.getItem('companyID')
    this.service.companyList(`companyId=${company}`).subscribe((resp) => {
      this.companys = resp.data
      this.selectAllCompanys()
    }, err => {
      console.log('load company error: ', err);

    })
  }

  exportStaffData(event) {
    let { selectedCompanys, selectedDepts } = this.setRequest();

    console.log('selected companys: ', selectedCompanys);
    console.log('selected depts: ', selectedDepts);

    let request: object = {
      company: selectedCompanys,
      department: selectedDepts,
    }

    this.service.exportStaff(request).subscribe((resp) => {
      console.log('export success: ', resp);
      if (resp.data && resp.data.length > 0) {
        this.downloadCSV(resp.data, 'staff')
      } else {
        this.messageService.add({
          severity: 'success',
          summary: 'Export',
          detail: 'Sorry, no records were found. Please adjust your search criteria and try again.'
        });
      }
      this.closeEvent(true)
    }, err => {
      console.log('export error: ', err);
    })
  }

  private setRequest() {
    console.log('all: ', this.companys);

    let selectedCompanys = [];
    let selectedDepts = [];
    for (let i = 0; i < this.companys.length; i++) {
      const company = this.companys[i];
      if (company.checked) {
        selectedCompanys.push(company.companyId);
      }
      for (let j = 0; j < company.departments.length; j++) {
        const dept = company.departments[j];
        if (dept.checked) {
          selectedDepts.push(dept.deptId);
        }
      }
    }
    return { selectedCompanys, selectedDepts };
  }

  onChange(event, item) {
    console.log('on change: ', item, ' :: ', this.selected);
    if (!item && this.selected) {
      console.log('TRUE');
      this.selectAllCompanys();
    } else if (!item && !this.selected) {
      this.deSelectAllCompanys()
      console.log('FALSE');
    } else {
      console.log('ELSE: ');
      if (item.checked && item.companyId) {
        for (const dept of item.departments) {
          dept.checked = true
        }
      } else if (!item.checked && item.companyId) {
        for (const dept of item.departments) {
          dept.checked = false
        }
      }
    }

    // this.subCategoryList.forEach(element =>{
    //     if(element.checked == false){
    //         element.waste_sub_sub_details.forEach(ele => {
    //             ele.checked = false;
    //         });
    //    }else{
    //       if(element.ewc_code == item.ewc_code){
    //           element.waste_sub_sub_details.forEach(ele => {
    //               ele.checked = true;
    //           });
    //       }
    //     }
    // })
  }

  private selectAllCompanys() {
    for (let i = 0; i < this.companys.length; i++) {
      const element = this.companys[i];
      element.checked = true;
      for (let index = 0; index < element.departments.length; index++) {
        const dept = element.departments[index];
        dept.checked = true
      }
    }
  }
  private deSelectAllCompanys() {
    for (let i = 0; i < this.companys.length; i++) {
      const element = this.companys[i];
      for (let index = 0; index < element.departments.length; index++) {
        const dept = element.departments[index];
        dept.checked = false
      }
      element.checked = false;
    }
  }

  closeEvent(event) {
    if (this.isEdit == false) {
      this.slide = false;
      this.cdr.detectChanges();
      setTimeout(e => {
        this.closeEvents.emit(true);
      }, 1000)

    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
    }
  }

  async downloadCSV(data: any, fileName: string = 'staff') {
    const date = `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}_${new Date().getHours()}:${new Date().getMinutes()}`
    const exportFileName = `${fileName}_${date}.csv`

    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, exportFileName);
  }

}
