import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { DisposalSitesService } from '../disposal-sites.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-list-price',
  templateUrl: './list-price.component.html',
  styleUrls: ['./list-price.component.scss']
})
export class ListPriceComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen:any;
  companyID:any;
  showAdd = false;
  showLoader = true;
  data:any;
  selectedCatgeory:any;
  category :any;
  selectSubCategory = false;
  title:any;
  parentWasteType :any;
  wasteTypeData:any;
  ide:any;
  categoryList = [];
  cols = [
    // {
    //     header: 'Big change Id',
    //     field: 'Id'
    // },
    // {
    //     header: 'Company ID',
    //     field: 'ParentId'
    // },

    {
        header: 'Waste Category',
        field: 'waste_type_name'
    },
    {
        header: 'Min Tonnage',
        field: 'min_tonnage'
    },
    {
        header: 'Cost per tonne',
        field: 'cost_per_tonne'
    },
    // {
    //     header: 'Metal rebate',
    //     field: 'metal_rebate'
    // }

];
searchArr = [];
  vehicleTypeData:any;
  adminUserList = [];
  selectedPrice:any;
  vehicleTypeList = [];
  id:any;
  viewData:any;
  priceList = [];
  disposalData:any;
  constructor(private service:DisposalSitesService,private route:ActivatedRoute,private translateService: TranslateService,private cdr:ChangeDetectorRef,public dialogService: DialogService,public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,private confirmDialogService:ConfirmationService,private router:Router) { }
  addPrice(event) {
    this.category = false;
    this.showAdd = true;
    this.disposalData = this.data;
    this.cdr.detectChanges();    

}
editPrice(event, data) {
  this.category = false;
  this.showAdd = true;
  this.isUpdate = true;
  this.disposalData = this.data;
  this.viewData = data;
  this.cdr.detectChanges(); 

}
callList(event){
  console.log('call list');
  this.showAdd = false;
  this.isView = false;
  this.isUpdate = false;
  this.viewData = {};
  this.cdr.detectChanges();
    this.fetchPrice();
}
closeEvent(event) {
    this.router.navigate(['disposalsites']);
}
removePrice(event, data) {
    this.confirmDialogService.confirm({
        message: 'Are you sure that you want to delete the Price?',
        accept: () => {
            //  this.service.deleteParentWasteType(data.Id).pipe(takeUntil(this.ngUnSubscribe)).subscribe((data: any) =>{
            if (data.status === 200) {

            }
            //   this.fetchUser();
            //} );
            this.updatePrice(event, data);
        }
    });

}
updatePrice(event: any, val: any) {
    let value: any = '';
    let data;
    const priceArr = [];
    //  this.TipSitesForm.value.location = {lat:this.lat,lng:this.lng};
    value = this.data;
    if (value.disposal_type_details.price.length > 0) {

        value.disposal_type_details.price.forEach(element => {

            if (element.waste_type !== val.waste_type) {
                data = {
                    waste_type: element.waste_type, min_tonnage: element.min_tonnage,
                    cost_per_tonne: element.cost_per_tonne, metal_rebate: element.metal_rebate
                };
                priceArr.push(data);
            }
        });
        value.disposal_type_details.price = priceArr;

    }
    value.removePrice = true
    console.log('value 11', value);
    this.service.updateDisposalLocation(value).subscribe((data: any) => {
        if (data.status === 200) {
            this.messageService.add({
                severity: 'success',
                summary: 'Delete Price',
                detail: 'Price deleted successfully'
            });

            this.fetchPrice();
        } else if (data.status === 500) {
            this.messageService.add({
                severity: 'error',
                // summary: 'Update Disposal site',
                detail: data.error.message.errmsg
            });
        }
        else {
            this.messageService.add({
                severity: 'error',
                //  summary: 'Update Disposal site',
                // detail: 'Failed to quotes the Tip site details'
                detail: 'Unable to update Tip site.Please try again.'
            });
        }

    });
}



ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.fetchPrice();
    if (this.cols.length > 0){
        this.cols.forEach(element => {
          this.searchArr.push(element.field);
        });
      }
}
fetchPrice() {
    this.service.getDisposalDetails(this.id).subscribe((data: any) => {
        if (data.data.disposal_type_details) {
            if (data.data.disposal_type_details.price.length > 0) {
                let arr = []
                //this.userDetails = data.data.disposal_type_details.price;
                if (data.data.disposal_type_details.price.length > 0) {
                    data.data.disposal_type_details.price.forEach(ele => {
                        if (Object.keys(ele).length === 0) {
                            console.log('true');
                            arr = [];
                        } else {
                            console.log('false');
                            arr.push(ele);
                        }
                    });
                }
                this.priceList = arr;

            } else {
                this.priceList = [];
            }
        }
        this.showLoader = false;
        this.data = data.data;
    });
}





}
