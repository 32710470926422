import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
/*import { AnimationService, AnimatorModule } from 'css-animator';*/
import {CalendarModule} from 'primeng/calendar';
import {TooltipModule} from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    CalendarModule,
    TooltipModule
    /*AnimatorModule*/
  ],
  declarations: [CardComponent],
  exports: [CardComponent],
  providers: [/*AnimationService*/]
})
export class CardModule { }
