import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { NavGuardService } from './nav-guard.service';
import { LoginComponent } from './login/login.component';
import { HazardousComponent } from './demo/hazardous/hazardous.component';
import { SkipComponent} from './demo/skip/skip.component';
import {ViewJobDetailsComponent} from'./demo/job/view-job-details/view-job-details.component';
import { WasteDescriptionComponent } from './demo/waste-description/waste-description.component';
import { ViewReportsComponent } from './demo/reports/view-reports/view-reports.component';
import { ListCategoryComponent } from './demo/waste-category/list-category/list-category.component';
import { ListEWCCodeComponent } from './demo/disposal-sites/list-ewc-code/list-ewc-code.component';
import { ListPriceComponent } from './demo/disposal-sites/list-price/list-price.component';
const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard/analytics',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'vehicle',
        loadChildren: () => import('./demo/vehicle/vehicle.module').then(module => module.VehicleModule)
      },
      {
        path: 'job',
        loadChildren: () => import('./demo/job/job.module').then(module => module.JobModule)
      },
      {
        path: 'job/data/:id',
        component: ViewJobDetailsComponent 
      },
      {
        path: 'admin/users',
        loadChildren: () => import('./demo/admin-users/admin-users.component.module').then(module => module.AdminUserModule)
      },
      {
        path: 'wastecategory',
        loadChildren: () => import('./demo/waste-category/waste-category.module').then(module => module.WasteCategoryModule)
      },
      {
        path: 'waste-description',
        component: WasteDescriptionComponent 
      },
      {
        path: 'Hazardous',
        component: HazardousComponent 
      },
      {
        path: 'skip',
        component: SkipComponent 
      },
      {
        path: 'customers',
        loadChildren: () => import('./demo/customers/customers.module').then(module => module.CustomersModule)
      },
      {
        path: 'customerdivisions',
        loadChildren: () => import('./demo/customer-divisions/customer-divisions.module').then(module => module.CustomerDivisionsModule)
      },
      
      {
        path: 'disposalsites',
        loadChildren: () => import('./demo/disposal-sites/disposal-sites.module').then(module => module.DisposalSitesModule)
      },
      {
        path: 'customer-sites',
        loadChildren: () => import('./demo/customer-site/customer-site.module').then(module => module.CustomerSiteModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./demo/reports/reports.module').then(module => module.ReportsModule)
      },
      {
        path: 'reports/:id',
        component: ViewReportsComponent
      },
      
      {
        path: 'disposalsites/listcategory/:id',
        component: ListEWCCodeComponent
      },
      
      {
        path: 'disposalsites/listPrices/:id',
        component: ListPriceComponent
      },
      {
        path: 'wastecategory/listcategory/:id',
        component: ListCategoryComponent
      },
      {
        path: 'vehicletypes',
        loadChildren: () => import('./demo/vehicle-types/vehicle-types.module').then(module => module.VehicleTypesModule)
      },
      {
        path: 'staff',
        loadChildren: () => import('./demo/staff/staff.module').then(module => module.StaffModule)
      },
      {
        path: 'layout',
        loadChildren: () => import('./demo/pages/layout/layout.module').then(module => module.LayoutModule)
      },
      {
        path: 'widget',
        loadChildren: () => import('./demo/widget/widget.module').then(module => module.WidgetModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./demo/users/users.module').then(module => module.UsersModule)
      },
      {
        path: 'basic',
        loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(module => module.UiBasicModule)
      },
      {
        path: 'advance',
        loadChildren: () => import('./demo/ui-elements/ui-adv/ui-adv.module').then(module => module.UiAdvModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(module => module.FormElementsModule)
      },
      {
        path: 'tbl-bootstrap',
        loadChildren: () => import('./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module').then(module => module.TblBootstrapModule)
      },
      {
        path: 'tbl-datatable',
        loadChildren: () => import('./demo/pages/tables/tbl-datatable/tbl-datatable.module').then(module => module.TblDatatableModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(module => module.CoreChartModule)
      },
      {
        path: 'maps',
        loadChildren: () => import('./demo/pages/core-maps/core-maps.module').then(module => module.CoreMapsModule)
      },
      {
        path: 'email',
        loadChildren: () => import('./demo/application/email/email.module').then(module => module.EmailModule)
      },
      {
        path: 'task',
        loadChildren: () => import('./demo/application/task/task.module').then(module => module.TaskModule)
      },
      {
        path: 'todo',
        loadChildren: () => import('./demo/application/todo/todo.module').then(module => module.TodoModule)
      },
      {
        path: 'gallery',
        loadChildren: () => import('./demo/application/gallery/gallery.module').then(module => module.GalleryModule)
      },
      {
        path: 'helpdesk',
        loadChildren: () => import('./demo/application/helpdesk/helpdesk.module').then(module => module.HelpdeskModule)
      },
      {
        path: 'editor',
        loadChildren: () => import('./demo/extension/editor/editor.module').then(module => module.EditorModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./demo/extension/invoice/invoice.module').then(module => module.InvoiceModule)
      },
      {
        path: 'full-calendar',
        loadChildren: () => import('./demo/extension/full-event-calendar/full-event-calendar.module')
          .then(module => module.FullEventCalendarModule)
      },
      {
        path: 'file-upload',
        loadChildren: () => import('./demo/extension/files-upload/files-upload.module').then(module => module.FilesUploadModule)
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
    ],
    canActivateChild: [ NavGuardService ]

  },
  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./demo/pages/maintenance/maintenance.module').then(module => module.MaintenanceModule)
      },
      { path: '**', component: LoginComponent },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
