import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { JobService } from '../job-data.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Table } from 'primeng/table/table';

@Component({
  selector: 'app-job-data',
  templateUrl: './job-data.component.html',
  styleUrls: ['./job-data.component.scss']
})
export class JobDataComponent implements OnInit {
  @ViewChild('dt', { static: false }) table: Table;
  pageJobIndex;
  cols = [];
  jobList = [];
  selectedJob: any;
  showAdd = false;
  companyID: any;
  isAcumen: any;
  jobData = {};
  startDate; //= new Date();
  endDate; // = new Date();
  showLoader = true;
  searchArr = [];
  showExportLoader: boolean;
  constructor(private translateService: TranslateService,
    private JobService: JobService, private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    private router: Router, private messageService: MessageService,
    private confirmDialogService: ConfirmationService) {

  }

  ngOnInit() {
    console.log('on init: ', this.startDate, ' : ', this.endDate);
    const dateObjects = this.JobService.getStartAndEndDate()
    console.log('on init dateObjects: ', dateObjects);

    if (dateObjects.startDate) {
      this.startDate = dateObjects.startDate
    } else {
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 30);
    }
    if (dateObjects.endDate) {
      this.endDate = dateObjects.endDate
    } else {
      this.endDate = new Date();
    }


    const jobID = localStorage.getItem('jobID');
    this.companyID = localStorage.getItem('companyID');
    this.pageJobIndex = parseInt(localStorage.getItem('pageJobIndex'));
    this.isAcumen = localStorage.getItem('isAcumen');


    //  this.setPage(2);
    if (this.pageJobIndex) {
      localStorage.removeItem('pageJobIndex');
    }
    if (jobID) {
      let event: any;
      let data = { _id: jobID };
      localStorage.removeItem('jobID');
      console.log('jobId', data)
      //  this.viewJob(event,data);
    }

    this.fetchJobList({ startDate: this.startDate, endDate: this.endDate });
    if (this.isAcumen == true || this.isAcumen == 'true') {
      this.cols = [
        {
          header: 'Job No',
          field: 'job_reference'
        },
        {
          header: 'Driver',
          field: 'employeeName'
        },
        {
          header: 'Vehicle reg no.',
          field: 'vehicle_reg_number'
        },
        {
          header: 'Company Name',
          field: 'companyName'
        },
        {
          header: 'Job start time',
          field: 'createdAt'
        },
        {
          header: 'Job Type',
          field: 'jobType'
        },
        {
          header: 'Completed',
          field: 'isCompleted'
        },
        {
          header: 'Last location job number',
          field: 'last_location_code'
        },
        {
          header: 'Job completed time',
          field: 'compltedAt'
        },

      ];
    } else {
      this.cols = [
        {
          header: 'Job No',
          field: 'job_reference'
        },
        {
          header: 'Driver',
          field: 'employeeName'
        },
        {
          header: 'Job start time',
          field: 'createdAt'
        },
        {
          header: 'Completed',
          field: 'isCompleted'
        }, {
          header: 'Last location job number',
          field: 'finalCollectionDetails.code'
        },
        {
          header: 'Job completed time',
          field: 'compltedAt'
        }
      ];
    }
    if (this.cols.length > 0) {
      this.cols.forEach(element => {
        this.searchArr.push(element.field);
      });
    }
  }
  onPageEvent(event) {
    console.log('event', event);
    this.pageJobIndex = (event.first / event.rows + 1);
  }
  setPage(n: number) {
    this.table.reset();
    let paging = {
      first: ((n - 1) * this.table.rows),
      rows: this.table.rows
    };
    console.log('table values', paging);
    this.table.first = paging.first;
    this.table.rows = paging.rows;
  }
  searchJobs(event) {
    console.log('search: ', this.startDate, ' : ', this.endDate);
    this.JobService.setStartAndEndDate(this.startDate, this.endDate)
    this.showLoader = true;
    this.fetchJobList({ startDate: this.startDate, endDate: this.endDate });
  }
  viewJob(event, data) {
    // this.JobService.jobDetails(data._id).subscribe((response) => {
    //   console.log('response',response);
    //   this.jobData = response.data;
    //   this.showAdd = true;
    //   this.cdr.detectChanges();
    // });
    if (this.pageJobIndex != 1) {
      let pageNum = this.pageJobIndex.toString();
      localStorage.setItem('pageJobIndex', pageNum);
    }
    this.router.navigate(['job/data/' + data._id]);
  }

  async getBigChangeNumber(job) {
    console.log('job to get bigchange no.: ', job);
    this.JobService.getBigChangeNumber(job).subscribe((resp) => {
      console.log(resp.message);
      if(resp.message!="Disposal location is not added"){
        this.fetchJobList({ startDate: this.startDate, endDate: this.endDate });
        this.messageService.add({
        severity: 'success',
        summary: 'Job',
        detail: resp.message
      });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Job',
          detail: resp.message
        });
      }
      
    }, err => {
      this.messageService.add({
        severity: 'error',
        summary: 'Job',
        detail: err
      });
      console.error('error: ', err);
    })
  }


  callList(event) {
    console.log('call list', event, this.jobData);
    if (event == false) {
      // this.showAdd = false;
      // this.cdr.detectChanges();
      if (this.pageJobIndex != 1) {
        let pageNum = this.pageJobIndex.toString();
        localStorage.setItem('pageJobIndex', pageNum);
      }
      this.router.navigate(['job/data/' + this.jobData['_id']]);
    } else {
      this.showAdd = false;
      this.jobData = {};
      this.cdr.detectChanges();
      this.fetchJobList({});
    }
  }
  deleteJob(id) {
    console.log('id', id, this.confirmDialogService);
    this.confirmDialogService.confirm({
      message: 'Are you sure that you want to delete the Job?',
      accept: () => {
        this.JobService.deleteJob(id).subscribe((data: any) => {
          if (data.status === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Delete Job',
              detail: 'Job details deleted successfully'
            });
            this.fetchJobList({});
          }
        });
      }
    });
  }

  async exportJob(event) {
    console.log('clicked job export: ', this.startDate, ' : ', this.endDate);
    let request = {
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.showExportLoader = true;
    this.JobService.exportJobs(request).subscribe((resp) => {
      console.log('export success: ', resp);
      this.showExportLoader = false;
      if (resp.data && resp.data.length > 0) {
        this.downloadCSV(resp.data, 'jobs')
      } else {
        this.messageService.add({
          severity: 'success',
          summary: 'Export',
          detail: 'Sorry, no records were found. Please adjust your search criteria and try again.'
        });
      }
      // this.closeEvent(true)
    }, err => {
      console.log('export error: ', err);
    })
  }

  reload(event) {
    console.log('reload event called: ', this.startDate, ' : ', this.endDate);
    this.showLoader = true;
    this.fetchJobList({ startDate: this.startDate, endDate: this.endDate });
  }

  fetchJobList(data) {
    let jobArr = [];
    this.JobService.jobList(data).subscribe((response) => {
      if (response.data.length > 0) {
        jobArr = response.data;
      }
      this.jobList = jobArr;
      console.log('Job list 1', this.jobList);
      this.showLoader = false;
      if (this.jobList.length > 0) {
        this.jobList.forEach((ele: any) => {
          console.log('A: ', this.isAcumen, ' : ', ele.bigChange_job_reference, ' : ', this.isAcumen && this.isAcumen === true && (!ele.bigChange_job_reference || ele.bigChange_job_reference === ''));

          if ((this.isAcumen && (this.isAcumen === 'true' || this.isAcumen === true)) && (!ele.bigChange_job_reference || ele.bigChange_job_reference === '')) {
            ele.showBigChangeButton = true
          }
          ele.createAt1 = ele.createdAt;
          ele.createdAt = ele.createdAt ? moment(ele.createdAt).format("DD/MM/YYYY h:mm a") : '-';
          ele.compltedAt = ele.compltedAt ? moment(ele.compltedAt).format("DD/MM/YYYY h:mm a") : '-';
          if (ele.isCompleted == 'true' || ele.isCompleted == true) {
            ele.isCompleted = 'Yes';
          } else {
            ele.isCompleted = 'No';
          }
          if (ele.job_reference) {
            if (ele.job_reference.match('COND')) {
              ele.delete = false;
            } else {
           //   ele.delete = true;
            }
          }
        });
      }

      console.log('Job list 2', this.jobList);


      if (this.pageJobIndex && this.pageJobIndex > 1) {
        console.log('page index', this.pageJobIndex);
        this.setPage(this.pageJobIndex);

      }

    });
  }


  async downloadCSV(data: any, fileName: string = 'jobs') {
    const date = `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}_${new Date().getHours()}:${new Date().getMinutes()}`
    const exportFileName = `${fileName}_${date}.csv`

    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, exportFileName);
  }



}
