import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleDataRoutingModule } from './vehicle-data-routing.module';
import { VehicleDataComponent } from './vehicle-data.component';
import {SharedModule} from '../../../theme/shared/shared.module';
import {NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import { AddEditVehicleComponent } from '../add-edit-vehicle/add-edit-vehicle.component';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {TooltipModule} from 'primeng/tooltip';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
  declarations: [VehicleDataComponent,AddEditVehicleComponent],
  imports: [
    CommonModule,
    VehicleDataRoutingModule,
    TooltipModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    DropdownModule,
    CheckboxModule,
    InputSwitchModule,
    ConfirmDialogModule,
    MessageModule,
    DynamicDialogModule,
    MessagesModule,
    NgbCarouselModule,
    TableModule,
    ToastModule
  ],
  exports:[AddEditVehicleComponent]
})
export class VehicleDataModule { }
