<div class="">
  <div id="styleSelector" class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
          <div class='row add-edit-header' *ngIf="!isView">
            <div class="ui-g-10">
              <h4 *ngIf='!isUpdate'>Import</h4>
              <h4 *ngIf='isUpdate'>Edit Disposal Site</h4>
            </div>
            <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Disposal site"
              tooltipPosition="top">
              <i class="fa fa-window-close fa-2x "></i>
            </div>
          </div>
          <div class="">
            <form [formGroup]="demoForm">
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="icon">File Upload</label>
                  <file-upload formControlName="files"></file-upload>
                </div>
              </div>

            </form>
            <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
              <div class='ui-g-12'>
                <div class="text-center m-t-20">
                  <button class="btn btn-primary" (click)="importfile($event)">Import</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ngx-spinner template="<img src='assets/images/loader.gif' />">
  <p style="font-size: 20px;font-size: 20px;
  color: white;
  position: relative;
  top: 22vh;">Importing...</p>
</ngx-spinner> -->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color='#3bafda' type="ball-clip-rotate" [fullScreen]="true">
  <p style="font-size: 20px;font-size: 20px;
color: white;
position: relative;
top: 22vh;">Importing...</p>
</ngx-spinner>