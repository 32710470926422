import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
export class StaffService {
    constructor(private http: HttpClient) { }
    uploadFiles(data) {
        return this.http.post(environment.apiURL + 'student/upload', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }

    fetchUser() {
        return this.http.get(environment.apiURL + 'user/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createUser(data) {
        return this.http.post(environment.apiURL + 'user/create', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }

    exportStaff(data: object) {
        return this.http.post(environment.apiURL + 'user/export-staff', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }

    importStaff(data) {
        console.log('import staff request: ', data);
        return this.http.post(environment.apiURL + 'user/import-staff', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }

    departmentList(value) {
        return this.http.get(environment.apiURL + 'department/list?companyId=' + value).pipe(
            tap((response: any) => {
                return response;
            })
        );

    }
    companyList(params?: string) {
        console.log('params: ', params);

        let apiEndPoint: string = 'company/list'
        if (params) {
            apiEndPoint = `${apiEndPoint}?${params}`
        }
        return this.http.get(environment.apiURL + apiEndPoint).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    getUserDetails(id) {
        return this.http.get(environment.apiURL + 'user/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateUser(data) {
        return this.http.post(environment.apiURL + 'user/update', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    deleteStudent(id) {
        return this.http.delete(environment.apiURL + 'student/delete/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }



    // updateUser(data): Observable<any> {
    //     return this.http.post(environment.apiURL + 'user/update', data).pipe(
    //         tap((res: any) => {
    //             return res;
    //         })
    //     );
    // }
    create(registerForm) {
        return this.http.post(environment.apiURL + 'user/create', registerForm).pipe(tap((res) => res));
    }
    // fetchUser() {
    //     return this.http.get(environment.apiURL + 'user/list').pipe(tap((res) => res));
    // }
    deleteUser(userID) {
        return this.http.delete(environment.apiURL + 'user/delete/' + userID).pipe(tap((res) => res));
    }
}
