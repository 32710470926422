import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DisposalSitesService } from '../disposal-sites.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { MapsAPILoader, LatLngLiteral } from '@agm/core';

@Component({
    selector: 'app-add-edit-disposal-sites',
    templateUrl: './add-edit-disposal-sites.component.html',
    styleUrls: ['./add-edit-disposal-sites.component.scss']
})
export class AddEditDisposalSitesComponent implements OnInit {
    wasteCategoryForm: FormGroup;
    isUpdate = false;
    slide = true;
    isEdit = false;
    isAcumen: any;
    companyID: any;
    companyList = [{ label: 'Please Select', value: '' }];
    typeList = [{ label: 'Please Select', value: '' }];
    data: any = [];
    wasteTypeData = [];
    wasteCategoryList = [];
    allWasteCategoryList = [];
    parentWasteTypesList = [];
    TipSitesForm: FormGroup;
    lat = 54.56848;
    long = -1.3187;
    disposalData = [];
    zoom = 16;
    _data: any;

    @Input('data') viewData: any = {};
    @Input('isView') isView = false;
    @Output('closeEvents') closeEvents = new EventEmitter<any>();
    constructor(private messageService: MessageService,
        private dialogService: DialogService,
        private service: DisposalSitesService,
        private cdr: ChangeDetectorRef,
        private translateService: TranslateService,
        public ref: DynamicDialogRef, public config: DynamicDialogConfig, ) {
        this.TipSitesForm = new FormGroup({
            Id: new FormControl('', [Validators.required]),
            // companyId: new FormControl('',[ Validators.required ]),
            Name: new FormControl('', [Validators.required]),
            disposalSiteAdds: new FormControl('', [Validators.required]),
            disposalSiteName: new FormControl('', [Validators.required]),
            Postcode: new FormControl(''),
            contactNo: new FormControl(''),
            location: new FormControl(''),
            type: new FormControl(''),
            parent_waste_category: new FormControl(''),
            min_tonnage: new FormControl(''),
            metal_rebate: new FormControl(''),
            cost_per_tonne: new FormControl(''),
            green_recycle: new FormControl(''),
            //_hours:new FormControl(''),
            global_notes: new FormControl(''),
            company_notes: new FormControl('')


        });
    }
    companyDepartmentList() {
        this.service.companyList().subscribe((data) => {
            if (data.status === 200) {
                console.log('company list', data);
                data.data.forEach(element => {
                    if (this.isAcumen == 'true' || this.isAcumen == true) {
                        this.companyList.push({ label: element.companyName, value: element.companyId });
                    } else {
                        if (element.companyId == this.companyID) {
                            this.companyList.push({ label: element.companyName, value: element.companyId });
                            if (this.isAcumen == 'false' || this.isAcumen == false && !this.isUpdate) {
                                // console.log('abcdefghijk');
                                // this.userForm.controls['company'].setValue(element.companyId);
                                // this.companyChange(data);

                            }
                        }
                    }
                });
                if (this.isUpdate) {
                    //  this.TipSitesForm.controls['companyId'].setValue(this._data.companyId);
                }

            } else {

            }


        });

    }
    autoCompleteCallback1(selectedData: any) {
        if (selectedData.response) {
            this.lat = selectedData.data.geometry.location.lat;
            this.long = selectedData.data.geometry.location.lng;
        }
    }
    changePickupMarkerLocation($event: { coords: LatLngLiteral }) {
        this.lat = $event.coords.lat;
        this.long = $event.coords.lng;

    }
    markerDragEnd($event: { coords: LatLngLiteral }) {
        this.lat = $event.coords.lat;
        this.long = $event.coords.lng;
    }
    clickedMarker($event) {

    }
    private setCurrentPosition() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.lat = position.coords.latitude;
                this.long = position.coords.longitude;
                this.zoom = 16;
            });
        }
    }
    setTwoNumberDecimal(event, key) {
        let val = this.TipSitesForm.get(key).value.toString();
        //   if (val.indexOf('.') != -1){
        //       if (val.split(".")[1].length > 2){
        this.TipSitesForm.get(key).setValue(parseFloat(val).toFixed(2));
        //  }
        //  }
    }
    ngOnInit() {
        this.companyID = localStorage.getItem('companyID');
        this.isAcumen = localStorage.getItem('isAcumen');
        this.loadData();

        this.companyDepartmentList();
        this.loadListAPI();
        //this.searchControl = new FormControl();
        //      this.mapsAPILoader.load().then(() => {
        //        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,{ types:['address']});
        //        autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
        //        autocomplete.addListener('place_changed', () => {
        //            this.ngZone.run(() => {
        //                const place = autocomplete.getPlace();

        //                if (place.geometry === undefined || place.geometry === null) {
        //                    return;
        //                }
        //                this.lat = place.geometry.location.lat();
        //                this.long = place.geometry.location.lng();
        //                this.zoom = 16;
        //            });
        //        });
        //    });
    }

    loadListAPI() {
        this.service.parentWasteTypes({}).subscribe((response) => {
            console.log('response parentWasteTypesList', response);
            if (response.length > 0) {
                response.forEach(element => {
                    let data;
                    data = { label: element.ewc_code + ' - ' + element.code_description, value: element.ewc_code };
                    this.parentWasteTypesList.push(data);
                    if (this.isUpdate) {
                        const disposal = [];
                        if (this._data.disposal_type_details) {
                            if (this._data.disposal_type_details.parent_waste_category.length > 0) {
                                this.disposalData = this._data.disposal_type_details.parent_waste_category;
                                this._data.disposal_type_details.parent_waste_category.forEach(element => {
                                    disposal.push(element.ewc_code);
                                });
                            }
                        }
                        this.TipSitesForm.controls['parent_waste_category'].setValue(disposal);
                    }

                });
                console.log('response parentWasteTypesList', this.parentWasteTypesList);

            }
        });
        this.service.typeList().subscribe((data) => {
            console.log('type list', data);
            data.forEach(element => {
                this.typeList.push({ label: element.name, value: element._id });
            });
            if (this.isUpdate) {
                this.TipSitesForm.controls['type'].setValue(this._data.type);
            }


        });
    }


    updateDisposal(event: any) {
        let value: any = '';
        const disposal = [];
        this.TipSitesForm.value.location = { lat: this.lat, long: this.long };
        value = this.TipSitesForm.value;
        console.log("value",value);
        
        value.companyId = this.data.companyId;
        if (value.type == '5e54d71d50b3a90c698c8393') {
            if (value.parent_waste_category.length > 0) {
                value.parent_waste_category.forEach(element => {
                    let data;
                    if (this.disposalData.length > 0) {
                        var exists = this.disposalData.find(({ ewc_code }) =>
                            element === ewc_code);
                        if (exists) {
                            const subArr = [];
                            let val;
                            if (exists.sub_waste_category.length > 0) {
                                exists.sub_waste_category.forEach(e2 => {
                                    const subSubArr = [];
                                    if (e2.sub_sub_waste_category.length > 0) {
                                        e2.sub_sub_waste_category.forEach(e3 => {
                                            subSubArr.push(e3.ewc_code);
                                        });

                                    }
                                    val = { ewc_code: e2.ewc_code, sub_sub_waste_category: subSubArr };
                                    subArr.push(val);
                                });
                            }
                            data = { ewc_code: exists.ewc_code, sub_waste_category: subArr }
                            disposal.push(data);

                        } else {

                            data = { ewc_code: element, sub_waste_category: [] }
                            disposal.push(data);
                        }
                    } else {

                        data = { ewc_code: element, sub_waste_category: [] }
                        disposal.push(data);
                    }
                });


            }

            value.disposal_type_details = {
                parent_waste_category: disposal,
                price: this._data.disposal_type_details.price,
                min_tonnage: value.min_tonnage,
                metal_rebate: value.metal_rebate,
                cost_per_tonne: value.cost_per_tonne,
                green_recycle: value.green_recycle,
                //opening_hours: value.opening_hours,
                global_notes: value.global_notes,
                company_notes: value.company_notes
            }

        }
        delete value.parent_waste_category;
        delete value.min_tonnage;
        delete value.metal_rebate;
        delete value.cost_per_tonne;
        delete value.green_recycle;
        //delete value.opening_hours;
        delete value.global_notes;
        delete value.company_notes;
        value._id = this._data._id;
        this.service.updateDisposalLocation(value).subscribe((data: any) => {
            if (data.status === 200) {
                console.log('value id', value);
                // this.dialogService.closeAll();
                if (this.isEdit == true) {
                    this.service.getDisposalDetails(value.Id).subscribe((response) => {
                        this.viewData = response.data;
                        this.closeEvent(event);

                    });
                } else {
                    this.closeEvent(event);

                }
                this.messageService.add({
                    severity: 'success',
                    summary: 'Update Disposal site',
                    detail: 'Tip site details updated successfully'
                });
            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Disposal site',
                    detail: data.error.message.errmsg
                });
            }
            else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Disposal site',
                    // detail: 'Failed to quotes the Tip site details'
                    detail: 'Unable to update Tip site.Please try again.'
                });
            }

        });
    }
    createDisposal(e: any) {
        e.preventDefault();
        let quotesData: any = '';
        const disposal = [];
        this.TipSitesForm.value.location = { lat: this.lat, long: this.long };
        quotesData = this.TipSitesForm.value;
        if (this.TipSitesForm.value.type == '5e54d71d50b3a90c698c8393') {
            if (quotesData.parent_waste_category.length > 0) {
                quotesData.parent_waste_category.forEach(element => {
                    let data;
                    data = { ewc_code: element, sub_waste_category: [] };
                    disposal.push(data);
                });
            }
            quotesData.disposal_type_details = {
                parent_waste_category: disposal,
                min_tonnage: quotesData.min_tonnage,
                metal_rebate: quotesData.metal_rebate,
                cost_per_tonne: quotesData.cost_per_tonne,
                green_recycle: quotesData.green_recycle,
                // opening_hours: quotesData.opening_hours,
                global_notes: quotesData.global_notes,
                company_notes: quotesData.company_notes
            }

        }
        delete quotesData.parent_waste_category;
        delete quotesData.min_tonnage;
        delete quotesData.metal_rebate;
        delete quotesData.cost_per_tonne;
        delete quotesData.green_recycle;
        //delete quotesData.opening_hours;
        delete quotesData.global_notes;
        delete quotesData.company_notes;
        console.log('data', quotesData);

        this.service.createDisposalLocation(quotesData).subscribe(
            (data: any) => {
                if (data.status === 200) {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Create Disposal site',
                        detail: 'New Disposal site created successfully'
                    });
                    this.closeEvent(event);
                } else if (data.status === 500) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Create Disposal site',
                        detail: data.error.message.errmsg
                    });
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Create Disposal site',
                        //  detail: 'Failed to create the Tip sites'
                        detail: 'Unable to add Disposal site.Please try again.'
                    });
                }

            },
            (err) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Create Disposal site',
                    // detail: 'Failed to create the Tip sites'
                    detail: 'Unable to add Tip site.Please try again.'
                });
                //  this.dialogService.closeAll();
            }
        );
    }


    editEvent(event) {
        this.isEdit = true;
        this.isUpdate = true;
        this.isView = false;
        this.loadData();
    }

    loadData() {

        if (this.viewData && (Object.keys(this.viewData).length !== 0)) {
            console.log(' true vehicleData', this.viewData);
            this._data = this.viewData;
            this.data = this.viewData;
            this.isUpdate = true;
            if (this.isUpdate) {
                this.lat = this.data.location ? this.data.location.lat : 54.56848;
                this.long = this.data.location ? this.data.location.long : -1.3187;
                this.TipSitesForm.controls['Id'].setValue(this.data.Id);
                this.TipSitesForm.controls['Name'].setValue(this.data.Name);
                // this.TipSitesForm.controls['companyId'].setValue(this.data.companyId);
                this.TipSitesForm.controls['disposalSiteAdds'].setValue(this.data.disposalSiteAdds);
                this.TipSitesForm.controls['disposalSiteName'].setValue(this.data.disposalSiteName);
                this.TipSitesForm.controls['contactNo'].setValue(this.data.contactNo);
                this.TipSitesForm.controls['Postcode'].setValue(this.data.Postcode);
                this.TipSitesForm.controls['location'].setValue(this.data.location);
                this.TipSitesForm.controls['type'].setValue(this.data.type);
                if (this.data.disposal_type_details) {
                    this.TipSitesForm.controls['parent_waste_category'].setValue(this.data.disposal_type_details.parent_waste_category);
                    this.TipSitesForm.controls['min_tonnage'].setValue(this.data.disposal_type_details.min_tonnage);
                    this.TipSitesForm.controls['metal_rebate'].setValue(this.data.disposal_type_details.metal_rebate);
                    this.TipSitesForm.controls['cost_per_tonne'].setValue(this.data.disposal_type_details.cost_per_tonne);
                    this.TipSitesForm.controls['green_recycle'].setValue(this.data.disposal_type_details.green_recycle);
                    // this.TipSitesForm.controls['opening_hours'].setValue(data.disposal_type_details.opening_hours);
                    this.TipSitesForm.controls['global_notes'].setValue(this.data.disposal_type_details.global_notes);
                    this.TipSitesForm.controls['company_notes'].setValue(this.data.disposal_type_details.company_notes);


                }
                if (this.isView) {

                }

            }
        }
    }
    closeEvent(event) {
        if (this.isEdit == false) {
            //     var element = document.getElementById("styleSelector");
            //   element.classList.remove("animate-block");
            //     element.classList.add("slide-out");
            this.slide = false;
            this.cdr.detectChanges();
            setTimeout(e => {
                this.closeEvents.emit(true);
            }, 1000)

        } else {
            this.isEdit = false;
            this.isView = true;
            this.isUpdate = false;
            this.loadData();
        }
    }

    blockSpecialChar(e) {
        console.log('key press: ', e);
        var k;
        document.all ? k = e.keyCode : k = e.which;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

}
