import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CustomersService } from '../customers.service';
 import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-edit-customers',
  templateUrl: './add-edit-customers.component.html',
  styleUrls: ['./add-edit-customers.component.scss']
})
export class AddEditCustomersComponent implements OnInit {
  customerForm:FormGroup;
  isUpdate = false;
  slide = true; 
  isEdit = false;
  isAcumen:any;
  companyID:any;
  companyList=[{label:'Please Select',value:''}];
  data: any = [];
  @Input('data') viewData: any = {};
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private service: CustomersService,
    private cdr:ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
  ) {
    this.customerForm  = new FormGroup({
      companyId: new FormControl('',[ Validators.required ]),
      companyName: new FormControl('',[ Validators.required ]),
  });
   }

  ngAfterViewInit(){
   
    console.log('ngafterviewinit',this.customerForm);
}
editEvent(event){
  this.isEdit = true;
  this.isUpdate = true;
  this.isView = false;
  this.loadData();
}


createCustomer(e) {
    let value: any = '';
    console.log('Value',this.customerForm.value);
    value = this.customerForm.value;
  //  value.username = this.studentForm.controls['student_number'].value;
  //  value.college_id = localStorage.getItem('college_id');
   //  if (value.email === value.cemail) {
    
        this.service.createCompany(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Create Customer',
                    detail: 'New Customer created successfully'
                });
                // this.ref.close(this);
               // this.closeEvents.emit(true);
               this.closeEvent(true);

            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Create Customer',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Create Customer',
                    detail: 'Unable to add Customer.Please try again.'
                  //  detail: 'Failed to create the User'
                });
            }
        },
      //  });
      (err) => {
        console.log('Error',err);
        this.messageService.add({
            severity: 'error',
            summary: 'Create Customer',
           // detail: 'Failed to create the Waste types'
           detail: 'Unable to add Customer.Please try again.'
        });
       // this.dialogService.closeAll();
    }
);
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
    
}
updateCustomer(event: any) {
   // console.log('update student', value.email, value.cemail);
    let value: any = '';
    value = this.customerForm.value;
    value._id=this.data._id;
    console.log('Update value',this.customerForm);
  
        this.service.updateCompany(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Update Customer',
                    detail: 'Customer details updated successfully'
                });
                // this.ref.close(this);
              if(this.isEdit == true){
                this.service.companyDetails(this.data.companyId).subscribe((response) => {
                    console.log('company  details', response);
                    this.viewData = response.data;
                    this.closeEvent(true);
                });
              }  else{
                 // this.closeEvents.emit(true);
                 this.closeEvent(true);

              }
               

            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Customer',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Customer',
                    detail: 'Unable to update Customer.Please try again.'
                  //  detail: 'Failed to update the user details'
                });
            }
          
        });
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
    
}
ngOnInit() {
  console.log('this.config',this.config);
//   var element = document.getElementById("styleSelector");
//                 element.classList.remove("slide-out");
//                 element.classList.add("animate-block");
  this.companyID = localStorage.getItem('companyID');
  this.isAcumen = localStorage.getItem('isAcumen');
  this.loadData();

}
loadData(){
  
    if(this.viewData && (Object.keys(this.viewData).length !== 0)) {
        console.log(' true vehicleData',this.viewData);
        this.data = this.viewData;
        if(this.isView){
          this.viewData.active = this.viewData.active == true?'Yes':'No';
          console.log(' true vehicleData',this.viewData);

        }
        this.isUpdate = true;
      if (this.isUpdate){
        this.customerForm.controls['companyId'].setValue(this.data.companyId);
        this.customerForm.controls['companyName'].setValue(this.data.companyName);

     
      }
    
  }
}
closeEvent(event){
   if (this.isEdit == false) {
//     var element = document.getElementById("styleSelector");
//   element.classList.remove("animate-block");
//     element.classList.add("slide-out");
this.slide = false;
this.cdr.detectChanges();
    setTimeout(e =>{
      this.closeEvents.emit(true);
   },1000)

   } else{
    this.isEdit = false;
    this.isView = true;
    this.isUpdate = false;
    this.loadData();
   }
}

}
