<div   class="" >
  
    <div id="styleSelector"  class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
<div class="container h-100">
        <div class="row align-items-center">
          <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
              <div class='row add-edit-header'  *ngIf="!isView">  
                  <div class="ui-g-10">
                      <h4 *ngIf='!isUpdate'>Create Vehicle Type</h4>
                      <h4 *ngIf='isUpdate'>Edit Vehicle Type</h4>
                  </div> 
                  <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Vehilce type" tooltipPosition="top">
                      <i class="fa fa-window-close fa-2x "></i>
                   </div>    
                       </div> 
                       <div class="">                    
            <form [formGroup]="vehicleTypeForm" *ngIf="!isView">
           
              
             
              <div class='row form-group'>
                <div class='ui-g-12 mt-3'>
                    <label for="firstname">Name</label>
                  <input class='ui-g-12' id="firstname" class="form-control"  type="text" size="30" formControlName='name' pInputText>
                </div>
              </div>   
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                        <label for="lastname">PPM</label>
                  <input class='ui-g-12' id="lastname" type="text" class="form-control"size="30" formControlName='ppm' pInputText>
                </div>
              </div>
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                        <label for="lastname">CO2 Emission rate (kg/m)</label>
                  <input class='ui-g-12' id="lastname" type="number" class="form-control"size="30" formControlName='co2' pInputText>
                </div>
              </div>      
               
              <!-- <div class='col-12' style='color:red;font-size: 12px' *ngIf="userForm.get(''">
                  Employee ID
                </div> -->
               
            
               
                  
              
            
              
           
              <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                <div class='ui-g-12'>
                  <p-button label="Create" [disabled]='!vehicleTypeForm.valid' (onClick)="createVehicleType($event)"></p-button>
                
                </div>
              </div>
              <div class='row button-cls' *ngIf='isUpdate'>
                <div class='ui-g-12'>
                  <p-button label="Update" [disabled]='!vehicleTypeForm.valid' (onClick)="updateVehicleType($event)"></p-button>
                
                </div>
              </div>
            
            </form>
            <div *ngIf="isView">
                <div class='row details-header'>  
                    <div class="ui-g-10">
                        <h4>View Vehicle Type Details</h4>
                    </div> 
                    <div class="ui-g-1" (click)="editEvent($event)" pTooltip="Click to edit a Vehilce type" tooltipPosition="top">
                        <i class="fa fa-edit fa-2x "></i>
                    </div>    
                    <div class="ui-g-1" (click)="closeEvent($event)" pTooltip="Click to close a Vehilce type" tooltipPosition="top">
                        <i class="fa fa-window-close fa-2x "></i>
                        </div>    
            </div>  
          <div class="details-content">
              <div class='row'>
                <div class="ui-g-6">
                  <span for="userName">Name</span>
                </div>
                <div class="ui-g-6">
                  <span for="userName">{{viewData.name}}</span>
                </div>
              </div>
              <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">PPM</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.ppm}}</span>
                  </div>
                </div>
                <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">CO2 Emission rate (kg/m)</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.co2}}</span>
                  </div>
                </div>
                

                  
                    
                      
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>
      </div>