import { Component, OnInit ,ChangeDetectorRef,Input,Output,EventEmitter} from '@angular/core';
import { DisposalSitesService } from '../disposal-sites.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup ,FormControl,Validators} from '@angular/forms';
@Component({
  selector: 'app-add-edit-price',
  templateUrl: './add-edit-price.component.html',
  styleUrls: ['./add-edit-price.component.scss']
})
export class AddEditPriceComponent implements OnInit {
  slide = true;
  title:any;
  subjectList = [];
  subCategoryList = [];
  isSubjectVisible = false;
  wasteTypeList = [{label:'Please Select',value:''}];;
  parentWasteType :any;
  companyList=[{label:'Please Select',value:''}];
  parentWasteTypeList = [{label:'Please Select',value:''}];
  CompanyForm:FormGroup;
  isView = false;
  _data: any = [];
  closepopup = false;
  priceForm:FormGroup
  @Input('id') id:any;
  @Input('category') category: any;
  @Input('data') disposalData: any = {};
  @Input('viewData') viewData: any = {};
  @Input('isUpdate') isUpdate = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(private service:DisposalSitesService,private translateService: TranslateService,private cdr:ChangeDetectorRef,public dialogService: DialogService,public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,private confirmDialogService:ConfirmationService,private router:Router) { 
      this.priceForm = new FormGroup({
        waste_type:new FormControl('', [ Validators.required ]),
        min_tonnage:new FormControl(''),
        metal_tonnage:new FormControl(''),
        cost_per_tonne:new FormControl(''),
        metal_rebate:new FormControl('')

    });
    }

    loadPriceData(){
      console.log('disposal data',this.disposalData)
    if(this.disposalData)  {
      let flag = false;
      this.service.wasteTypeList().subscribe((data) => {
          data.data.forEach(element => {
              let data;
            if(element.active == 1)  {
              if(this.disposalData.disposal_type_details){
                  if(this.disposalData.disposal_type_details.price.length > 0){
                      var exists = this.disposalData.disposal_type_details.price.find(({waste_type}) => 
                          element.wasteTypeId === waste_type);
                      if(!exists){
                          console.log('element',element);
                          data = {label:element.wasteType,value:element.wasteTypeId};
                          this.wasteTypeList.push(data);
                      }
                  }else{
                      data = {label:element.wasteType,value:element.wasteTypeId};
                      this.wasteTypeList.push(data); 
                  }
              }else{
                  data = {label:element.wasteType,value:element.wasteTypeId};
                  this.wasteTypeList.push(data);
              }
              if (this.isUpdate){
                  if(this._data.waste_type == element.wasteTypeId){
                      flag = true;
                  }
              }
          }
          });
          if (this.isUpdate){
              let data ;
              data = {label:this._data.waste_type_name,value:this._data.waste_type};
             if (flag == true){
              this.wasteTypeList.push(data);
              this.priceForm.controls['waste_type'].setValue(this._data.waste_type);

             }

          }

         
      });
  }
  }
  setTwoNumberDecimal(event,key){
      let val = this.priceForm.get(key).value.toString();
    //   if (val.indexOf('.') != -1){
    //       if (val.split(".")[1].length > 2){
            this.priceForm.get(key).setValue(parseFloat(val).toFixed(2));
        //  }
    //  }
  }
  fetchCategory(){
      this.service.getDisposalDetails(this.id).subscribe((data: any) => {
           this.disposalData = data.data;
       });
  }
  updateDisposal(event: any,value) {
      console.log('value',value);
      this.service.updateDisposalLocation(value).subscribe((data: any) => {
          if (data.status === 200) {
              // this.messageService.add({
              //     severity: 'Success',
              //    // summary: 'Updated price successfully',
              //     detail: data.message
              // });
                 this.cancel();
             
          } else if (data.status === 500) {
              this.messageService.add({
                  severity: 'error',
                 // summary: 'Update Disposal site',
                  detail: data.error.message.errmsg
              });
          }
          else {
              this.messageService.add({
                  severity: 'error',
                //  summary: 'Update Disposal site',
                 // detail: 'Failed to quotes the Tip site details'
                 detail: 'Unable to update Tip site.Please try again.'
              });
          }

      });
  }
  updateSubCategory(event){
      const  subcatArr = [];
      const mainCategoryArr = [];
      console.log('subcategorylist',this.subCategoryList);
      this.subCategoryList.forEach(element =>{
          const subsubcatArr = [];
         if(element.checked == true){
           element.waste_sub_sub_details.forEach(ele => {
             if(ele.checked == true){
              subsubcatArr.push(ele.ewc_code);
             }
           });
           let data;
                data = {ewc_code:element.ewc_code,sub_sub_waste_category:subsubcatArr}
                subcatArr.push(data);
          }
      });
      if(this.disposalData.disposal_type_details.parent_waste_category.length > 0){
      this.disposalData.disposal_type_details.parent_waste_category.forEach(el => {
              let data;
              if(el.ewc_code == this.parentWasteType){
                  data = {ewc_code:this.parentWasteType,sub_waste_category:subcatArr}
                  mainCategoryArr.push(data);
              }else{
                  const subArr = [];
                  let val;
                //  console.log('data',el,el.sub_waste_category);
                 if(el.sub_waste_category.length > 0){
                  el.sub_waste_category.forEach(e2 =>{
                      const subSubArr = [];
                      if(e2.sub_sub_waste_category.length > 0){
                          e2.sub_sub_waste_category.forEach(e3 => {
                              subSubArr.push(e3.ewc_code);
                          });
                        
                      }
                      val = {ewc_code:e2.ewc_code,sub_sub_waste_category:subSubArr};
                      subArr.push(val);
                  });
                 }
                  data = {ewc_code:el.ewc_code,sub_waste_category:subArr}
                  mainCategoryArr.push(data);
              }
          });
      }  
      console.log('main data',mainCategoryArr);
      this.disposalData.disposal_type_details.parent_waste_category = mainCategoryArr;
      this.closepopup = true;
      this.updateDisposal(event,this.disposalData);

  }
  updatePrice(event){
  
          if (this.disposalData.disposal_type_details.price.length > 0){

          this.disposalData.disposal_type_details.price.forEach(element => {

             if (element.waste_type === this._data.waste_type){
              
                  element.waste_type = this.priceForm.value.waste_type;
                  element.min_tonnage = this.priceForm.value.min_tonnage;
                  element.metal_tonnage = this.priceForm.value.metal_tonnage;
                  element.cost_per_tonne = this.priceForm.value.cost_per_tonne;
                  element.metal_rebate = this.priceForm.value.metal_rebate;
             
            }
          });
       
      } 
      console.log('disposal data',this.disposalData);
      this.updateDisposal(event,this.disposalData);

  }
  createPrice(event){
          const priceArr = [];
          let data;
          if (this.disposalData.disposal_type_details){

              if (this.disposalData.disposal_type_details.price.length > 0){
                  console.log('loop second');

              this.disposalData.disposal_type_details.price.forEach(element => {
                 if (element){
                  data = {
                      waste_type:element.waste_type,min_tonnage:element.min_tonnage,metal_tonnage:element.metal_tonnage,
                      cost_per_tonne:element.cost_per_tonne,metal_rebate:element.metal_rebate
                  };
                  priceArr.push(data);
              }
              });
              data = this.priceForm.value;
              priceArr.push(data);
            } else {
              console.log('loop third');

              data = this.priceForm.value;
              priceArr.push(data);
          }

          } 
          this.disposalData.disposal_type_details.price = priceArr;
console.log('price',this.disposalData);
        this.updateDisposal(event,this.disposalData);
      }
  
  
  
  
  ngOnInit() {
    this.loadFormData();

      this.loadPriceData();

  }
  loadFormData(){
    if(this.viewData && (Object.keys(this.viewData).length !== 0)) {
      console.log(' true vehicleData',this.viewData);
      this._data = this.viewData;
    this.priceForm.controls['min_tonnage'].setValue( this._data.min_tonnage);
    if ( this._data.metal_tonnage){
    this.priceForm.controls['metal_tonnage'].setValue( this._data.metal_tonnage);
    }
    this.priceForm.controls['cost_per_tonne'].setValue( this._data.cost_per_tonne);
    this.priceForm.controls['metal_rebate'].setValue( this._data.metal_rebate);
  }
  }
  loadCategory(){
      console.log('load category');
      this.service.subCategoryList({ewc_code:this.parentWasteType}).subscribe((response: any) => {
      if(response && response.length > 0){
          response.forEach(element => {
              element.code_description = element.ewc_code+ ' - '+element.code_description;
              element.checked = false;
              element.waste_sub_sub_details.forEach(ele => {
                  ele.code_description = ele.ewc_code+ ' - '+ele.code_description;
                  ele.checked = false;
              });
          });
         
          this.subCategoryList = response;
          const subCat = [];
          const subSubCat = [];
          if(this.isUpdate){
              this.disposalData.disposal_type_details.parent_waste_category.forEach(element => {
                  if(element.ewc_code == this.parentWasteType){
                      if(element.sub_waste_category.length > 0){
                          element.sub_waste_category.forEach(el => {
                              subCat.push(el);
                              if(el.sub_sub_waste_category.length > 0){
                                  el.sub_sub_waste_category.forEach(e => {
                                      subSubCat.push(e);
                                  });
                              }
                          });
                      }

                  }
 
              });
              this.subCategoryList.forEach(ele => {
                 if(subCat.length > 0){
                     subCat.forEach(e =>{
                      if(ele.ewc_code == e.ewc_code){
                          ele.checked = true;
                          ele.waste_sub_sub_details.forEach(e1 => {
                              subSubCat.forEach(e2 =>{
                                  if(e1.ewc_code == e2.ewc_code){
                                      e1.checked = true;
                                  }
                              });    
                          });
                      }
                     });
                 }
              });

          }
      }
      });
  }
  onChange(event,item){
    this.subCategoryList.forEach(element =>{
        if(element.checked == false){
            element.waste_sub_sub_details.forEach(ele => {
                ele.checked = false;
            });
       }else{
          if(element.ewc_code == item.ewc_code){
              element.waste_sub_sub_details.forEach(ele => {
                  ele.checked = true;
              });
          }

        }
    })
  }
  ngAfterViewInit(){
  }
  cancel(){
  this.closeEvents.emit(true);
  }

  closeEvent(event){

    this.slide = false;
    this.cdr.detectChanges();
       setTimeout(e =>{
        this.closeEvents.emit(true);
      },1000)
      }

}
