import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomersRoutingModule } from './customers.routing.module';
import { CustomersComponent } from './customers.component';
import {SharedModule} from '../../theme/shared/shared.module';
import {NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {TooltipModule} from 'primeng/tooltip';
import {LinkDisposalCategoryComponent} from './link-disposal-category/link-disposal-category.component';
import {AddEditCustomersComponent} from './add-edit-customers/add-edit-customers.component'
@NgModule({
  declarations: [CustomersComponent,AddEditCustomersComponent,LinkDisposalCategoryComponent],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    TooltipModule,
    MessagesModule,
    NgbCarouselModule,
    DropdownModule,
    CalendarModule
  ],
  exports:[AddEditCustomersComponent,LinkDisposalCategoryComponent]
})
export class CustomersModule { }
