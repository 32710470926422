import { Component, OnInit ,ChangeDetectorRef, ViewChild} from '@angular/core';
import { WasteCategoryService } from './waste-category.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table/table';
@Component({
  selector: 'app-waste-category',
  templateUrl: './waste-category.component.html',
  styleUrls: ['./waste-category.component.scss']
})
export class WasteCategoryComponent implements OnInit {
  @ViewChild('dt', { static: false }) table:Table;
  pageWasteIndex;
  isUpdate = false;
  isView = false;
  isAcumen:any;
  showAlertText = false;
  viewData:any;
  companyID:any;
  wasteCategoryList = [];
  selectedCategory:any;
  showAdd = false;
  cols = [
    // {
    //     header: 'Big Change ID',
    //     field: 'wasteTypeId'
    // },
    {
        header: 'Waste Category',
        field: 'wasteType'
    },
];
  vehicleTypeData:any;
  adminUserList = [];
  showLoader = true;
  selectedVehicle:any;
  vehicleTypeList = [];
  linkData:any;
  showLink = false;
  searchArr = [];
  constructor(private service:WasteCategoryService,private translateService: TranslateService,private cdr:ChangeDetectorRef,public dialogService: DialogService,public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,private confirmDialogService:ConfirmationService,private router:Router) { }

    ngOnInit() {
      localStorage.removeItem('jobID');
      this.companyID = localStorage.getItem('companyID');
      this.isAcumen = localStorage.getItem('isAcumen');
      this.pageWasteIndex = localStorage.getItem('pageWasteIndex');
      console.log('index',this.pageWasteIndex);
    //  this.setPage(2);
     if(this.pageWasteIndex){
      localStorage.removeItem('pageWasteIndex');
     }
      this.fetchWasteCategoryList();
      if (this.cols.length > 0){
        this.cols.forEach(element => {
          this.searchArr.push(element.field);
        });
      }
    }
    callList(event){
      console.log('call list');
      this.showAdd = false;
      this.isView = false;
      this.vehicleTypeData = {};
      this.cdr.detectChanges();
        this.fetchWasteCategoryList();
    }
    createWasteCategory(event){
      console.log('create staff called');
     
      if(this.showAdd == true){
        this.showAdd = false;
        this.isView = false;
        this.vehicleTypeData = {};
       setTimeout(e =>{
         this.showAdd = true;
       },1000) 
      }else if(this.showLink == true){
        this.showLink = false;
        this.linkData = {};
        setTimeout(e =>{
          this.showAdd = true;
        },1000) 
      }else if(this.showAlertText == true){
        this.showAlertText = false;
        this.viewData = {};
        setTimeout(e =>{
          this.showAdd = true;
        },1000) 
      }
      else{
        this.showAdd = true;
      }      this.cdr.detectChanges();
    }
    onPageEvent(event){
      console.log('event',event);
      this.pageWasteIndex = (event.first/event.rows + 1);
    }
    setPage(n: number){
      this.table.reset();
      let paging = {
        first: ((n - 1) * this.table.rows),
        rows: this.table.rows
    };
    console.log('table values',paging);
    this.table.first = paging.first;
    this.table.rows = paging.rows;
  }
    editWasteCategory(event,data){
    
      this.service.getWasteTypeDetails(data.wasteTypeId).subscribe((response) => {
          console.log('user  details', response);
          this.vehicleTypeData = response.data;
       this.showAdd = true;
       console.log('user  details', this.vehicleTypeData);
  
       this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });
  
      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchWasteCategoryList();
      //     }
      // });
  });
    }
    listCategory(event,data){
      console.log('data',this.pageWasteIndex)
      if (this.pageWasteIndex != 1){
        let pageNum = this.pageWasteIndex;
        setTimeout(e =>{
          localStorage.setItem('pageWasteIndex',pageNum);
        },1000)
        

      }
      this.router.navigate(['wastecategory/listcategory/'+ data.wasteTypeId])


  }
  listCustomer(event,data){
    this.showAdd = false;
    this.showLink = false;
    this.service.getWasteTypeDetails(data.wasteTypeId).subscribe((response) => {
      this.linkData = response.data;
    this.showLink = true;
    this.cdr.detectChanges();
    });
  }
    alertText(event,data){
      this.showAdd = false;
      this.showLink = false;
      this.showAlertText = false;
      this.service.getWasteTypeDetails(data.wasteTypeId).subscribe((response) => {
        console.log('user  details', response.data);
      this.viewData = response.data;
      this.showAlertText = true;
      this.cdr.detectChanges();
      });
    }
    closeView(event){
      this.showAlertText = false;
    }
    change(event,car){
      console.log('data',car);
       // let value:any = {};
        this.service.getWasteTypeDetails(car.wasteTypeId).subscribe((response) => {
            let value = response.data;
            // value.alert_text = data.alert_text? data.alert_text:'';
            // value.wasteTypeId = data.wasteTypeId;
            // value.linked_waste_category = data.linked_waste_category;
            // value.default_min_tonnage = data.default_min_tonnage?data.default_min_tonnage:'';
            value.metal_slider = car.metal_slider?car.metal_slider:false;
            value.active = car.active == true ? 1: 0;
            // value._id = data._id;
            console.log('value', value);

            this.update(value);
 
        });
    }
    update(value){
      this.service.updateWasteType(value).subscribe((data: any) => {
            
        });
    }
    
    viewWasteCategory(event,data){
     
      this.service.getWasteTypeDetails(data.wasteTypeId).subscribe((response) => {
        console.log('user  details', response);
        this.vehicleTypeData = response.data;
        this.isView = true;
     this.showAdd = true;
     console.log('user  details', this.vehicleTypeData);
  
     this.cdr.detectChanges();
   
    
  });
    }
    deleteWasteCategory(id){
      this.confirmDialogService.confirm({
        message: 'Are you sure that you want to delete the Waste category?',
        accept: () => {
            this.service.deleteType(id).subscribe((data: any) => {
                if (data.status === 200) {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Delete Waste category',
                        detail: 'Waste category details deleted successfully'
                   });
                 
                }else {
           
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Delete Waste category',
                        detail: data.error.message
                    });
                }
                this.fetchWasteCategoryList();
            },
            (err) => {
                console.log('error',err);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Delete Waste category',
                    detail: 'Failed to delete the Waste category'
                });
                this.fetchWasteCategoryList();
            }
        );
          //  });
        }
    });
    }
    fetchWasteCategoryList() {
      const staffArr = [];
      this.service.wasteTypeList().subscribe((response) => {
  
         
          this.wasteCategoryList = response.data;
          if (this.wasteCategoryList.length > 0){
              this.wasteCategoryList.forEach(element =>{
                 element.metal_slider = element.metal_slider?element.metal_slider:false;
                 element.active = element.active == 1?true:false;
              });
          }        
          console.log('waste category  details', this.wasteCategoryList);

          this.showLoader=false;
          if(this.pageWasteIndex && this.pageWasteIndex > 1)  {
            console.log('page index',this.pageWasteIndex);
            this.setPage(this.pageWasteIndex);
      
          }
      });
  }


  closeAll(event){
    this.showLink = false;
    this.linkData = {};
    this.cdr.detectChanges();
    this.fetchWasteCategoryList();
  }

}
