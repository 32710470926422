import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { CustomerDivisionsService } from './customer-divisions.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-customer-divisions',
  templateUrl: './customer-divisions.component.html',
  styleUrls: ['./customer-divisions.component.scss']
})
export class CustomerDivisionsComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen:any;
  companyID:any;
  showAdd = false;
  customerDivisionList=[];
  divisionData:any;
  selectedDivision:any;
  cols = [
  
];
  adminUserList = [];
  searchArr = [];
  showLoader = true;
  selectedAdmin:any;
  constructor(private service:CustomerDivisionsService,private translateService: TranslateService,private cdr:ChangeDetectorRef,public dialogService: DialogService,public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,private confirmDialogService:ConfirmationService) { }

    ngOnInit() {
      this.clearIndex();
      this.companyID = localStorage.getItem('companyID');
      this.isAcumen = localStorage.getItem('isAcumen');
  
      this.fetchCustomerDivisionList();
     if (this.isAcumen == true || this.isAcumen == 'true'){
       this.cols = [  
        {
            header: 'Division/Department Name',
            field: 'departmentName'
        }, {
          header: 'Company Name',
          field: 'companyName'
      }
     ];
    }else{
      this.cols = [  
        {
            header: 'Division/Department Name',
            field: 'departmentName'
        }
      ];
    }
    if (this.cols.length > 0){
      this.cols.forEach(element => {
        this.searchArr.push(element.field);
      });
    }
  }
    callList(event){
      console.log('call list');
      this.showAdd = false;
      this.isView = false;
      this.divisionData = {};
      this.cdr.detectChanges();
        this.fetchCustomerDivisionList();
    }
    clearIndex(){
      localStorage.removeItem('jobID');
      localStorage.removeItem('pageJobIndex'); 
      localStorage.removeItem('pageDisposalIndex'); 
      localStorage.removeItem('pageWasteIndex'); 
      localStorage.removeItem('pageReportIndex'); 
    }
    createDivision(event){
      console.log('create staff called');
      if(this.showAdd == true){
        this.showAdd = false;
        this.isView = false;
        this.divisionData = {};
       setTimeout(e =>{
         this.showAdd = true;
       },1000) 
      }else{
        this.showAdd = true;
      }
      this.cdr.detectChanges();
    }
    editDivision(event,data){
    
      this.service.getDepartmentDetails(data.deptId).subscribe((response) => {
          console.log('user  details', response);
          this.divisionData = response.data;
       this.showAdd = true;
       console.log('user  details', this.divisionData);
  
       this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });
  
      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchCustomerDivisionList();
      //     }
      // });
  });
    }
    viewDivision(event,data){
     
      this.service.getDepartmentDetails(data.deptId).subscribe((response) => {
        console.log('user  details', response);
        this.divisionData = response.data;
        this.isView = true;
     this.showAdd = true;
     console.log('user  details', this.divisionData);
  
     this.cdr.detectChanges();
   
    
  });
    }
    deleteDivision(id){
      this.confirmDialogService.confirm({
        message: 'Are you sure that you want to delete the Customer Division?',
        accept: () => {
            this.service.deleteType(id).subscribe((data: any) => {
                if (data.status === 200) {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Delete Customer Division',
                        detail: 'Customer Division details deleted successfully'
                   });
                 
                }else {
           
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Delete Customer Division',
                        detail: data.error.message
                    });
                }
                this.fetchCustomerDivisionList();
            },
            (err) => {
                console.log('error',err);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Delete Customer Division',
                    detail: 'Failed to delete the Customer Division'
                });
                this.fetchCustomerDivisionList();
            }
        );
          //  });
        }
    });
    }
    fetchCustomerDivisionList() {
      const staffArr = [];
      this.service.departmentList().subscribe((response) => {
          console.log('staff  details', response);
  
          // response.data.forEach((ele) => {
          //     ele.isActive = ele.active ? 'Yes' : 'No';
          // });
          if (response.data.length > 0){
            response.data.forEach(element => {
              console.log('element',element,this.companyID);
              if (this.isAcumen == 'true' || this.isAcumen == true){
                staffArr.push(element);
              } else{
                if(element.companyId == this.companyID){
                  staffArr.push(element);
                }
              }
            });
          }
          this.customerDivisionList = staffArr;
         
          this.showLoader=false;
      });
  }


}
