<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card moduleName="Staff" module="Staff" (addUser)="createStaff($event)" (importStaff)="importStaff($event)"
      (exportStaffs)='exportStaff($event)' cardClass="table-card" blockClass="p-0">


      <p-table #dt [value]="userDetails" [(selection)]="selectedStaff" dataKey="_id" styleClass="ui-table-customers"
        [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="searchArr">
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">

              <b>{{col.header}}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>

            </th>
            <th></th>
            <!--  <th>Delete</th> -->
            <th></th>
            <!-- <th><span (click)='addPrice($event)' class="fa-plusBtn">
                  <i class='fa fa-plus-circle fa-4x'></i>
                </span></th>  -->
          </tr>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
              <input pInputText type="text" class="ui-inputtext"
                (input)="dt.filter($event.target.value, col.field, 'contains')">
            </th>
            <th></th>
            <th></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <!-- [style.background-color]="car['rowColor']" -->
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols">
              {{car[col.field]}}
            </td>
            <td>
              <span>
                <p-checkbox name="active" ngDefaultControl [(ngModel)]="car.active"
                  class="map-checkbox text-allign-btns" (onChange)="change($event,car)" label='Active' binary="false">
                </p-checkbox>
              </span>
            </td>
            <td>
              <span (click)='viewStaff($event,car)' class="mob_icon " pTooltip="Click to view a Staff"
                tooltipPosition="top">
                <i class='fa fa-eye fa-2x'></i>
              </span>
              <span (click)='editStaff($event,car)' class="mob_icon delete_icon" pTooltip="Click to edit a Staff"
                tooltipPosition="top">
                <i class='fa fa-edit fa-2x'></i>
              </span>

              <!-- </td> -->
              <!-- <td>
                  <span (click)='viewAdmin($event,car)'>
                    <i class='fa fa-eye fa-2x'></i>
                  </span>
                </td>
                <td>
                  <span (click)='editAdmin($event,car)'>
                    <i class='fa fa-pencil fa-2x'></i>
                  </span>
                </td>-->
              <!-- <td > -->
              <span (click)='deleteStaff(car._id)' class="mob_icon delete_icon" pTooltip="Click to delete a Staff"
                tooltipPosition="top">
                <i class='fa fa-trash fa-2x'></i>
              </span>
              <!-- <p-button label="Remove" class="btn-background" (onClick)="deleteStaff($event,car)"></p-button> -->

            </td>

          </tr>
        </ng-template>
        <ng-template class="noRecords" *ngIf="userDetails.length==0" pTemplate="emptymessage">
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length+2">
              <div class="my-center-text">
                No Records to display
              </div>
            </td>

          </tr>
        </ng-template>
      </p-table>
    </app-card>
  </div>
  <!-- tabs card end -->
  <div class="loader" *ngIf="showLoader"></div>


</div>
<div *ngIf="showAdd">
  <app-add-edit-staff [isView]="isView" [data]="staffData" (closeEvents)="callList($event)"></app-add-edit-staff>
</div>
<div *ngIf="showimport">
  <app-import-staff [isView]="isView" (closeEvents)="callList($event)"></app-import-staff>
</div>
<div *ngIf="showExport">
  <app-export-staff [isView]="isView" (closeEvents)="callList($event)"></app-export-staff>
</div>