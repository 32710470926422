import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WasteCategoryService } from '../waste-category.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-edit-waste-category',
  templateUrl: './add-edit-waste-category.component.html',
  styleUrls: ['./add-edit-waste-category.component.scss']
})
export class AddEditWasteCategoryComponent implements OnInit {
  wasteCategoryForm: FormGroup;
  isUpdate = false;
  slide = true; 
  isEdit = false;
  isAcumen:any;
  companyID:any;
  companyList=[{label:'Please Select',value:''}];
  data: any = [];
  wasteTypeData = [];
  wasteCategoryList = [];
  allWasteCategoryList = [];
  @Input('data') viewData: any = {};
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(private messageService: MessageService,
    private dialogService: DialogService,
    private service: WasteCategoryService,
    private cdr:ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,) { 
    this.wasteCategoryForm = new FormGroup({
      wasteTypeId: new FormControl(''),
      wasteType: new FormControl('',[ Validators.required ]),
      linked_waste_category:new FormControl(''),
      default_min_tonnage:new FormControl('')
  });
  }
  setTwoNumberDecimal(event,key){
    let val = this.wasteCategoryForm.get(key).value.toString();
  //   if (val.indexOf('.') != -1){
  //       if (val.split(".")[1].length > 2){
          this.wasteCategoryForm.get(key).setValue(parseFloat(val).toFixed(2));
      //  }
  //  }
} 
editEvent(event){
  this.isEdit = true;
  this.isUpdate = true;
  this.isView = false;
  this.loadData();
}
ngOnInit() {
  console.log('this.config',this.config);
//   var element = document.getElementById("styleSelector");
//                 element.classList.remove("slide-out");
//                 element.classList.add("animate-block");
  this.companyID = localStorage.getItem('companyID');
  this.isAcumen = localStorage.getItem('isAcumen');
  this.loadData();
  this.fetchEWCCategory();

}
loadData(){
   
    if(this.viewData && (Object.keys(this.viewData).length !== 0)) {
        console.log(' true vehicleData',this.viewData);
        this.data = this.viewData;
       
        this.isUpdate = true;
      if (this.isUpdate){
        this.wasteCategoryForm.controls['wasteType'].setValue(this.data.wasteType);
        this.wasteCategoryForm.controls['wasteTypeId'].setValue(this.data.wasteTypeId);
        if (this.data.default_min_tonnage){
          this.wasteCategoryForm.controls['default_min_tonnage'].setValue(this.data.default_min_tonnage);
        }

      }
      if(this.isView){
        // const dataArr = [];
        // if(this.viewData.linked_ewc_code.length > 0){
        //   this.viewData.linked_ewc_code.forEach(element => {
        //      dataArr.push(element.code_description);
        //   });
        //   this.viewData.linked_ewc_code = dataArr;
        // }
      }
    
  }
}
closeEvent(event){
   if (this.isEdit == false) {
//     var element = document.getElementById("styleSelector");
//   element.classList.remove("animate-block");
//     element.classList.add("slide-out");
this.slide = false;
this.cdr.detectChanges();
    setTimeout(e =>{
      this.closeEvents.emit(true);
   },1000)

   } else{
    this.isEdit = false;
    this.isView = true;
    this.isUpdate = false;
    this.loadData();
   }
}




updateWasteCategory(event: any) {
  let value: any = '';
  const categoryList = [];
  value = this.wasteCategoryForm.value;
  value.companyId = this.data.companyId;
  value._id = this.data._id;
  if(value.linked_waste_category.length > 0){
      value.linked_waste_category.forEach(element => {
          let data;
         if(this.wasteTypeData.length > 0){
          var exists = this.wasteTypeData.find(({ewc_code}) => 
          element === ewc_code);
      if(exists){

          const subArr = [];
          let val;
          if(exists.sub_waste_category.length > 0){
              exists.sub_waste_category.forEach(e2 =>{
                 const subSubArr = [];
                 if(e2.sub_sub_waste_category.length > 0){
                     e2.sub_sub_waste_category.forEach(e3 => {
                         subSubArr.push(e3.ewc_code);
                     });
                   
                 }
                 val = {ewc_code:e2.ewc_code,sub_sub_waste_category:subSubArr};
                 subArr.push(val);
             });
            }
             data = {ewc_code:exists.ewc_code,sub_waste_category:subArr}
             categoryList.push(data);

         }else{
          data = {ewc_code:element,sub_waste_category:[]}
          categoryList.push(data); 
         }
      }else{
          data = {ewc_code:element,sub_waste_category:[]}
          categoryList.push(data);
      }
           
      });
      value.linked_waste_category = categoryList;
  }else{
    value.linked_waste_category = [];
  }
  console.log('value',value)
  this.service.updateWasteType(value).subscribe((data: any) => {
      if (data.status === 200) {
          this.messageService.add({
              severity: 'success',
              summary: 'Update Waste type',
              detail: 'Waste type details updated successfully'
          });
       //   this.dialogService.closeAll();
      } else if (data.status === 500) {
          this.messageService.add({
              severity: 'error',
              summary: 'Update Waste type',
              detail: data.error.message.errmsg
          });
      }else {
          console.log('Error',data);
          this.messageService.add({
              severity: 'error',
              summary: 'Update Waste type',
              // detail: 'Failed to quotes the Waste type details'
              detail: 'Unable to update Waste type.Please try again.'
          });
      }
      if(this.isEdit == true){
        this.service.getWasteTypeDetails(this.data.wasteTypeId).subscribe((response) => {
          this.viewData = response.data;
          this.closeEvent(event);

        });
      }else{
        this.closeEvent(event);

      }

  });
}
createWasteCategory(e: any) {
  let quotesData: any = '';
  quotesData = this.wasteCategoryForm.value;
  const categoryList = [];
  if(quotesData.linked_waste_category.length > 0){
      quotesData.linked_waste_category.forEach(element => {
          let data;
          data = {ewc_code:element,sub_waste_category:[]};
          categoryList.push(data);
      });
      quotesData.linked_waste_category = categoryList;
  }else{
    quotesData.linked_waste_category = [];
  }
  quotesData.wasteTypeId =  quotesData.wasteType;
  console.log('quotes',quotesData);

  this.service.createWasteType(quotesData).subscribe(
      (data: any) => {
          if (data.status === 200) {
              this.messageService.add({
                  severity: 'success',
                  summary: 'Create Waste type',
                  detail: 'New Waste types created successfully'
              });
          //    this.dialogService.closeAll();
          this.closeEvents.emit(true);
          }else if (data.status === 500) {
              this.messageService.add({
                  severity: 'error',
                  summary: 'Create Waste type',
                  detail: data.error.message.errmsg
              });
          }
           else {
              this.messageService.add({
                  severity: 'error',
                  summary: 'Create Waste types',
                //  detail: 'Failed to create the Waste types'
                detail: 'Unable to add Waste type.Please try again.'
              });
          }
          
      },
      (err) => {
          console.log('Error',err);
          this.messageService.add({
              severity: 'error',
              summary: 'Create Waste types',
             // detail: 'Failed to create the Waste types'
             detail: 'Unable to add Waste type.Please try again.'
          });
         // this.dialogService.closeAll();
      }
  );
}
fetchEWCCategory(){
  // this.service.parentWasteTypes({}).subscribe((data: any) => {
  // if(data.length > 0){
  //     data.forEach(element => {
  //         let val;
  //         val = {label: element.ewc_code +' - '+ element.code_description,value:element.ewc_code};
  //         this.wasteCategoryList.push(val);
  //     });
      
      if(this.isUpdate){
          const ewcCategory = [];
        if(this.data.linked_waste_category)  {
          this.wasteTypeData = this.data.linked_waste_category;
          if (this.data.linked_waste_category.length > 0){
              this.data.linked_waste_category.forEach(element => {
                  ewcCategory.push(element.ewc_code);
              });
          }
        }
          this.wasteCategoryForm.controls['linked_waste_category'].setValue(ewcCategory);
       }

  // }
  // });

}
search(event){
  const filtered: any[] = [];
    for (let i = 0; i < this.allWasteCategoryList.length; i++) {
    const category = this.allWasteCategoryList[i];
      if (category.label.trim().toLowerCase().indexOf(event.query.toLowerCase()) > -1 ||category.value.trim().toLowerCase().indexOf(event.query.toLowerCase()) > -1) {
       filtered.push(category);
    }
  }
 // this.filterSearchSort(filtered);
  this.wasteCategoryList = filtered;
  console.log('categoryList',this.wasteCategoryList);
  }
  filterSearchSort(filtered:any){
      filtered.sort((a,b)=>{
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) //sort string ascending
            return -1 
        if (nameA > nameB)
            return 1
        return 0 //default return value (no sorting)
        })
    }
  

}
