<div   class="" >
  
    <div id="styleSelector"  class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
<div class="container h-100">
        <div class="row align-items-center">
          <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
              <div class='row add-edit-header'  *ngIf="!isView">  
                  <div class="ui-g-10">
                      <h4 *ngIf='!isUpdate'>Create Customer</h4>
                      <h4 *ngIf='isUpdate'>Edit Customer</h4>
                  </div> 
                  <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Customer" tooltipPosition="top">
                      <i class="fa fa-window-close fa-2x "></i>
                   </div>    
             </div> 
                       <div class="">                    
            <form [formGroup]="customerForm" *ngIf="!isView">
           
              <div class='row form-group'>
                <div class='ui-g-12 mt-3'>
                    <label for="firstname">Big change Id</label>
                  <input class='ui-g-12' id="firstname" class="form-control {{isUpdate?'disabled':''}}"  type="{{isUpdate?'text':'number'}}"  size="30" formControlName='companyId' pInputText>
                </div>
              </div>   
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                        <label for="lastname">Company Name</label>
                  <input class='ui-g-12' id="lastname" type="text" class="form-control"size="30" formControlName='companyName' pInputText>
                </div>
              </div>   
            
           
              <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                <div class='ui-g-12'>
                  <p-button label="Create" [disabled]='!customerForm.valid' (onClick)="createCustomer($event)"></p-button>
                
                </div>
              </div>
              <div class='row button-cls' *ngIf='isUpdate'>
                <div class='ui-g-12'>
                  <p-button label="Update" [disabled]='!customerForm.valid' (onClick)="updateCustomer($event)"></p-button> 
                </div>
              </div>
            
            </form>
            <div *ngIf="isView">
                <div class='row details-header'>  
                    <div class="ui-g-10">
                        <h4>View Company Details</h4>
                    </div> 
                    <div class="ui-g-1" (click)="editEvent($event)" pTooltip="Click to edit a Customer" tooltipPosition="top">
                        <i class="fa fa-edit fa-2x "></i>
                    </div>    
                    <div class="ui-g-1" (click)="closeEvent($event)" pTooltip="Click to close a Customer" tooltipPosition="top">
                        <i class="fa fa-window-close fa-2x "></i>
                        </div>    
            </div>  
          <div class="details-content" *ngIf="viewData">
              <div class='row'>
                <div class="ui-g-6">
                  <span for="userName">Big change Id</span>
                </div>
                <div class="ui-g-6">
                  <span for="userName">{{viewData.companyId}}</span>
                </div>
              </div>
              <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Company Name</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.companyName}}</span>
                  </div>
                </div>
                <!-- <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Disposal sites</span>
                  </div>
                  <div class="ui-g-6" *ngIf="viewData.disposalLocations">
                      <span for="userName" *ngFor="let i of viewData.disposalLocations;let j = index;">
                        {{j+1}}.
                        <span>{{i.disposalSiteName}}</span><br/>
                      </span>
                    </div>
                </div> -->
                <!-- <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Waste categories</span>
                  </div>
                  <div class="ui-g-6" *ngIf="viewData.wasteTypes">
                    <span for="userName" *ngFor="let i of viewData.wasteTypes;let j = index;">
                      {{j+1}}.
                      <span>{{i.wasteType}}</span><br/>
                    </span>
                  </div>
                </div> -->
               
                
                  
                    
                        
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>
      </div>