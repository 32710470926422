import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { JobService } from '../../job-data.service';
 import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-edit-netweight',
  templateUrl: './add-edit-netweight.component.html',
  styleUrls: ['./add-edit-netweight.component.scss']
})
export class AddEditNetweightComponent implements OnInit {
  jobForm:FormGroup;
  isUpdate = false;
  slide = true; 
  isEdit = false;
  isAcumen:any;
  companyID:any;
  companyList=[{label:'Please Select',value:''}];
  data: any = [];
  @Input('data') viewData: any = {};
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private service: JobService,
    private cdr:ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
  ) {
    this.jobForm  = new FormGroup({
      netweight: new FormControl('',[ Validators.required ])
  });
   }

  ngAfterViewInit(){
   
    console.log('ngafterviewinit',this.jobForm);
}
editEvent(event){
  this.isEdit = true;
  this.isUpdate = true;
  this.isView = false;
 this.loadData();
}



updateJob(event: any) {
   // console.log('update student', value.email, value.cemail);
    let value: any = '';
    value = this.jobForm.value;
    value._id=this.data._id;
    console.log('Update value',this.jobForm);
  
        this.service.updateJob(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Update NetWeight',
                    detail: 'Net Weight updated successfully'
                });
                // this.ref.close(this);
              if(this.isEdit == true){
                // this.service.companyDetails(this.data.companyId).subscribe((response) => {
                //     console.log('company  details', response);
                //     this.viewData = response.data;
                //     this.closeEvent(true);
                // });
              }  else{
                 // this.closeEvents.emit(true);
                 this.closeEvent(true);

              }
               

            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Job',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Job',
                    detail: 'Unable to update Job.Please try again.'
                  //  detail: 'Failed to update the Job
                });
            }
          
        });
   
    
}
ngOnInit() {
  console.log('this.config',this.config);
  this.loadData();


}

closeEvent(event){
   if (this.isEdit == false) {
//     var element = document.getElementById("styleSelector");
//   element.classList.remove("animate-block");
//     element.classList.add("slide-out");
this.slide = false;
this.cdr.detectChanges();
    setTimeout(e =>{
      this.closeEvents.emit(true);
   },1000)

   } else{
    this.isEdit = false;
    this.isView = true;
    this.isUpdate = false;
 //   this.loadData();
   }
}
loadData(){
  
    if(this.viewData && (Object.keys(this.viewData).length !== 0)) {
        console.log(' true vehicleData',this.viewData);
        this.data = this.viewData;
        if(this.isView){
          this.viewData.active = this.viewData.active == true?'Yes':'No';
          console.log(' true vehicleData',this.viewData);

        }
        this.isUpdate = true;
      if (this.isUpdate){
        this.jobForm.controls['netweight'].setValue(this.data.net_weight);

     
      }
    
  }
}

}
