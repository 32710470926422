import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CustomerSitesService } from '../customer-site.service';
 import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-edit-customer-site',
  templateUrl: './add-edit-customer-site.component.html',
  styleUrls: ['./add-edit-customer-site.component.scss']
})
export class AddEditCustomerSiteComponent implements OnInit {
  TipSitesForm:FormGroup;
  isUpdate = false;
  slide = true; 
  isEdit = false;
  isAcumen:any;
  companyID:any;
  companyList=[{label:'Please Select',value:''}];
  disposalList=[{label:'Please Select', value:''}];
  data: any = [];
  @Input('data') viewData: any = {};
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private service: CustomerSitesService,
    private cdr:ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
  ) {
    this.TipSitesForm  = new FormGroup({
      Id:new FormControl('',[ Validators.required ]),
      ParentId: new FormControl('',[ Validators.required ]),
      Name: new FormControl(''),
      disposalLocationId:new FormControl('',[ Validators.required ]),
      tipSiteName:new FormControl('',[ Validators.required ])
  });
    
   }
  
  ngAfterViewInit(){
    console.log('before ngafterviewinit',this.TipSitesForm);
   
}
editEvent(event){
  this.isEdit = true;
  this.isUpdate = true;
  this.isView = false;
  this.loadData();
}

companyDepartmentList(){
    this.service.companyList().subscribe((data) => {
        if (data.status === 200) {
            console.log('company list',data);
            data.data.forEach(element => {
                if(this.isAcumen == 'true' || this.isAcumen == true){
                    this.companyList.push({label:element.companyName,value:element.companyId});
                  } else{
                     if (element.companyId == this.companyID) {
                         let data = {label:element.companyName,value:element.companyId};
                       this.companyList.push({label:element.companyName,value:element.companyId});
                       if(this.isAcumen == 'false' || this.isAcumen == false &&!this.isUpdate){
                        console.log('abcdefghijk');
                        this.TipSitesForm.controls['companyId'].setValue(element.companyId);
                        // this.companyChange(data);

                    }
                    }
                  }
            });
            if(this.isUpdate){
              this.TipSitesForm.controls['ParentId'].setValue(this.data.ParentId);
              let event;
              this.companyChange(event);
          }
           
            
           
         
        }else{

        }
       

    });
   
}
companyChange(event){
  this.disposalList=[{label:'Please Select', value:''}];
  let id = this.TipSitesForm.get('ParentId').value;
  console.log('id',id);
  this.service.fetchDisposalLocation(id).subscribe((data) => {
  console.log('Disposal list',data);
 
  if (data.status === 200) {
      console.log('Disposal list1',data);
      data.data.forEach(element => {
          this.disposalList.push({label:element.Name,value:element.Id});
      });
   if(this.isUpdate){
      this.TipSitesForm.controls['disposalLocationId'].setValue(this.data.disposalLocationId);
   }
  }
  });
}
disposalSiteChange(event){

 this.disposalList.forEach(element => {
     if(element.value == this.TipSitesForm.get('disposalLocationId').value){
      this.TipSitesForm.get('Name').setValue(element.label);
     }
 });
 console.log('disposalSiteChange',this.disposalList,event,this.TipSitesForm.get('Name').value);
}

createSite(e) {
    let value: any = '';
    console.log('Value',this.TipSitesForm.value);
    value = this.TipSitesForm.value;
  //  value.username = this.studentForm.controls['student_number'].value;
  //  value.college_id = localStorage.getItem('college_id');
   //  if (value.email === value.cemail) {
   
        this.service.createTipLocation(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Create Customer site',
                    detail: 'New Customer site created successfully'
                });
                // this.ref.close(this);
               // this.closeEvents.emit(true);
               this.closeEvent(true);

            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Create Customer site',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Create Customer site',
                    detail: 'Unable to add Customer site.Please try again.'
                  //  detail: 'Failed to create the User'
                });
            }
        },
      //  });
      (err) => {
        console.log('Error',err);
        this.messageService.add({
            severity: 'error',
            summary: 'Create Customer site',
           // detail: 'Failed to create the Waste types'
           detail: 'Unable to add Customer site.Please try again.'
        });
       // this.dialogService.closeAll();
    }
);
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
    
}
updateSite(event: any) {
   // console.log('update student', value.email, value.cemail);
    let value: any = '';
    value = this.TipSitesForm.value;
    value._id=this.data._id;
    console.log('Update value',this.TipSitesForm);
   

        this.service.updateTipLocation(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Update Customer site',
                    detail: 'Customer site details updated successfully'
                });
                // this.ref.close(this);
              if(this.isEdit == true){
                this.service.getTipDetails(this.data.Id).subscribe((response) => {
                    console.log('user  details', response);
                    this.viewData = response.data;
                    this.closeEvent(true);
                });
              }  else{
                 // this.closeEvents.emit(true);
                 this.closeEvent(true);

              }
               

            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Customer site',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Customer site',
                    detail: 'Unable to update User.Please try again.'
                  //  detail: 'Failed to update the user details'
                });
            }
          
        });
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
    
}
ngOnInit() {
  console.log('this.config',this.config);
//   var element = document.getElementById("styleSelector");
//                 element.classList.remove("slide-out");
//                 element.classList.add("animate-block");
  this.companyID = localStorage.getItem('companyID');
  this.isAcumen = localStorage.getItem('isAcumen');
  this.loadData();

this.companyDepartmentList();
}
loadData(){
   
    if(this.viewData && (Object.keys(this.viewData).length !== 0)) {
        console.log(' true vehicleData',this.viewData);
        this.data = this.viewData;
        this.isUpdate = true;
      if (this.isUpdate){
        this.TipSitesForm.controls['Id'].setValue(this.data.Id);
            this.TipSitesForm.controls['Name'].setValue(this.data.Name);
            this.TipSitesForm.controls['ParentId'].setValue(this.data.ParentId);
            this.TipSitesForm.controls['tipSiteName'].setValue(this.data.tipSiteName);

       
      }
    
  }
}
closeEvent(event){
   if (this.isEdit == false) {
//     var element = document.getElementById("styleSelector");
//   element.classList.remove("animate-block");
//     element.classList.add("slide-out");
this.slide = false;
this.cdr.detectChanges();
    setTimeout(e =>{
      this.closeEvents.emit(true);
   },1000)

   } else{
    this.isEdit = false;
    this.isView = true;
    this.isUpdate = false;
    this.loadData();
   }
}



}
