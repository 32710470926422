import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
@Component({
    selector: 'app-image-view',
    templateUrl: './image-view.html',
    styleUrls: ['./image-view.scss']
})
export class ImageViewComponent  implements OnInit {
    _data: any = [];
    get viewData() {
        return this._data;
    }
    set viewData(data) {
        console.log('Image details: ', data);

        if (data) {
            this._data = data;
            
           
        }
    }
    constructor( public ref: DynamicDialogRef, public config: DynamicDialogConfig,
      
    ) {
       
       
    }
   
    closeEvent(event){
        this.ref.close();
    }
     
      
    ngOnInit() {   
        this._data =  this.config.data.viewData;  
        console.log('config',this.config,this._data);  
     }
}
