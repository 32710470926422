import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { StaffService } from '../staff-data.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { AddEditStaffComponent } from '../add-edit-staff/add-edit-staff.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-staff-data',
  templateUrl: './staff-data.component.html',
  styleUrls: ['./staff-data.component.scss']
})
export class StaffDataComponent implements OnInit {
  userDetails = [];
  showLoader = true;
  staffData: any = {};
  showAdd = false;
  isAcumen: any;
  companyID: any;
  isView = false;
  showimport = false;
  showExport = false;
  selectedStaff: any;
  searchArr = [];
  cols1 = [
    {
      header: 'Name',
      field: 'name'
    },
    {
      header: 'Company',
      field: 'companyName'
    },
    {
      header: 'Department',
      field: 'department'
    },
    {
      header: 'Email',
      field: 'email'
    },
    {
      header: 'Contact Number',
      field: 'phone'
    },



  ];
  cols2 = [
    {
      header: 'Name',
      field: 'name'
    },
    {
      header: 'Email',
      field: 'email'
    },
    {
      header: 'Contact Number',
      field: 'phone'
    },



  ];
  cols = [];
  constructor(private http: HttpClient, private service: StaffService, private translateService: TranslateService, private cdr: ChangeDetectorRef, public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService, private confirmDialogService: ConfirmationService) { }

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem('companyID');
    this.isAcumen = localStorage.getItem('isAcumen');
    this.cols = this.isAcumen == 'true' ? this.cols1 : this.cols2;
    this.fetchStaffList();
    if (this.cols.length > 0) {
      this.cols.forEach(element => {
        this.searchArr.push(element.field);
      });
    }

  }
  clearIndex() {
    localStorage.removeItem('jobID');
    localStorage.removeItem('pageJobIndex');
    localStorage.removeItem('pageDisposalIndex');
    localStorage.removeItem('pageWasteIndex');
    localStorage.removeItem('pageReportIndex');
  }
  change(event, value) {
    let obj;
    obj = {
      phone: value.phone,
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      company: value.company.companyId,
      department: value.department,
      employeeId: value.employeeId,
      active: value.active,
      //  password: value.password,
      _id: value._id
    };
    console.log('value', obj);

    this.service.updateUser(obj).subscribe((data) => {
    });
  }
  callList(event) {
    console.log('call list');
    this.showAdd = false;
    this.isView = false;
    this.showimport = false;
    this.showExport = false;
    this.staffData = {};
    this.cdr.detectChanges();
    this.fetchStaffList();
  }
  createStaff(event) {
    console.log('create staff called');
    // const ref = this.dialogService.open(AddEditStaffComponent, {
    //     header: 'Create Staff ',
    //     width: '600px',
    // });

    // ref.onClose.subscribe((car) => {
    //     if (car) {
    //         // this.messageService.add({severity:'info', summary: 'Staff added successfully'});
    //      this.fetchStaffList();
    //     }
    // });
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.staffData = {};
      setTimeout(e => {
        this.showAdd = true;
      }, 1000)
    } else {
      this.showAdd = true;
    } this.cdr.detectChanges();
  }
  editStaff(event, data) {
    // let editData: any = '';
    //  this.showLoader=true;
    // console.log("Data",data);
    // this.service.getUserDetails(data.employeeId).subscribe((response) => {
    this.service.getUserDetails(data._id).subscribe((response) => {
      console.log('user  details', response);
      this.staffData = response.data[0];
      this.showAdd = true;
      console.log('user  details', this.staffData);

      this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });

      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchStaffList();
      //     }
      // });
    });
  }
  viewStaff(event, data) {

    // this.service.getUserDetails(data.employeeId).subscribe((response) => {
    this.service.getUserDetails(data._id).subscribe((response) => {
      console.log('user  details', response);
      this.staffData = response.data[0];
      this.isView = true;
      this.showAdd = true;
      console.log('user  details', this.staffData);

      this.cdr.detectChanges();


    });
  }

  async exportStaff(params) {
    console.log('called export staff again: ', params);
    this.showExport = true;
    this.cdr.detectChanges();

    // this.service.exportStaff({}).subscribe((resp) => {
    //   console.log('export success: ', resp);
    //   this.downloadCSV(resp.data)
    // }, err => {
    //   console.log('export error: ', err);
    // })
  }

  importStaff(event) {
    console.log("import staff")
    this.showimport = true;
    this.cdr.detectChanges();
  }

  deleteStaff(id) {
    this.confirmDialogService.confirm({
      message: 'Are you sure that you want to delete the staff?',
      accept: () => {
        this.service.deleteUser(id).subscribe((data: any) => {
          if (data.status === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Delete Staff',
              detail: 'Staff details deleted successfully'
            });

          } else {

            this.messageService.add({
              severity: 'error',
              summary: 'Delete Staff',
              detail: data.error.message
            });
          }
          this.fetchStaffList();
        },
          (err) => {
            console.log('error', err);
            this.messageService.add({
              severity: 'error',
              summary: 'Delete Staff',
              detail: 'Failed to delete the Staff'
            });
            this.fetchStaffList();
          }
        );
        //  });
      }
    });
  }
  fetchStaffList() {
    const staffArr = [];
    this.service.fetchUser().subscribe((response) => {
      console.log('staff  details', response.data);

      // response.data.forEach((ele) => {
      //     ele.isActive = ele.active ? 'Yes' : 'No';
      // });
      if (response.data.length > 0) {
        response.data.forEach(element => {
          // console.log('element', element, this.companyID);
          if (this.isAcumen == 'true' || this.isAcumen == true) {
            staffArr.push(element);
          } else {
            if (element.company.companyId == this.companyID) {
              staffArr.push(element);
            }
          }
        });
      }
      this.userDetails = staffArr;
      if (this.userDetails.length > 0) {
        this.userDetails.forEach((ele) => {
          if (ele.company) {
            ele.companyId = ele.company.companyId;
            ele.companyName = ele.company.companyName;
          }
          ele.name = ele.firstName + ' ' + ele.lastName;
        });
      }
      this.showLoader = false;
    });
  }
}
