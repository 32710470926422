<div   class="" >
  
        <div id="styleSelector"  class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
    <div class="container h-100">
            <div class="row align-items-center">
              <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
                  <div class='row add-edit-header'  *ngIf="!isView">  
                      <div class="ui-g-10">
                          <h4 *ngIf='!isUpdate'>Create Waste Category</h4>
                          <h4 *ngIf='isUpdate'>Edit Waste Category</h4>
                      </div> 
                      <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Waste category" tooltipPosition="top">
                          <i class="fa fa-window-close fa-2x "></i>
                       </div>    
                           </div> 
                           <div class="">                    
                <form [formGroup]="wasteCategoryForm" *ngIf="!isView">
               
                
                  <div class='row form-group'>
                    <div class='ui-g-12 mt-3'>
                        <label for="firstname">Waste Category</label>
                      <input class='ui-g-12' id="firstname" class="form-control"  type="text" size="30" formControlName='wasteType' pInputText>
                    </div>
                  </div>   
                  <!-- <div class='row form-group' >
                       
                        <div class='ui-g-12  mt-3'>
                          <label for="linked_type">EWC Waste Category</label>
                          <p-multiSelect class='ui-g-12 form-control' [options]="wasteCategoryList" formControlName='linked_waste_category'></p-multiSelect>
              
                      </div>
                      </div> -->

                  <div class='row form-group'>
                    <div class='ui-g-12  mt-3'>
                            <label for="lastname">Default Minimum tonnage</label>
                      <input class='ui-g-12' id="lastname" type="number" class="form-control" step="0.25" (change)="setTwoNumberDecimal($event,'default_min_tonnage')" formControlName='default_min_tonnage' pInputText>
                    </div>
                  </div>   
                   
                  <!-- <div class='col-12' style='color:red;font-size: 12px' *ngIf="userForm.get(''">
                      Employee ID
                    </div> -->
                   
                
                   
                      
                  
                
                  
               
                  <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                    <div class='ui-g-12'>
                      <p-button label="Create" [disabled]='!wasteCategoryForm.valid' (onClick)="createWasteCategory($event)"></p-button>
                    
                    </div>
                  </div>
                  <div class='row button-cls' *ngIf='isUpdate'>
                    <div class='ui-g-12'>
                      <p-button label="Update" [disabled]='!wasteCategoryForm.valid' (onClick)="updateWasteCategory($event)"></p-button>
                    
                    </div>
                  </div>
                
                </form>
                <div *ngIf="isView">
                    <div class='row details-header'>  
                        <div class="ui-g-10">
                            <h4>View Waste Category Details</h4>
                        </div> 
                        <div class="ui-g-1" (click)="editEvent($event)" pTooltip="Click to edit a Waste category" tooltipPosition="top">
                            <i class="fa fa-edit fa-2x "></i>
                        </div>    
                        <div class="ui-g-1" (click)="closeEvent($event)" pTooltip="Click to close a Waste category" tooltipPosition="top">
                            <i class="fa fa-window-close fa-2x "></i>
                            </div>    
                </div>  
              <div class="details-content">
                    <div class='row'>
                            <div class="ui-g-6">
                              <span for="userName">Big change Id</span>
                            </div>
                            <div class="ui-g-6">
                              <span for="userName">{{viewData.wasteTypeId}}</span>
                            </div>
                          </div>
                  <div class='row'>
                    <div class="ui-g-6">
                      <span for="userName">Waste Type</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.wasteType}}</span>
                    </div>
                  </div>
                  <!-- <div class='row'>
                        <div class="ui-g-6">
                          <span for="userName">EWC Waste Category</span>
                        </div>
                        <div class="ui-g-6" *ngIf="viewData.linked_ewc_code.length > 0">

                          <span for="userName" *ngFor="let i of viewData.linked_ewc_code;let j = index">
                            {{j+1}}.
                            <span> {{i.code_description}}</span><br/>

                          </span>
                        </div>
                      </div> -->
                      <!-- <div class='row'>
                          <div class="ui-g-6">
                            <span for="userName">Company</span>
                          </div>
                          <div class="ui-g-6" *ngIf="viewData.companyId.length > 0">
                            <span for="userName" *ngFor="let i of viewData.companyId;let j=index">
                                {{j+1}}.
                              <span>{{i.companyName}}</span><br/>
                            </span>
                          </div>
                        </div> -->
                  <div class='row'>
                      <div class="ui-g-6">
                        <span for="userName">Default Minimum Tonnage</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{viewData.default_min_tonnage}}</span>
                      </div>
                    </div>
                    
    
                      
                        
                          
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          </div>