import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { WasteDescriptionService } from '../waste-description/waste-description.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { SkipService } from './skip.service';
@Component({
    selector: 'app-skip',
    templateUrl: './skip.component.html',
    styleUrls: ['./skip.component.scss']
})
export class SkipComponent implements OnInit {
    wasteDescriptionForm: FormGroup;
    subjectList = [];
    data: any;
    wasteCategoryList = [];
    allwasteCategoryList = [];
    wasteTypeData: any = [];
    isSubjectVisible = false;
    isUpdate = false;
    // CompanyForm: FormGroup;
    isView = false;
    _data: any = [];

    constructor(
        private service: SkipService,
        private translateService: TranslateService, private cdr: ChangeDetectorRef, public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig,
        private messageService: MessageService, private confirmDialogService: ConfirmationService

    ) {
        this.wasteDescriptionForm = new FormGroup({
            waste_description: new FormControl('', [Validators.required]),

        });

    }

    ngOnInit() {
        this.wasteDescriptionForm = new FormGroup({
            description: new FormControl('', [Validators.required]),
        });
        this.getData();

    }
    getData() {
        this.service.fetchSkip().subscribe((resp: any) => {
            console.log('data', resp);
            if (resp.data) {
                this.data = resp.data;
                this.isUpdate = true;
                this.wasteDescriptionForm.get('description').setValue(this.data.description);
            } else {
                this.isUpdate = false;
            }
        });
    }
    update(event) {
        let temp;
        temp = { _id: this.data._id, description: this.wasteDescriptionForm.value.description };
        this.service.updateSkip(temp).subscribe((data: any) => {
            this.messageService.add({
                severity: 'success',
                summary: 'Update Skip',
                detail: 'Skip updated successfully'
            });
            this.getData();


        });
    }

}
