import { VehicleService } from '../vehicle-data.service';
import { Component, OnDestroy, OnInit,ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { AddEditVehicleComponent } from '../add-edit-vehicle/add-edit-vehicle.component';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'app-vehicle-data',
  templateUrl: './vehicle-data.component.html',
  styleUrls: ['./vehicle-data.component.scss']
})
export class VehicleDataComponent implements OnInit {
  position ="top-right";
  vehicleList=[];
  showLoader = true;
  vehicleData:any = {};
  showAdd = false;
  isAcumen:any;
  isView = false;
  companyID:any;
  selectedVehicles:any;
  cols = [
    
    {
        header: 'Registration number',
        field: 'vehicle_reg_number'
    },
    {
        header: 'Vehicle type',
        field: 'vehicle_type'
    },
    {
        header: 'Parent Company',
        field: 'company'
    },
    // {
    //     header: 'Active/inactive',
    //     field: 'isActive'
    // },
];
searchArr = [];
  constructor(private translateService: TranslateService,private service:LoginService,private vehicleService: VehicleService,private cdr:ChangeDetectorRef,public dialogService: DialogService,public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,private confirmDialogService:ConfirmationService
  ) {
    
   }

  
  ngOnInit() {
   this.clearIndex();
    this.companyID = localStorage.getItem('companyID');
    this.isAcumen = localStorage.getItem('isAcumen');
  
    this.fetchVehicleList(null);
    if (this.cols.length > 0){
        this.cols.forEach(element => {
          this.searchArr.push(element.field);
        });
      }
  }
  clearIndex(){
    localStorage.removeItem('jobID');
    localStorage.removeItem('pageJobIndex'); 
    localStorage.removeItem('pageDisposalIndex'); 
    localStorage.removeItem('pageWasteIndex'); 
    localStorage.removeItem('pageReportIndex'); 
  }
  callList(event){
      console.log('call list');
      this.showAdd = false;
      this.isView = false;
      this.vehicleData = {};
      this.cdr.detectChanges();
      this.fetchVehicleList(null);
  }
  fetchVehicleList(event) {
      const vehicleArr = [];
    this.vehicleService.vehicleList().subscribe((response) => {
       // console.log('vehicle  details', response.data);
        if (response.data.length > 0){
        response.data.forEach(element => {
            console.log('loop',typeof(element.isActive),element.isActive);

           if (element.isActive){
                if (element.isActive === 1){
                    element.isActive = true;
                } else{
                element.isActive = false;
                }
            } else{
               element.isActive = false; 
            }
            if (element.company_details.length > 0){
                element.company = element.company_details[0].companyName;
            }
            if (element.vehicle_type.length > 0){
                element.vehicle_type = element.vehicle_type[0].name;
            }
         if (this.isAcumen == 'true' || this.isAcumen == true){
            vehicleArr.push(element);
         }   else{
            if (element.company_details.length > 0){
                if (element.company_details[0].companyId == this.companyID){
                    vehicleArr.push(element);

             }
          } 
         }
           
        });
        
    }
    
        this.vehicleList = vehicleArr;
        this.showLoader=false;
    });
}
editVehicle(event,data){
//     let editData: any = '';
   // this.showLoader = true;
    this.vehicleService.getVehicleDetails(data._id).subscribe((response) => {
        this.vehicleData = response[0];
     this.showAdd = true;
     console.log('user  details', this.vehicleData);

     this.cdr.detectChanges();
//     editData = response.data;
//     const ref = this.dialogService.open(AddEditVehicleComponent, {
//         data: {
//             viewData: response[0],
//             isUpdate: true
//         },
//         header: 'Edit Vehicle Details',
//         width: '600px',
//     });

//     ref.onClose.subscribe((car) => {
//         if (car) {
//             // this.messageService.add({severity:'info', summary: 'Vehicle Updated successfully'});
//          this.fetchVehicleList(null);
//         }
//     });
 });
}
viewVehicle(event,data){
    this.vehicleService.getVehicleDetails(data._id).subscribe((response) => {
    this.vehicleData = response[0];
    this.isView = true;
    this.showAdd = true;
     console.log('user  details', this.vehicleData);

     this.cdr.detectChanges();
    });
}

createVehicle(event){
   console.log('createVehicle called');
//   const ref = this.dialogService.open(AddEditVehicleComponent, {
//     header: 'Create New Vehicle',
//     width: '600px',
// });

// ref.onClose.subscribe((car) => {
//     // this.messageService.add({severity:'info', summary: 'Vehicle added successfully'});
//     this.fetchVehicleList(null);
// });
if(this.showAdd == true){
    this.showAdd = false;
    this.isView = false;
    this.vehicleData = {};
   setTimeout(e =>{
     this.showAdd = true;
   },1000) 
  }else{
    this.showAdd = true;
  }
  this.cdr.detectChanges();
}
change(event,val){
    let obj;
    this.vehicleService.getVehicleDetails(val._id).subscribe((response) => {
        obj = {
            company:response[0].company[0].companyId,
            vehicle_type:response[0].vehicle_type[0]._id,
            vehicle_reg_number:response[0].vehicle_reg_number,
            isActive:val.isActive,
            _id:val._id
        };
        console.log('value',obj);
          this.vehicleService.updateVehicle(obj).subscribe((data: any) => {
        },error=>{

        });
    });
   
}
deleteVehicle(id){
  this.confirmDialogService.confirm({
    message: 'Are you sure that you want to delete the Vehicle?',
    accept: () => {
        this.vehicleService.deleteVehicle(id).subscribe((data: any) => {
            if (data) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Delete Vehicle',
                    detail: 'Vehicle details deleted successfully'
                });
                
            } else {
             
              this.messageService.add({
                  severity: 'error',
                  summary: 'Delete Vehicle',
                  detail: data.error.message
              });
          }
          this.fetchVehicleList(null);
      },
      (err) => {
          console.log('error',err);
          this.messageService.add({
              severity: 'error',
              summary: 'Delete Vehicle',
              detail: 'Failed to delete the Vehicle'
          });
          this.fetchVehicleList(null);
      }
  );
        //});
    }
});
}
}
