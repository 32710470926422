import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { MapsAPILoader, LatLngLiteral } from '@agm/core';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { NgxSpinnerService } from "ngx-spinner";
import { StaffService } from "../staff-data.service";

@Component({
  selector: 'app-import-staff',
  templateUrl: './import-staff.component.html',
  styleUrls: ['./import-staff.component.scss']
})
export class ImportStaffComponent implements OnInit {
  isUpdate = false;
  slide = true;
  isEdit = false;
  public filesControl = new FormControl(null, [FileUploadValidators.filesLimit(1)]);

  public demoForm = new FormGroup({
    files: this.filesControl
  });
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(
    private dialogService: DialogService,
    private service: StaffService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private loader: NgxSpinnerService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    console.log('on init staff import');
  }

  importStaff(event) {

    console.log('importStaff: ', event);
    if (this.demoForm.valid) {
      const formData: any = new FormData();
      formData.append('file', this.demoForm.value.files[0]);
      this.loader.show()
      this.service.importStaff(formData).subscribe((resp) => {
        console.log('response: ', resp);
        if (resp.status === 200) {
          this.loader.hide()
          this.messageService.add({
            severity: 'success',
            summary: 'Import Staff',
            detail: `Imported: ${resp.inserted} \n
                     Updated: ${resp.updated} \n
                     Failed: ${resp.failed}`,
          });
          if (resp.failedData && resp.failedData.length > 0) {
            this.downloadCSV(resp.failedData, 'staff-failed')
          }
          this.closeEvent(event);
        } else {
          this.loader.hide()
          this.messageService.add({
            severity: 'error',
            summary: 'Import Prices',
            //  detail: 'Failed to create the Tip sites'
            detail: 'Failed to import.'
          });
          this.closeEvent(event);
        }

      }, err => {
        console.log('import error: ', err);
        this.loader.hide()
        this.messageService.add({
          severity: 'error',
          summary: 'Create Disposal site',
          //  detail: 'Failed to create the Tip sites'
          detail: 'Unable to upload the file.Please try again.'
        });
        this.closeEvent(event);
      })
    } else {
      console.log("errr", this.demoForm.value.files);
    }

  }

  closeEvent(event) {
    if (this.isEdit == false) {
      this.slide = false;
      this.cdr.detectChanges();
      setTimeout(e => {
        this.closeEvents.emit(true);
      }, 1000)

    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
    }
  }

  async downloadCSV(data: any, fileName: string = 'data') {
    const date = `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}_${new Date().getHours()}:${new Date().getMinutes()}`
    const exportFileName = `${fileName}_${date}.csv`

    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, exportFileName);
  }

}
