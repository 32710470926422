import { Component, EventEmitter, Input, OnDestroy, HostListener, OnInit, Output, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AdminUserService } from '../admin-users.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-admin-users',
  templateUrl: './add-edit-admin-users.component.html',
  styleUrls: ['./add-edit-admin-users.component.scss']
})
export class AddEditAdminUsersComponent implements OnInit {
  userForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  clickOpen = false;
  isEdit = false;
  errorMessage = false;
  isAcumen: any;
  companyID: any;
  companyList = [{ label: 'Please Select', value: '' }];
  data: any = [];
  @Input('data') viewData: any = {};
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private service: AdminUserService,
    private eRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
  ) {
    this.addForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      company: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      cpassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      active: new FormControl(true)
      //   isActive:new FormControl(false)

    });
    this.editForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      company: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      cpassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      active: new FormControl(true)
      //  isActive:new FormControl(false)

    });
  }
  //  @HostListener('click', ['$event'])
  //     clickout(event) {
  //      if(this.clickOpen) {
  //       if(this.eRef.nativeElement.contains(event.target)) {
  //         console.log("clicked inside");
  //       } else {
  //         console.log("clicked outside");
  //         this.closeEvent(event);
  //       }
  //     }
  //     }
  ngAfterViewInit() {
    console.log('before ngafterviewinit', this.userForm);
    if (!this.isUpdate) {
      this.userForm.patchValue({
        email: '',
        password: ''
      });

    }
    this.clickOpen = true;

    console.log('ngafterviewinit', this.userForm);
  }
  onChange(event){
    console.log('event',event);
    this.errorMessage = false;
    this.cdr.detectChanges();
  }
  editEvent(event) {
    this.isEdit = true;
    this.isUpdate = true;
    this.isView = false;
    this.loadData();
  }

  companyDepartmentList() {
    this.service.companyList().subscribe((data) => {
      if (data.status === 200) {
        console.log('company list', data);
        data.data.forEach(element => {
          if (this.isAcumen == 'true' || this.isAcumen == true) {
            this.companyList.push({ label: element.companyName, value: element.companyId });
          } else {
            if (element.companyId == this.companyID) {
              let data = { label: element.companyName, value: element.companyId };
              this.companyList.push({ label: element.companyName, value: element.companyId });
              if (this.isAcumen == 'false' || this.isAcumen == false && !this.isUpdate) {
                console.log('abcdefghijk');
                this.userForm.controls['company'].setValue(element.companyId);
                // this.companyChange(data);

              }
            }
          }
        });

        if (this.isUpdate) {
          let data;
          data = {
            value: this.data.company.companyId
          };
          // this.companyChange(data);
          this.userForm.controls['company'].setValue(this.data.company.companyId);

        }


      } else {

      }


    });

  }
  createAdmin(e) {
    let value: any = '';
    console.log('Value', this.userForm.value);
    value = this.userForm.value;

    if (this.userForm.get('cpassword').value !== this.userForm.get('password').value) {
      this.userForm.get('cpassword').setValue('');
      this.userForm.get('password').setValue('');
      // this.messageService.add({
      //   severity: 'failure',
      //   summary: 'Error',
      //   detail: 'Password and confirm password does not match'
      // });
      this.errorMessage = true;
    } else {
      this.errorMessage = false
      delete value.cpassword;
      this.service.createAdmin(value).subscribe((data) => {
        if (data.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Create Admin',
            detail: 'New Admin created successfully'
          });
          // this.ref.close(this);
          // this.closeEvents.emit(true);

        } else if (data.status === 500) {
          this.messageService.add({
            severity: 'error',
            summary: 'Create Admin',
            detail: data.error.message.errmsg
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Create Admin',
            detail: 'Unable to add Admin.Please try again.'
            //  detail: 'Failed to create the User'
          });
        }
        this.closeEvent(true);

      },
        //  });
        (err) => {
          console.log('Error', err);
          this.messageService.add({
            severity: 'error',
            summary: 'Create Admin',
            // detail: 'Failed to create the Waste types'
            detail: 'Unable to add User.Please try again.'
          });
          // this.dialogService.closeAll();
        }
      );
      // } else {
      //     this.messageService.add({
      //         severity: 'failure',
      //         summary: 'Create Student',
      //         detail: 'Student Email and confirm email are not matching'
      //     });
      // }
    }
  }
  updateAdmin(event: any) {
    // console.log('update student', value.email, value.cemail);
    let value: any = '';
    value = this.userForm.value;
    value._id = this.data._id;
    console.log('Update value', this.userForm.get('cpassword').value, ':', this.userForm.get('password').value);
    if (this.userForm.get('cpassword').value !== this.userForm.get('password').value) {
      this.userForm.get('cpassword').setValue('');
      this.userForm.get('password').setValue('');
      this.errorMessage = true;
      // this.messageService.add({
      //   severity: 'error',
      //   summary: 'Error',
      //   detail: 'Password and confirm password does not match'
      // });
    } else {
    // delete value.password;
    this.errorMessage = false;
     delete value.cpassword;
    this.service.updateAdmin(value).subscribe((data) => {
      if (data.status === 200) {
        this.messageService.add({
          severity: 'success',
          summary: 'Update Admin',
          detail: 'Admin details updated successfully'
        });
        // this.ref.close(this);
        if (this.isEdit == true) {
          console.log('data', this.data);
          this.service.getDetails(this.data._id).subscribe((response) => {
            console.log('user  details', response);
            this.viewData = response.data[0];
            this.closeEvent(true);
          });
          // this.ref.close(this);
          } else {
            // this.closeEvents.emit(true);
            this.closeEvent(true);

          }


        } else if (data.status === 500) {
          this.messageService.add({
            severity: 'error',
            summary: 'Update Admin',
            detail: data.error.message.errmsg
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Update Admin',
            detail: 'Unable to update User.Please try again.'
            //  detail: 'Failed to update the user details'
          });
        }

      });
      // } else {
      //   //     this.messageService.add({
      //   //         severity: 'failure',
      //   //         summary: 'Create Student',
      //   //         detail: 'Student Email and confirm email are not matching'
      //   //     });
    }
    //}
  }
  ngOnInit() {
    console.log('this.config', this.config);
    //   var element = document.getElementById("styleSelector");
    //                 element.classList.remove("slide-out");
    //                 element.classList.add("animate-block");
    this.companyID = localStorage.getItem('companyID');
    this.isAcumen = localStorage.getItem('isAcumen');
    this.loadData();

    this.companyDepartmentList();
  }
  loadData() {
    if (this.isUpdate) {
      this.userForm = this.editForm;
    } else {
      this.userForm = this.addForm;
    }
    if (this.viewData && (Object.keys(this.viewData).length !== 0)) {
      console.log(' true vehicleData', this.viewData);
      this.data = this.viewData;
      if (this.isView) {
        this.viewData.active = this.viewData.active == true ? 'Yes' : 'No';
        console.log(' true vehicleData', this.viewData);

      }
      this.isUpdate = true;
      if (this.isUpdate) {
        if (this.data.active == 'No' || this.data.active == 'Yes') {
          this.data.active = this.data.active == 'Yes' ? true : false;
        }
        this.userForm = this.editForm;
        this.userForm.controls['email'].setValue(this.data.email);
        this.userForm.controls['firstName'].setValue(this.data.firstName);
        this.userForm.controls['lastName'].setValue(this.data.lastName);
        this.userForm.controls['password'].setValue(this.data.password);
        this.userForm.controls['cpassword'].setValue(this.data.password);
        this.userForm.controls['active'].setValue(this.data.active);

        if (this.data.company) {
          this.userForm.controls['company'].setValue(this.data.company.companyId);
        }
      }

    }
  }
  closeEvent(event) {
    console.log('create', this.isEdit);
    if (this.isEdit == false) {
      //     var element = document.getElementById("styleSelector");
      //   element.classList.remove("animate-block");
      //     element.classList.add("slide-out");
      this.slide = false;
      this.clickOpen = false;
      this.cdr.detectChanges();
      setTimeout(e => {
        this.closeEvents.emit(true);
      }, 1000)

    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
      this.loadData();
    }
  }

}
