import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CustomerDivisionsService } from '../customer-divisions.service';
 import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-edit-customer-divisions',
  templateUrl: './add-edit-customer-divisions.component.html',
  styleUrls: ['./add-edit-customer-divisions.component.scss']
})
export class AddEditCustomerDivisionsComponent implements OnInit {
  DivisionForm:FormGroup;
  isUpdate = false;
  slide = true; 
  isEdit = false;
  isAcumen:any;
  companyID:any;
  companyList=[{label:'Please Select',value:''}];
  data: any = [];
  @Input('data') viewData: any = {};
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private service: CustomerDivisionsService,
    private cdr:ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
  ) {
    this.DivisionForm  = new FormGroup({
      companyId: new FormControl('',[ Validators.required ]),
      departmentName: new FormControl('',[ Validators.required ]),
      deptId:new FormControl('',[ Validators.required ])
  });
    
   }

  ngAfterViewInit(){
    console.log('before ngafterviewinit',this.DivisionForm);
   
}
editEvent(event){
  this.isEdit = true;
  this.isUpdate = true;
  this.isView = false;
  this.loadData();
}

companyDepartmentList(){
    this.service.companyList().subscribe((data) => {
        if (data.status === 200) {
            console.log('company list',data);
            data.data.forEach(element => {
                if(this.isAcumen == 'true' || this.isAcumen == true){
                    this.companyList.push({label:element.companyName,value:element.companyId});
                  } else{
                     if (element.companyId == this.companyID) {
                         let data = {label:element.companyName,value:element.companyId};
                       this.companyList.push({label:element.companyName,value:element.companyId});
                       if(this.isAcumen == 'false' || this.isAcumen == false &&!this.isUpdate){
                        console.log('abcdefghijk');
                        this.DivisionForm.controls['companyId'].setValue(element.companyId);
                        // this.companyChange(data);

                    }
                    }
                  }
            });
           
            if(this.isUpdate){
                
                this.DivisionForm.controls['companyId'].setValue(this.data.companyId);
              
            }
           
         
        }else{

        }
       

    });
   
}
createDivision(e) {
    let value: any = '';
    console.log('Value',this.DivisionForm.value);
    value = this.DivisionForm.value;
  //  value.username = this.studentForm.controls['student_number'].value;
  //  value.college_id = localStorage.getItem('college_id');
   //  if (value.email === value.cemail) {
   
        this.service.createDepartment(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Create Customer Division',
                    detail: 'New Customer division created successfully'
                });
                // this.ref.close(this);
               // this.closeEvents.emit(true);
               this.closeEvent(true);

            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Create Customer Division',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Create Customer Division',
                    detail: 'Unable to add Customer Division.Please try again.'
                  //  detail: 'Failed to create the User'
                });
            }
        },
      //  });
      (err) => {
        console.log('Error',err);
        this.messageService.add({
            severity: 'error',
            summary: 'Create Customer Division',
           // detail: 'Failed to create the Waste types'
           detail: 'Unable to add Customer Division.Please try again.'
        });
       // this.dialogService.closeAll();
    }
);
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
    
}
updateDivision(event: any) {
   // console.log('update student', value.email, value.cemail);
    let value: any = '';
    value = this.DivisionForm.value;
    value._id=this.data._id;
    console.log('Update value',this.DivisionForm);
   

        this.service.updateDepartment(value).subscribe((data) => {
            if (data.status === 200) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Update Customer Division',
                    detail: 'Customer Division details updated successfully'
                });
                // this.ref.close(this);
              if(this.isEdit == true){
                console.log('id',this.data);
                this.service.getDepartmentDetails(this.data.deptId).subscribe((response) => {
                    console.log('user  details', response);
                    this.viewData = response.data;
                    this.closeEvent(true);
                });
              }  else{
                 // this.closeEvents.emit(true);
                 this.closeEvent(true);

              }
               

            } else if (data.status === 500) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Customer Division',
                    detail: data.error.message.errmsg
                });
            }else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Update Customer Division',
                    detail: 'Unable to update User.Please try again.'
                  //  detail: 'Failed to update the user details'
                });
            }
          
        });
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
    
}
ngOnInit() {
  console.log('this.config',this.config);
//   var element = document.getElementById("styleSelector");
//                 element.classList.remove("slide-out");
//                 element.classList.add("animate-block");
  this.companyID = localStorage.getItem('companyID');
  this.isAcumen = localStorage.getItem('isAcumen');
  this.loadData();

this.companyDepartmentList();
}
loadData(){
   
    if(this.viewData && (Object.keys(this.viewData).length !== 0)) {
        console.log(' true vehicleData',this.viewData);
        this.data = this.viewData;
        this.isUpdate = true;
      if (this.isUpdate){
        this.DivisionForm.controls['departmentName'].setValue(this.data.departmentName);
        this.DivisionForm.controls['companyId'].setValue(this.data.companyId);
        this.DivisionForm.controls['deptId'].setValue(this.data.deptId);

       
      }
    
  }
}
closeEvent(event){
   if (this.isEdit == false) {
//     var element = document.getElementById("styleSelector");
//   element.classList.remove("animate-block");
//     element.classList.add("slide-out");
this.slide = false;
this.cdr.detectChanges();
    setTimeout(e =>{
      this.closeEvents.emit(true);
   },1000)

   } else{
    this.isEdit = false;
    this.isView = true;
    this.isUpdate = false;
    this.loadData();
   }
}


}
