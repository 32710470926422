import { Component, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    errorMessage = '';
    moduleName = 'Login';
    loggedIn = false;
    constructor(private router: Router, private translateService: TranslateService,
        private loginService: LoginService, private cdr: ChangeDetectorRef) {

    }
    ngAfterViewInit() {
        this.loggedIn = localStorage.getItem('token') ? false : true;
        this.cdr.detectChanges();
    }
    navigateSignUp() {
        this.router.navigate(['register']);
    }
    login() {
        this.loginService.login(this.loginForm.value).subscribe((response: any) => {
            if (response.error) {
                console.log(response.error);
                this.errorMessage = response.error.message.error;
            } else {
                this.errorMessage = '';
                const userData = response.data;
                console.log(userData)
                //   let data={id: "Hazardous", title: "Hazardous", type: "item", url: "/Hazardous"}
                //   userData.available_modules.push(data)
                //   console.log("edit",userData.available_modules)
                //   let data1={id: "Skip", title: "Skip", type: "item", url: "/skip"}
                //   userData.available_modules.push(data1)
                //   console.log("edit",userData.available_modules)
                this.loginService.updateUser(userData);
                //this.loginService.setCompanyId('1234');
                localStorage.setItem('firstName', userData.firstName);
                localStorage.setItem('lastName', userData.lastName);
                localStorage.setItem('available_modules', JSON.stringify(userData.available_modules));
                localStorage.setItem('token', userData.token);
                localStorage.setItem('companyID', userData.company);
                localStorage.setItem('isAcumen', userData.isAcumen);
                this.loggedIn = false;
                this.router.navigate(['dashboard/analytics']);
            }
        });
    }
    ngOnInit() {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', Validators.required)
        });
        this.loginService.user.subscribe((data) => {
            if (data !== -1) {
                this.router.navigate(['home']);
            }
        });
    }
    forgotPassword(event) {
        console.log('event', event);
        this.router.navigate(['auth/reset-password-v2']);

    }
}
