import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DisposalSitesService } from '../disposal-sites.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { MapsAPILoader, LatLngLiteral } from '@agm/core';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-importing',
  templateUrl: './importing.component.html',
  styleUrls: ['./importing.component.scss']
})
export class ImportingComponent implements OnInit {
  isUpdate = false;
  slide = true;
  isEdit = false;
  public filesControl = new FormControl(null, [FileUploadValidators.filesLimit(1)]);

  public demoForm = new FormGroup({
    files: this.filesControl
  });
  @Input('isView') isView = false;
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  constructor(private messageService: MessageService,
    private dialogService: DialogService,
    private service: DisposalSitesService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private loader: NgxSpinnerService,
  ) {

  }
  importfile(event) {
    if (this.demoForm.valid) {
      const formData: any = new FormData();
      formData.append('file', this.demoForm.value.files[0]);
      this.loader.show()
      this.service.importFile(formData).subscribe((resp) => {
        console.log('response: ', resp);
        if (resp.message == "imported") {
          this.loader.hide()
          this.messageService.add({
            severity: 'success',
            summary: 'Import Prices',
            detail: 'Prices updated successfully.'
          });
          this.closeEvent(event);
        } else {
          this.loader.hide()
          this.messageService.add({
            severity: 'error',
            summary: 'Import Prices',
            //  detail: 'Failed to create the Tip sites'
            detail: 'Failed to import.'
          });
          this.closeEvent(event);
        }

      }, err => {
        console.log('import error: ', err);
        this.loader.hide()
        this.messageService.add({
          severity: 'error',
          summary: 'Create Disposal site',
          //  detail: 'Failed to create the Tip sites'
          detail: 'Unable to upload the file.Please try again.'
        });
        this.closeEvent(event);
      })
    } else {
      console.log("errr", this.demoForm.value.files);


    }

  }

  closeEvent(event) {
    if (this.isEdit == false) {
      //     var element = document.getElementById("styleSelector");
      //   element.classList.remove("animate-block");
      //     element.classList.add("slide-out");
      this.slide = false;
      this.cdr.detectChanges();
      setTimeout(e => {
        this.closeEvents.emit(true);
      }, 1000)

    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
      //  this.loadData();
    }
  }
  ngOnInit() {
  }
}
