import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { WasteCategoryService } from '../waste-category.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen:any;
  companyID:any;
  showAdd = false;
  showLoader = true;
  data:any;
  selectedCatgeory:any;
  category :any;
  selectSubCategory = false;
  title:any;
  parentWasteType :any;
  wasteTypeData:any;
  ide:any;
  categoryList = [];
  cols = [
    // {
    //     header: 'Big change Id',
    //     field: 'Id'
    // },
    // {
    //     header: 'Company ID',
    //     field: 'ParentId'
    // },
 
    {
      header:'EWC Code',
      field:'ewc_code'
    },
    {
        header: 'Description',
        field: 'code_description'
    }
];
  vehicleTypeData:any;
  adminUserList = [];
  selectedVehicle:any;
  vehicleTypeList = [];
  id:any;
  searchArr = [];
  constructor(private service:WasteCategoryService,private router:Router,private translateService: TranslateService,private cdr:ChangeDetectorRef,public dialogService: DialogService,public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,private route:ActivatedRoute,private confirmDialogService:ConfirmationService) { }

    ngOnInit() {
      localStorage.removeItem('jobID');
      localStorage.removeItem('pageIndex');  
      this.companyID = localStorage.getItem('companyID');
      this.isAcumen = localStorage.getItem('isAcumen');
      this.id = this.route.snapshot.paramMap.get('id');

      this.fetchCategory();
      if (this.cols.length > 0){
        this.cols.forEach(element => {
          this.searchArr.push(element.field);
        });
      }
    
    }
  
    addCategory(event){
      console.log('create staff called');
      console.log('edit category',this.data)

      this.category = false;
      this.wasteTypeData = this.data;
      this.ide =this.id;
      this.showAdd = true;
      this.cdr.detectChanges();
    }
    callList(event){
      console.log('call list');
      this.showAdd = false;
      this.isView = false;
      this.isUpdate = false;
      this.selectSubCategory = false;
      this.vehicleTypeData = {};
      this.cdr.detectChanges();
        this.fetchCategory();
    }
    editCategory(event,data){
      console.log('edit category',this.data)
      this.showAdd = true;
      this.category = false;
      this.isUpdate = true;
      this.selectSubCategory = true;
      this.title = data.code_description;
      this.parentWasteType = data.ewc_code,data;
      this.wasteTypeData = this.data;
      this.ide = this.id;
      this.cdr.detectChanges();
  
    }
    closeEvent(event){
      this.router.navigate(['wastecategory']);
    }
    removeCatgeory(event,data){
      this.confirmDialogService.confirm({
        message: 'Are you sure that you want to delete the Parent Waste type?',
        accept: () => {
           
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Delete Parent Waste type',
                        detail: 'Parent Waste type details deleted successfully'
                   });
                   this.updateCategory(event,data);

            
        }
    });
    }
    fetchCategory(){
      this.service.getWasteTypeDetails(this.id).subscribe((data: any) => {
          console.log('parent data',data.data);
           this.categoryList = data.data.linked_waste_category ? data.data.linked_waste_category: [];
           this.showLoader = false;
           this.data = data.data;
       });
  }
    updateCategory(event: any,val:any) {
      let value: any = '';
      let data;
      const disposal = [];
    //  this.TipSitesForm.value.location = {lat:this.lat,lng:this.lng};
      value = this.data;
          if (value.linked_waste_category.length > 0){
              value.linked_waste_category.forEach(element => {
                  if(element.ewc_code != val.ewc_code){
                      console.log('element',element,element.ewc_code ,val.ewc_code)
                      if(element.ewc_code) {
                          const subArr = [];
                      let val;
                     if(element.sub_waste_category.length > 0){
                      element.sub_waste_category.forEach(e2 =>{
                          const subSubArr = [];
                          if(e2.sub_sub_waste_category.length > 0){
                              e2.sub_sub_waste_category.forEach(e3 => {
                                  subSubArr.push(e3.ewc_code);
                              });
                            
                          }
                          val = {ewc_code:e2.ewc_code,sub_sub_waste_category:subSubArr};
                          subArr.push(val);
                      });
                     }
                      data = {ewc_code:element.ewc_code,sub_waste_category:subArr}
                         }else{
                          data = {ewc_code:element,sub_waste_category:[]};
                         }
                         disposal.push(data);
                  }
                 
              });
              value.linked_waste_category = disposal;
          }
          console.log('value',value);
      this.service.updateWasteType(value).subscribe((data: any) => {
          if (data.status === 200) {
              this.fetchCategory();
          } else if (data.status === 500) {
              this.messageService.add({
                  severity: 'error',
                 // summary: 'Update Disposal site',
                  detail: data.error.message.errmsg
              });
          }
          else {
              this.messageService.add({
                  severity: 'error',
                //  summary: 'Update Disposal site',
                 // detail: 'Failed to quotes the Tip site details'
                 detail: 'Unable to update Tip site.Please try again.'
              });
          }
  
      });
  }
   

}
