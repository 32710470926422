import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { JobService } from '../job-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment'; 
import { ImageViewComponent } from '../../../theme/shared/components/image-view/image-view.component';
declare var google: any;

@Component({
  selector: 'app-view-job-details',
  templateUrl: './view-job-details.component.html',
  styleUrls: ['./view-job-details.component.scss']
})
export class ViewJobDetailsComponent implements OnInit {
  @ViewChild('map') map: AgmMap;
  isView = false;
  locationList = [];
  wasteDescriptionForm: FormGroup;
  companyID: any;
  isAcumen: any;
  allLocationList = [];
  imageList = [];
  isUpdate = false;
  selectedCerti: any;
  selectedLoc: any;
  interimLocationList = [];
  lines = [];
  lastwasteLocation: any
  image: any;
  JobForm: FormGroup;
  data: any = [];
  lat = 53.707952345979145;
  long = -1.2452939277947062;
  zoom = 8;
  showLoader = true;
  viewData: any;
  searchArr = [];
  searchArr1 = [];
  searchArr2 = [];
  searchArr3 = [];
  previous;
  editweight = false;
  jobdata:any;
  differenceinestimatedandactulal : any;
  cols = [
    {
      header: 'Address',
      field: 'address'
    },
    {
      header: 'Works Order reference',
      field: "code"

    }
  ];
  cols1 = [
    {
      header: 'Name',
      field: 'certificate_img_File'
    }
  ];
  cols2 = [
    {
      header: 'Address',
      field: 'address'
    },
    {
      header: 'Works Order reference',
      field: "code"

    }
  ];
  cols3 = [
    {
      header: 'HazardWaste Description',
      field: 'hazardWasteDescription'
    },
    {
      header: 'Quantity',
      field: "quantity"

    },
    {
      header: 'Unit of Measure',
      field: "unit"

    },
    {
      header: 'Container storage Description',
      field: "containerDescription"

    },
    {
      header: 'Container Type',
      field: "container_type"

    },
    {
      header: 'Waste type',
      field: "waste_type"

    }
  ];


  constructor(private JobService: JobService, private route: ActivatedRoute, public dialogService: DialogService,
    private messageService: MessageService, private router: Router, private confirmDialogService: ConfirmationService) {
    this.JobForm = new FormGroup({
      pickup_location: new FormControl(true),
      next_location: new FormControl(true),
      disposal_location: new FormControl(true),
      interim_location: new FormControl(true),
      suggested_disposal_location: new FormControl(true),
      final_collection_loaction: new FormControl(true)


      // certificate_img_File:new FormControl('')
    });
    this.wasteDescriptionForm = new FormGroup({
      waste_description: new FormControl('', [Validators.required]),

  });
  }

  ngOnInit() {
    this.companyID = localStorage.getItem('companyID');
    this.isAcumen = localStorage.getItem('isAcumen');
    const id = this.route.snapshot.paramMap.get('id');
    if (this.cols.length > 0) {
      this.cols.forEach(element => {
        this.searchArr.push(element.field);


      });
    }
    if (this.cols2.length > 0) {
      this.cols2.forEach(element => {
        this.searchArr2.push(element.field);
      });
    }
    if (this.cols1.length > 0) {
      this.cols1.forEach(element => {
        this.searchArr1.push(element.field);
      });
    }
    if (this.cols3.length > 0) {
      this.cols3.forEach(element => {
        this.searchArr3.push(element.field);
      });
    }
    console.log(this.searchArr);
    console.log(this.searchArr1);
    console.log(this.searchArr2);
    console.log(this.searchArr3);
    this.JobService.jobDetails(id).subscribe((data) => {
      console.log('view details data', data.data);
      this.data = data.data;
      // localStorage.setItem('jobID',this.data._id); 
      const imageArr = [];
      if (this.data.certificate_img_File || this.data.certificate_img) {
        this.image = this.data.certificate_img_File ? this.data.certificate_img_File : this.data.certificate_img;
        imageArr.push({certificate_img_File:this.image,
          certificate_img: this.data.certificate_img  });
        // this.imageList.push({certificate_img_File:'http://159.65.148.36:7010/api/jobs/viewcertificate/SD60414003.jpg'});
      }
      this.imageList = imageArr;
      console.log('data', this.imageList, this.cols1);
      this.data.isCompleted = this.data.isCompleted == true ? 'Yes' : 'No';
      this.data.company = this.data.company.companyName;
      this.data.department = this.data.department && this.data.department.departmentName ? this.data.department.departmentName : '';
      this.interimLocationList = this.data.interimLocationList;
      this.lines=this.data.items;
      console.log("lines",this.data.items,);
      console.log(this.data.request_date,moment(this.data.request_date).format('DD-MM-YYYY'));
    if(moment(this.data.request_date).format('DD-MM-YYYY')!="Invalid date"){
      this.data.request_date=moment(this.data.request_date).format('DD-MM-YYYY');
    }
      // this.lastwasteLocation = this.data.finalCollectionDetails;
      const locArr = [];
      locArr.push(this.data.finalCollectionDetails)
      this.lastwasteLocation = locArr;
      console.log("table data1", this.interimLocationList);
      console.log("table data2", this.lastwasteLocation);
      console.log("table data2", this.lastwasteLocation);
      if (this.data.location && this.data.location!=undefined) {
        this.locationList.push({ lat: parseFloat(this.data.location.location.lat), long: parseFloat(this.data.location.location.long), address: this.data.location.address, color: 'http://maps.google.com/mapfiles/ms/icons/green.png', label: { 'text': ' ' }, point: 'pickup' });
      }
      if (this.data.finallocation && this.data.finallocation!=undefined) {
        this.locationList.push({ lat: parseFloat(this.data.finallocation.location.lat), long: parseFloat(this.data.finallocation.location.long), address: this.data.finallocation.address, color: 'http://maps.google.com/mapfiles/ms/icons/green.png', label: { 'text': ' ' }, point: 'pickup' });
      }
      if (this.data.pickupLocationDetails) {
        this.locationList.push({ lat: parseFloat(this.data.pickupLocationDetails.location.lat), long: parseFloat(this.data.pickupLocationDetails.location.long), address: this.data.pickupLocationDetails.address, color: 'http://maps.google.com/mapfiles/ms/icons/green.png', label: { 'text': ' ' }, point: 'pickup' });
      }
      if (this.data.nextLocationDetails) {
        this.locationList.push({ lat: parseFloat(this.data.nextLocationDetails.location.lat), long: parseFloat(this.data.nextLocationDetails.location.long), address: this.data.nextLocationDetails.address, color: 'http://maps.google.com/mapfiles/ms/icons/blue.png', label: { 'text': ' ' }, point: 'next' });
      }
      // console.log("locccc",this.data.certificate_img,this.data.certificate_img_File);
      
      if (this.data.disposeLocationList) {
        this.data.disposeLocationList.forEach((element, i) => {
          let address;
          let index = i + 1;
          index = index.toString();
          element.actual.totalCost = element.actual.totalCost ? parseFloat(element.actual.totalCost).toFixed(2) : '';
          element.actual.travelDistance = element.actual.travelDistance ? parseFloat(element.actual.travelDistance).toFixed(2) : '';
          element.actual.travelCost = element.actual.travelCost ? parseFloat(element.actual.travelCost).toFixed(2) : '';
          element.actual.driverCost = element.actual.driverCost ? parseFloat(element.actual.driverCost).toFixed(2) : '';
          element.actual.travelTime = element.actual.travelTime ? parseFloat(element.actual.travelTime) : '';

          address = 'Name: ' + element.actual.Name + '\n' +
            'Address: ' + element.actual.disposalSiteAdds + '\n' +
            'Telephone: ' + element.actual.contactNo + '\n' +
            'Postcode: ' + element.actual.Postcode + '\n' +
            'Cost per tonne: ' + element.actual.costperTonne + '\n' +
            'Min tonnage: ' + element.actual.minTonnage + '\n' +
            'Estimated tonnage: ' + element.actual.estimatedTonnage + '\n' +
            'Total Distance: ' + element.actual.travelDistance + '\n' +
            'Travel Time: ' + element.actual.travelTime + '\n' +
            'Disposal Cost: ' + element.actual.tonnageCost + '\n' +
            'Travel Cost: ' + element.actual.travelCost + '\n' +
            'Driver Cost: ' + element.actual.driverCost + '\n' +
            'Total Cost: ' + element.actual.totalCost + '\n';
            console.log("ele",element);
            
          this.locationList.push({ lat: parseFloat(element.actual.location.lat), long: parseFloat(element.actual.location.long), color: 'http://maps.google.com/mapfiles/ms/icons/pink.png', address: address, label: { 'text': index, 'fontWeight': 'bold', 'fontSize': '16px' }, point: 'suggested_disposal' });
        });
      }
      // console.log("locccc",this.data.disposeLocationList);
      if (this.data.disposeLocationDetails) {
        let address;
        this.data.disposeLocationDetails.disposalDistace = this.data.disposeLocationDetails.disposalDistace ? parseFloat(this.data.disposeLocationDetails.disposalDistace).toFixed(2) : '';
        this.data.disposeLocationDetails.finalDistace = this.data.disposeLocationDetails.finalDistace ? parseFloat(this.data.disposeLocationDetails.finalDistace).toFixed(2) : '';
        this.data.disposeLocationDetails.totalCost = this.data.disposeLocationDetails.totalCost ? parseFloat(this.data.disposeLocationDetails.totalCost).toFixed(2) : '';
        this.data.disposeLocationDetails.travelDistance = this.data.disposeLocationDetails.travelDistance ? parseFloat(this.data.disposeLocationDetails.travelDistance).toFixed(2) : '';
        this.data.disposeLocationDetails.travelCost = this.data.disposeLocationDetails.travelCost ? parseFloat(this.data.disposeLocationDetails.travelCost).toFixed(2) : '';
        this.data.disposeLocationDetails.driverCost = this.data.disposeLocationDetails.driverCost ? parseFloat(this.data.disposeLocationDetails.driverCost).toFixed(2) : '';
        this.data.disposeLocationDetails.travelTime = this.data.disposeLocationDetails.travelTime ? parseFloat(this.data.disposeLocationDetails.travelTime) : '';
        address = 'Name: ' + this.data.disposeLocationDetails.Name + '\n' +
          'Address: ' + this.data.disposeLocationDetails.disposalSiteAdds + '\n' +
          'Telephone: ' + this.data.disposeLocationDetails.contactNo + '\n' +
          'Postcode: ' + this.data.disposeLocationDetails.Postcode + '\n' +
          'Cost per tonne: ' + this.data.disposeLocationDetails.disposal_type_details.cost_per_tonne + '\n' +
          'Min tonnage: ' + this.data.disposeLocationDetails.disposal_type_details.min_tonnage + '\n' +
          'Estimated tonnage: ' + this.data.disposeLocationDetails.estimatedTonnage + '\n' +
          'Total Distance: ' + this.data.disposeLocationDetails.travelDistance + '\n' +
          'Travel Time: ' + this.data.disposeLocationDetails.travelTime + '\n' +
          'Disposal Cost: ' + this.data.disposeLocationDetails.tonnageCost + '\n' +
          'Travel Cost: ' + this.data.disposeLocationDetails.travelCost + '\n' +
          'Driver Cost: ' + this.data.disposeLocationDetails.driverCost + '\n' +
          'Total Cost: ' + this.data.disposeLocationDetails.totalCost + '\n';

        this.locationList.push({ lat: parseFloat(this.data.disposeLocationDetails.location.lat), long: parseFloat(this.data.disposeLocationDetails.location.long), address: address, color: 'http://maps.google.com/mapfiles/ms/icons/red.png', label: { 'text': ' ' }, point: 'disposal' });
      }
      // console.log("locccc",this.data.disposeLocationList);
      if (this.data.interimLocationList) {
        this.data.interimLocationList.forEach((element, i) => {
          let index = i + 1;
          index = index.toString();
          this.locationList.push({ lat: parseFloat(element.location.lat), long: parseFloat(element.location.long), color: 'http://maps.google.com/mapfiles/ms/icons/yellow.png', address: element.address, label: { 'text': index, 'fontWeight': 'bold', 'fontSize': '16px' }, point: 'interim' });
        });
      }
      if (this.data.finalCollectionDetails) {
        this.locationList.push({ lat: parseFloat(this.data.finalCollectionDetails.location.lat), long: parseFloat(this.data.finalCollectionDetails.location.long), address: this.data.finalCollectionDetails.address, color: 'http://maps.google.com/mapfiles/ms/icons/orange.png', label: { 'text': ' ' }, point: 'final_collection' });
      }
      const arr = this.locationList;
      this.allLocationList = arr;
      console.log('locationList', this.locationList)
      this.showLoader = false;
      this.viewData = data;
      //        this.data.disposeLocationDetails.totalCost =this.data.disposeLocationDetails.totalCost? parseFloat(this.data.disposeLocationDetails.totalCost).toFixed(2):'';
      // this.data.disposeLocationDetails.travelCost = parseFloat(this.data.disposeLocationDetails.travelCost).toFixed(2);
      // this.data.disposeLocationDetails.driverCost = parseFloat(this.data.disposeLocationDetails.driverCost).toFixed(2);



      if (this.data.disposeLocationDetails && this.data.disposeLocationDetails.metalRebateCost && this.data.disposeLocationDetails.metalRebateCost > 0) {

        this.data.disposeLocationDetails.metal_rebate = 'Yes';
      } else {
        this.data.disposeLocationDetails.metal_rebate = 'No';
      }
      if(this.data.disposeLocationDetails && this.data.disposeLocationDetails.travelTime){
        
        console.log("time",this.data.disposeLocationDetails.travelTime,this.time_convert(this.data.disposeLocationDetails.travelTime));
        this.data.disposeLocationDetails.travelTime=this.time_convert(this.data.disposeLocationDetails.travelTime);
      }
      if(this.data.disposeLocationDetails && this.data.disposeLocationDetails.start2FinalCollectionTime){
        
        console.log("time",this.data.disposeLocationDetails.start2FinalCollectionTime,this.time_convert(this.data.disposeLocationDetails.start2FinalCollectionTime));
        this.data.disposeLocationDetails.start2FinalCollectionTime=this.time_convert(this.data.disposeLocationDetails.start2FinalCollectionTime);
      }
      if(this.data.disposeLocationDetails && this.data.disposeLocationDetails.finalCollection2DisposalTime){
        
        console.log("time",this.data.disposeLocationDetails.finalCollection2DisposalTime,this.time_convert(this.data.disposeLocationDetails.finalCollection2DisposalTime));
        this.data.disposeLocationDetails.finalCollection2DisposalTime=this.time_convert(this.data.disposeLocationDetails.finalCollection2DisposalTime);
      }
      if(this.data.disposeLocationDetails && this.data.disposeLocationDetails.disposal2FinalTime){
        
        console.log("time",this.data.disposeLocationDetails.disposal2FinalTime,this.time_convert(this.data.disposeLocationDetails.disposal2FinalTime));
        this.data.disposeLocationDetails.disposal2FinalTime=this.time_convert(this.data.disposeLocationDetails.disposal2FinalTime);
      }
      if(this.data.net_weight && this.data.disposeLocationDetails.estimatedTonnage){

        var quantity = (parseFloat(this.data.disposeLocationDetails.estimatedTonnage)) - parseFloat(this.data.net_weight)  ;
        if(quantity > 0){
        this.differenceinestimatedandactulal = quantity + "  Overestimate";
      }
       else if(quantity < 0){
        this.differenceinestimatedandactulal = quantity + "  Underestimate";
      }
      else{
        this.differenceinestimatedandactulal = quantity ;
      }
      }
      if (this.data) {

        // this.JobForm.controls['employeeName'].setValue(this.data.employeeName);
        // this.JobForm.controls['employeeId'].setValue(this.data.employeeId);

        // this.JobForm.controls['tip_location'].setValue(this.data.tip_locationName);
        // this.JobForm.controls['disposalLocationName'].setValue(this.data.disposalLocationName);
        // this.JobForm.controls['waste_type'].setValue(this.data.waste_type);
        // this.JobForm.controls['isCompleted'].setValue(this.data.isCompleted);

        // this.image = this.data.certificate_img_File ? this.data.certificate_img_File : this.data.certificate_img;
        // if (this.data.company) {
        //     this.JobForm.controls['company'].setValue(this.data.company.companyName);
        // }
        // if (this.data.department) {
        //     this.JobForm.controls['department'].setValue(this.data.department.departmentName);
        // }
        // this.data.disposeLocationDetails.totalCost = parseFloat(this.data.disposeLocationDetails.totalCost).toFixed(2);
        // this.data.disposeLocationDetails.travelCost = parseFloat(this.data.disposeLocationDetails.travelCost).toFixed(2);
        // this.data.disposeLocationDetails.driverCost = parseFloat(this.data.disposeLocationDetails.driverCost).toFixed(2);
        // this.data.disposeLocationDetails.disposalDistace = parseFloat(this.data.disposeLocationDetails.disposalDistace).toFixed(2);
        // this.data.disposeLocationDetails.finalDistace = parseFloat(this.data.disposeLocationDetails.finalDistace).toFixed(2);
        // this.data.disposeLocationDetails.travelDistance = parseFloat(this.data.disposeLocationDetails.travelDistance).toFixed(2);

        // if (this.data.disposeLocationDetails){
        //    this.JobForm.controls['totalCost'].setValue(this.data.disposeLocationDetails.totalCost);
        //    this.JobForm.controls['travelCost'].setValue(this.data.disposeLocationDetails.travelCost);
        //    this.JobForm.controls['driverCost'].setValue(this.data.disposeLocationDetails.driverCost)
        //    this.JobForm.controls['disposalDistace'].setValue(this.data.disposeLocationDetails.disposalDistace)
        //    this.JobForm.controls['finalDistace'].setValue(this.data.disposeLocationDetails.finalDistace)
        //    this.JobForm.controls['travelDistance'].setValue(this.data.disposeLocationDetails.travelDistance)
        //    this.JobForm.controls['ppm'].setValue(this.data.disposeLocationDetails.ppm)
        //    this.JobForm.controls['vehicleType'].setValue(this.data.disposeLocationDetails.vehicleType)
        //    this.JobForm.controls['estimatedTonnage'].setValue(this.data.disposeLocationDetails.estimatedTonnage)
        //    this.JobForm.controls['tonnageCost'].setValue(this.data.disposeLocationDetails.tonnageCost)
        //    if (this.data.disposeLocationDetails.metalRebateCost > 0){
        //     this.JobForm.controls['metal_rebate'].setValue('Yes')
        //    } else{
        //     this.JobForm.controls['metal_rebate'].setValue('No')   
        //    }

        //    this.JobForm.controls['metalRebateCost'].setValue(this.data.disposeLocationDetails.metalRebateCost)


        // }

      }
    });


  }
   editnetweight(data){
          console.log('data', data);
          this.jobdata = data;
       this.editweight = true;
    }
     callList(event){
      console.log('call list');
      this.editweight = false;
      this.ngOnInit();
     
    }
  time_convert(decimalTime) {
    decimalTime = decimalTime * 60 * 60;
    let hours: any = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    let minutes: any = Math.floor((decimalTime / 60));
    decimalTime = decimalTime - (minutes * 60);
    let seconds: any = Math.round(decimalTime);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return `${hours}:${minutes}:${seconds}`;
  }
  update(event) {}
  change(event, key) {
    console.log('key', key, this.locationList)
    const arr = [];
    switch (key) {
      case 'pickup_location':
        if (this.JobForm.get(key).value == false) {
          this.locationList.forEach(ele => {
            if (ele.point != 'pickup') {
              arr.push(ele);
            }
          });
          this.locationList = arr;
        } else {
          this.allLocationList.forEach(ele => {
            if (ele.point == 'pickup') {
              this.locationList.push(ele);
            }
          });
        }
        break;
      case 'next_location':
        if (this.JobForm.get(key).value == false) {
          this.locationList.forEach(ele => {
            if (ele.point != 'next') {
              arr.push(ele);
            }
          });
          this.locationList = arr;
        } else {
          this.allLocationList.forEach(ele => {
            if (ele.point == 'next') {
              this.locationList.push(ele);
            }
          });
        }
        break;
      case 'disposal_location':
        if (this.JobForm.get(key).value == false) {
          this.locationList.forEach(ele => {
            if (ele.point != 'disposal') {
              arr.push(ele);
            }
          });
          this.locationList = arr;
        } else {
          this.allLocationList.forEach(ele => {
            if (ele.point == 'disposal') {
              this.locationList.push(ele);
            }
          });
        }
        break;
      case 'interim_location':
        if (this.JobForm.get(key).value == false) {
          this.locationList.forEach(ele => {
            if (ele.point != 'interim') {
              arr.push(ele);
            }
          });
          this.locationList = arr;
        } else {
          this.allLocationList.forEach(ele => {
            if (ele.point == 'interim') {
              this.locationList.push(ele);
            }
          });
        }
        break;
      case 'suggested_disposal_location':
        if (this.JobForm.get(key).value == false) {
          this.locationList.forEach(ele => {
            if (ele.point != 'suggested_disposal') {
              arr.push(ele);
            }
          });
          this.locationList = arr;
        } else {
          this.allLocationList.forEach(ele => {
            if (ele.point == 'suggested_disposal') {
              this.locationList.push(ele);
            }
          });
        }
        break;
      case 'final_collection_loaction':
        if (this.JobForm.get(key).value == false) {
          this.locationList.forEach(ele => {
            if (ele.point != 'final_collection') {
              arr.push(ele);
            }
          });
          this.locationList = arr;
        } else {
          this.allLocationList.forEach(ele => {
            if (ele.point == 'final_collection') {
              this.locationList.push(ele);
            }
          });
        }
        break;



    }
    console.log('location list', this.locationList, this.allLocationList);

  }
  clickedMarker(infowindow) {
    console.log('location list: ', this.locationList);

    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
  viewMore(event) {
    // this.closeEvents.emit(false);
  }
  closeEvent(event) {
    console.log('close event');
    this.router.navigate(['job/data']);
  }
  deleteJob(id) {

  }
  viewImage(data) {
    const ref = this.dialogService.open(ImageViewComponent, {
      data: {
        viewData: data
      },
      width: '600px',
    });

    ref.onClose.subscribe((i) => {

    });

  }

  viewCertificate(data) {
    let img;
    img = { url: data.certificate_img };
    const ref = this.dialogService.open(ImageViewComponent, {
      data: {
        viewData: img
      },
      width: '600px',
    });

    ref.onClose.subscribe((i) => {

    });
  }


}
