<div   class="" >
  
    <div id="styleSelector"  class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
<div class="container h-100">
        <div class="row align-items-center">
          <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
              <div class='row add-edit-header'  *ngIf="!isView">  
                  <div class="ui-g-10">
                      <h4 *ngIf='!isUpdate'>Create Department</h4>
                      <h4 *ngIf='isUpdate'>Edit Department</h4>
                  </div> 
                  <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Customer division" tooltipPosition="top">
                      <i class="fa fa-window-close fa-2x "></i>
                   </div>    
                       </div> 
                       <div class="">                    
            <form [formGroup]="DivisionForm" *ngIf="!isView">
           
                <div class='row form-group' *ngIf="isAcumen == 'true'">
                    <div class='ui-g-12  mt-3'>
                      <label for="linked_type">Company Associated to</label>
                    <p-dropdown class='ui-g-12 form-control' [options]="companyList"   [style]="{'width':'100%'}" formControlName='companyId'></p-dropdown>
          
                  </div>
                </div>  
             
              <div class='row form-group'>
                <div class='ui-g-12 mt-3'>
                    <label for="firstname">Division/Department Name</label>
                  <input class='ui-g-12' id="firstname" class="form-control"  type="text" size="30" formControlName='departmentName' pInputText>
                </div>
              </div>   
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                        <label for="lastname">Division/Department ID</label>
                  <input class='ui-g-12' id="lastname" type="{{isUpdate?'text':'number'}}"  class="form-control {{isUpdate?'disabled':''}}" size="30" formControlName='deptId' pInputText>
                </div>
              </div>   
               
              <!-- <div class='col-12' style='color:red;font-size: 12px' *ngIf="userForm.get(''">
                  Employee ID
                </div> -->
              
                  
             
              
              
               
             
              
           
              <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                <div class='ui-g-12'>
                  <p-button label="Create" [disabled]='!DivisionForm.valid' (onClick)="createDivision($event)"></p-button>
                
                </div>
              </div>
              <div class='row button-cls' *ngIf='isUpdate'>
                <div class='ui-g-12'>
                  <p-button label="Update" [disabled]='!DivisionForm.valid' (onClick)="updateDivision($event)"></p-button>
                
                </div>
              </div>
            
            </form>
            <div *ngIf="isView">
                <div class='row details-header'>  
                    <div class="ui-g-10">
                        <h4>View Department Details</h4>
                    </div> 
                    <div class="ui-g-1" (click)="editEvent($event)" pTooltip="Click to edit a Customer division" tooltipPosition="top">
                        <i class="fa fa-edit fa-2x "></i>
                    </div>    
                    <div class="ui-g-1" (click)="closeEvent($event)" pTooltip="Click to close a Customer division" tooltipPosition="top">
                        <i class="fa fa-window-close fa-2x "></i>
                        </div>    
            </div>  
          <div class="details-content">
                <div class='row'>
                        <div class="ui-g-6">
                          <span for="userName">Company Associated to</span>
                        </div>
                        <div class="ui-g-6">
                          <span for="userName">{{viewData.companyId}}</span>
                        </div>
                </div>
              <div class='row'>
                <div class="ui-g-6">
                  <span for="userName">Division/Department Name</span>
                </div>
                <div class="ui-g-6">
                  <span for="userName">{{viewData.departmentName}}</span>
                </div>
              </div>
              <div class='row'>
                  <div class="ui-g-6">
                    <span for="userName">Division/Department ID</span>
                  </div>
                  <div class="ui-g-6">
                    <span for="userName">{{viewData.deptId}}</span>
                  </div>
                </div>
                
                  
                    
                        
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>
      </div>